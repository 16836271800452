import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import { SecondaryButton } from "@ddm-design-system/button";
import { Icon } from "@ddm-design-system/icon";
import { ButtonText, SectionTitle, BodyHighlight, Subtitle } from "@ddm-design-system/typography";
import { IBeverage } from "../../store/types";
import useIsMobile from "../../hooks/useIsMobile";
import useContent from "../../hooks/useContent";

interface IProps {
  beverage: IBeverage;
  open: boolean;
  onClick: () => void;
  index: number;
  inactive: boolean;
  containerWidth?: number;
  numItems: number;
}

const MAX_DETAILS_WIDTH = 650;
const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      width: 125,
      margin: `${theme.spaceUnit.xl} ${theme.spaceUnit.lg}`,
      marginBottom: theme.spaceUnit.xs,
      "@media": {
        "screen and (max-width: 700px)": {
          width: `calc(64 + 2 * ${theme.spaceUnit.sm})`,
          margin: 0,
          paddingTop: theme.spaceUnit.sm
        }
      }
    },
    containerOpen: {
      marginBottom: theme.spaceUnit.xxl
    },
    item: {
      outline: "none",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spaceUnit.lg,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      "@selectors": {
        "&:hover": {
          borderBottomColor: theme.colors.white
        }
      },
      "@media": {
        "screen and (max-width: 700px)": {
          paddingBottom: theme.spaceUnit.md,
          padding: `0 ${theme.spaceUnit.sm}`
        }
      }
    },
    image: {
      marginBottom: theme.spaceUnit.xs,
      width: "auto",
      height: "auto",
      objectFit: "contain",
      borderRadius: "50%",
      minHeight: 135,
      "@media": {
        "screen and (max-width: 700px)": {
          minHeight: "unset",
          width: "100%"
        }
      }
    },
    title: {
      textAlign: "center",
      color: theme.colors.white,
      height: 46,
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@media": {
        "screen and (max-width: 700px)": {
          display: "none"
        }
      }
    },
    description: {
      padding: `${theme.spaceUnit.lg} 0`,
      backgroundColor: theme.colors.black,
      zIndex: 10
    },
    textContainer: {
      maxHeight: 95,
      overflowY: "hidden",
      padding: `0 ${theme.spaceUnit.lg}`,
      position: "relative",
      paddingBottom: theme.spaceUnit.lg,
      "@media": {
        "screen and (max-width: 700px)": {
          paddingBottom: theme.spaceUnit.sm,
          marginLeft: theme.spaceUnit.lg,
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    },
    textContainerOverflow: {
      paddingBottom: theme.spaceUnit.xl,
      "@media": {
        "screen and (max-width: 700px)": {
          paddingBottom: theme.spaceUnit.sm
        }
      }
    },
    textContainerShowAll: {
      maxHeight: "unset"
    },
    name: {
      color: theme.colors.white,
      marginBottom: theme.spaceUnit.xs,
      padding: `0 ${theme.spaceUnit.lg}`,
      "@media": {
        "screen and (max-width: 700px)": {
          padding: `0 ${theme.spaceUnit.lg}`
        }
      }
    },
    text: {
      color: theme.colors.white,
      marginBottom: theme.spaceUnit.lg,
      "@media": {
        "screen and (max-width: 700px)": {
          paddingBottom: 0
        }
      }
    },
    overflow: {
      position: "absolute",
      left: 0,
      right: 0,
      background: `linear-gradient(transparent, ${theme.colors.black}, ${theme.colors.black})`,
      color: theme.colors.white,
      bottom: 0,
      paddingTop: theme.spaceUnit.xxl,
      height: theme.spaceUnit.xxl,
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    overflowIcon: {
      marginLeft: `-${theme.spaceUnit.lg}`
    },
    info: {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "space-between",
      borderTop: "1px solid #353535",
      paddingTop: theme.spaceUnit.lg,
      flexWrap: "wrap",
      "@media": {
        "screen and (max-width: 700px)": {
          display: "inline-block",
          minWidth: 260,
          marginLeft: theme.spaceUnit.lg,
          paddingTop: 0
        }
      }
    },
    infoItem: {
      display: "flex",
      flexDirection: "column",
      padding: `0 ${theme.spaceUnit.lg}`,
      marginBottom: theme.spaceUnit.lg,
      "@media": {
        "screen and (max-width: 700px)": {
          padding: `${theme.spaceUnit.xs} 0`,
          borderBottom: "1px solid #353535",
          marginBottom: 0
        }
      }
    },
    infoTitle: {
      color: theme.colors.grey.grey100
    },
    infoValue: {
      color: theme.colors.white
    },
    infoBorder: {
      borderLeft: "1px solid #353535",
      "@media": {
        "screen and (max-width: 700px)": {
          borderLeft: "unset"
        }
      }
    }
  };
};

type Direction = "LEFT" | "RIGHT" | "CENTER";

const BeveragesGridItem: React.FC<IProps> = ({
  beverage,
  open,
  onClick,
  index,
  inactive,
  containerWidth,
  numItems
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const [direction, setDirection] = useState<Direction>("LEFT");
  const [width, setWidth] = useState(MAX_DETAILS_WIDTH);
  const [marginLeft, setMarginLeft] = useState(0);
  const [overflow, setOverflow] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const isMobile = useIsMobile();
  const { home: content } = useContent();

  useEffect(() => {
    if (containerWidth === undefined || !ref.current?.offsetLeft) {
      return;
    }
    const posX =
      window.innerWidth < containerWidth
        ? ref.current.offsetLeft
        : ref.current.offsetLeft - (window.innerWidth - containerWidth) / 2;
    const style = getComputedStyle(ref.current);
    const itemWidth =
      ref.current.clientWidth + parseFloat(style.marginLeft) + parseFloat(style.marginRight);
    const itemsPerRow = Math.floor(containerWidth / itemWidth);
    let newDirection: Direction =
      posX - parseFloat(style.marginLeft) < containerWidth / 2 ? "RIGHT" : "LEFT";
    let newMarginLeft = 0;

    const finalRowItems = numItems % itemsPerRow;
    const finalRow = index >= numItems - finalRowItems;

    // when there are 3 columns then center the description to
    // occupy all available width
    // center the description when:
    if (
      // there is only one column
      itemsPerRow === 1 ||
      // its the middle column of a row
      (!finalRow && itemsPerRow % 2 === 1 && index % itemsPerRow === Math.floor(itemsPerRow / 2)) ||
      // its the middle column on the final row
      (finalRow && finalRowItems % 2 === 1 && index % itemsPerRow === Math.floor(finalRowItems / 2))
    ) {
      newDirection = "CENTER";
    }

    let widthAvailable =
      newDirection === "CENTER"
        ? containerWidth
        : newDirection === "LEFT"
        ? posX + parseFloat(style.marginLeft)
        : containerWidth - posX;

    if (isMobile) {
      newDirection = "RIGHT";
      widthAvailable = containerWidth;
      newMarginLeft = -posX + parseFloat(style.marginLeft);
    }

    setDirection(newDirection);
    setWidth(Math.min(widthAvailable, MAX_DETAILS_WIDTH));
    setMarginLeft(newMarginLeft);

    // if (containerWidth < 700) {
    //   setWidth(
    //     Math.min(
    //       widthAvailable,
    //       containerWidth -
    //         parseFloat(style.marginLeft) -
    //         parseFloat(style.marginRight)
    //     )
    //   );
    // }
  }, [ref, containerWidth, index, numItems, isMobile]);

  const [styles, cx] = useStyles(stylesheet);

  useLayoutEffect(() => {
    if (!open || !textContainerRef.current) {
      return;
    }
    const style = getComputedStyle(textContainerRef.current);
    setOverflow(
      (textContainerRef.current &&
        textRef.current &&
        textContainerRef.current.clientHeight - parseFloat(style.paddingBottom) <
          textRef.current.getBoundingClientRect().height) ||
        false
    );
  }, [open]);
  return (
    <div ref={ref} className={cx(styles.container, open && styles.containerOpen)}>
      <div
        role="button"
        onKeyDown={onClick}
        tabIndex={index}
        onClick={onClick}
        className={cx(styles.item)}
        style={{
          borderBottomColor: open ? "white" : isMobile && inactive ? "#353535" : "transparent"
        }}
      >
        <img
          src={beverage.lense}
          width={96}
          height={96}
          alt={beverage.name}
          className={cx(styles.image)}
          style={{
            opacity: inactive ? 0.5 : 1
          }}
        />
        <ButtonText
          className={cx(styles.title)}
          style={{
            opacity: inactive ? 0.5 : 1
          }}
        >
          {beverage.name}
        </ButtonText>
      </div>
      {open && (
        <div
          className={cx(styles.description)}
          style={{
            alignSelf:
              direction === "CENTER" ? "center" : direction === "LEFT" ? "flex-end" : "flex-start",
            width,
            marginLeft
          }}
        >
          <Subtitle className={cx(styles.name)}>{beverage.name}</Subtitle>
          <div
            className={`${cx(styles.textContainer)} ${
              overflow && cx(styles.textContainerOverflow)
            } ${showAll && cx(styles.textContainerShowAll)}`}
            ref={textContainerRef}
          >
            <div ref={textRef}>
              <SectionTitle className={cx(styles.text)}>{beverage.description}</SectionTitle>
            </div>
            {overflow && !showAll && (
              <div className={cx(styles.overflow)}>
                <SecondaryButton
                  onClick={() => setShowAll(true)}
                  style={{
                    color: "white",
                    backgroundColor: "transparent",
                    borderColor: "transparent"
                  }}
                >
                  {content.home_read_more}
                </SecondaryButton>
                <Icon name="chevron-down" className={cx(styles.overflowIcon)} />
              </div>
            )}
          </div>
          <div className={cx(styles.info)}>
            <div className={cx(styles.infoItem)}>
              <BodyHighlight className={cx(styles.infoTitle)}>
                {content.home_beer_type}
              </BodyHighlight>
              <SectionTitle className={cx(styles.infoValue)}>{beverage.category}</SectionTitle>
            </div>
            <div className={`${cx(styles.infoItem)} ${cx(styles.infoBorder)}`}>
              <BodyHighlight className={cx(styles.infoTitle)}>
                {content.home_country_origin}
              </BodyHighlight>
              <SectionTitle className={cx(styles.infoValue)}>{beverage.origin}</SectionTitle>
            </div>

            <div className={`${cx(styles.infoItem)} ${cx(styles.infoBorder)}`}>
              <BodyHighlight className={cx(styles.infoTitle)}>{content.home_abv}</BodyHighlight>
              <SectionTitle className={cx(styles.infoValue)}>{beverage.abv}%</SectionTitle>
            </div>
            <div className={`${cx(styles.infoItem)} ${cx(styles.infoBorder)}`}>
              <BodyHighlight className={cx(styles.infoTitle)}>{content.home_ibu}</BodyHighlight>
              <SectionTitle className={cx(styles.infoValue)}>
                {beverage.ibu === -1 ? "--" : beverage.ibu}
              </SectionTitle>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeveragesGridItem;
