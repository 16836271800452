import React from "react";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import {
  Body,
  Description,
  Subtitle,
  SectionTitle,
  PageTitle
} from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import useIsMobile from "../../hooks/useIsMobile";
import { formatCurrency } from "../../helpers";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import useContent from "../../hooks/useContent";

interface IProps {
  todayReport: any;
  tomorrowReport?: any;
  spoiler?: boolean;
}

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  reportSection: {
    padding: `${theme.spaceUnit.xxl} calc(${theme.spaceUnit.md} * 4) ${theme.spaceUnit.xl} `,
    borderBottom: `1px solid ${theme.colors.grey.grey50}`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.xl} 0`
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.xl} 0`
      }
    }
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.lg
  },
  sectionContainer: {
    boxSizing: "border-box",
    marginTop: `calc(${theme.spaceUnit.md} * 3)`,
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  statusAlert: {
    color: theme.colors.alert.alert100
  },
  statusSuccess: {
    color: theme.colors.success.success100
  },
  potentialSection: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spaceUnit.md
  }
});

const PotentialSection: React.FC<IProps> = ({ todayReport, tomorrowReport, spoiler = false }) => {
  const [styles, cx] = useStyles(stylesheet);
  const isMobile = useIsMobile();
  const countryConfig = useSelector(getCurrentCountryConfig);
  const { report: content } = useContent();

  const SectionTitleFont = isMobile ? SectionTitle : Subtitle;
  const BodyFont = isMobile ? Description : Body;
  const potentialProfit = tomorrowReport.profit - todayReport.profit;

  return (
    <section className={cx(styles.reportSection)}>
      <div className={cx(styles.sectionHeader)}>
        <SectionTitleFont className={cx(styles.sectionTitle)}>
          {content.report_potential}
        </SectionTitleFont>
        {spoiler ? (
          <BodyFont>{content.report_potential_spoiler_desc}</BodyFont>
        ) : (
          <BodyFont>{content.report_potential_desc}</BodyFont>
        )}
      </div>
      <div className={cx(styles.sectionContainer, styles.potentialSection)}>
        <PageTitle className={cx(potentialProfit > 0 ? styles.statusSuccess : styles.statusAlert)}>
          {formatCurrency(potentialProfit, countryConfig.currency)}
        </PageTitle>
      </div>
    </section>
  );
};

export default PotentialSection;
