import { ITapsInfo, ICo2Info, IBeverage } from "../types";
import { ETodayActions } from "./types";
import {VOLUME_TIMEFRAME, VOLUME_UNIT} from "../../constants";

export const setTotalTaps = (payload: number) => ({
  type: ETodayActions.SET_TOTAL_TAPS,
  payload
});

export const setBeverage = (beverage: IBeverage | null, tapNumber: number) => ({
  type: ETodayActions.SET_BEVERAGE,
  payload: {
    beverage,
    tapNumber
  }
});

export const updateTapInformation = (
  tapInfo: ITapsInfo,
  tapNumber: number
) => ({
  type: ETodayActions.UPDATE_TAP_INFO,
  payload: {
    tapInfo,
    tapNumber
  }
});

export const updateCo2Information = (payload: ICo2Info) => ({
  type: ETodayActions.UPDATE_CO2_INFO,
  payload
});

export const updateTotalVolumeSoldPerYear = (payload: number) => ({
  type: ETodayActions.UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR,
  payload
});

export const updateSellInPrice = (beverageId: string, price: number) => ({
  type: ETodayActions.UPDATE_SELL_IN_PRICE,
  payload: {
    beverageId,
    price
  }
});

export const updateVolumeConfig = (volumeUnit: VOLUME_UNIT, volumeTimeframe: VOLUME_TIMEFRAME) => ({
  type: ETodayActions.UPDATE_VOLUME_CONFIG,
  payload: {
    volumeUnit,
    volumeTimeframe
  }
});
