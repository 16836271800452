import React from "react";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import {
  Body,
  Description,
  Subtitle,
  SectionTitle,
  PageTitle,
  Label,
  DescriptionMedium
} from "@ddm-design-system/typography";
import { useSelector } from "react-redux";
import numeral from "numeral";
import { Icon } from "@ddm-design-system/icon";
import { MessageTooltip } from "@ddm-design-system/tooltip";
import useIsMobile from "../../hooks/useIsMobile";
import { currencyMap, VOLUME_UNIT } from "../../constants";
import AvatarTaps from "../avatar-taps/avatar-taps";
import { formatCurrency } from "../../helpers";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import useContent from "../../hooks/useContent";

interface IProps {
  todayReport: any;
}

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  reportSection: {
    padding: `${theme.spaceUnit.xxl} calc(${theme.spaceUnit.md} * 4) ${theme.spaceUnit.xl} `,
    borderBottom: `1px solid ${theme.colors.grey.grey50}`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.xl} 0`
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.xl} 0`
      }
    }
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.lg
  },
  sectionContainer: {
    boxSizing: "border-box",
    marginTop: `calc(${theme.spaceUnit.md} * 3)`,
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  table: {
    borderCollapse: "collapse",
    borderBottom: `1px solid ${theme.colors.grey.grey25}`,
    width: "100%"
  },
  tableHeader: {
    whiteSpace: "nowrap",
    color: theme.colors.grey.grey100,
    textAlign: "left",
    padding: `${theme.spaceUnit.md} ${theme.spaceUnit.xs}`
  },
  tableCell: {
    padding: `${theme.spaceUnit.lg} ${theme.spaceUnit.xs}`,
    paddingRight: theme.spaceUnit.md,
    whiteSpace: "nowrap",
    borderBottom: `1px solid ${theme.colors.grey.grey25}`,
    "@media": {
      "screen and (max-width: 700px)": {
        boxSizing: "border-box",
        height: 90
      }
    }
  },
  tableCellLogo: {
    padding: `${theme.spaceUnit.xs} 0 `,
    paddingRight: theme.spaceUnit.md
  },
  statusAlert: {
    color: theme.colors.alert.alert100,
    display: "flex",
    alignItems: "center",
    cursor: "help",

    "@selectors": {
      "> .y-icon": {
        marginRight: theme.spaceUnit.xs,
        fill: theme.colors.alert.alert100
      }
    },
    "@media": {
      "screen and (max-width: 700px)": {
        whiteSpace: "initial",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical"
      }
    }
  },

  statusSuccess: {
    color: theme.colors.success.success100
  },
  tableSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spaceUnit.lg
  },
  totalValue: {
    whiteSpace: "nowrap",
    "@media": {
      "screen and (max-width: 700px)": {
        fontSize: `${theme.typography.sectionTitle.fontSize} !important`
      }
    }
  },
  tooltipLabel: {
    display: "flex",
    alignItems: "center",
    minHeight: theme.spaceUnit.md
  }
});

const RevenueSectionToday: React.FC<IProps> = ({ todayReport }) => {
  const [styles, cx] = useStyles(stylesheet);
  const isMobile = useIsMobile();
  const { report: content, general: contentGeneral } = useContent();
  const countryConfig = useSelector(getCurrentCountryConfig);

  const SectionTitleFont = isMobile ? SectionTitle : Subtitle;
  const BodyFont = isMobile ? Description : Body;

  const TotalTextFont = isMobile ? DescriptionMedium : SectionTitle;
  const ValueFont = isMobile ? SectionTitle : PageTitle;

  return (
    <section className={cx(styles.reportSection)}>
      <div className={cx(styles.sectionHeader)}>
        <SectionTitleFont className={cx(styles.sectionTitle)}>
          {content.report_taps}
        </SectionTitleFont>
        <BodyFont>
          {content.report_taps_subtitle}{" "}
          {countryConfig.useTax ? `(${contentGeneral.general_incl_tax})` : ""}
        </BodyFont>
      </div>

      <div className={cx(styles.sectionContainer)}>
        <table className={cx(styles.table)}>
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
              <th className={cx(styles.tableHeader)}>
                <MessageTooltip
                  renderHeader={() => (
                    <Label className={cx(styles.tooltipLabel)}>
                      {isMobile ? content.report_hl_year_short : content.report_hl_year_long}&nbsp;
                      <Icon name="info" size={10} />
                    </Label>
                  )}
                >
                  {content.report_total_volume_converted}
                </MessageTooltip>
              </th>
              <th className={cx(styles.tableHeader)}>
                <Label>
                  {isMobile
                    ? (content.report_price_year_short || "").replace(
                        "%UNIT%",
                        currencyMap[countryConfig.currency]
                      )
                    : content.report_price_year_long}
                </Label>
              </th>
              <th className={cx(styles.tableHeader)}>
                <Label>{content.report_status}</Label>
              </th>
            </tr>
          </thead>
          <tbody>
            {todayReport.taps.map((tap: any, i: number) => (
              <tr key={i}>
                <td className={cx(styles.tableCellLogo)}>
                  <AvatarTaps beverage={tap.beverage} />
                </td>
                <td className={cx(styles.tableCell)}>
                  <Body>
                    {tap.hlPerYear} {currencyMap[VOLUME_UNIT.HL]}
                  </Body>
                </td>
                <td className={cx(styles.tableCell)}>
                  <Body>
                    {formatCurrency(tap.pricePerServing,countryConfig.currency, false, true)}
                  </Body>
                </td>
                <td className={cx(styles.tableCell)}>
                  {tap.isQualityRisk ? (
                    <MessageTooltip
                      renderHeader={() => (
                        <Description className={cx(styles.statusAlert)}>
                          {content.report_quality_risk}&nbsp;
                          <Icon name="info" size={12} />
                        </Description>
                      )}
                    >
                      {(content.report_quality_risk_tooltip || "").replace(
                        "%NUMBER%",
                        `${Math.round(tap.minimumLitersRequired / 100 - tap.hlPerYear)}`
                      )}
                    </MessageTooltip>
                  ) : (
                    <Description className={cx(styles.statusSuccess)}>
                      {content.report_quality_risk_ok}
                    </Description>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={cx(styles.tableSummary)}>
          <TotalTextFont>{content.report_total_revenue}</TotalTextFont>
          <ValueFont className={cx(styles.totalValue)}>
            {formatCurrency(todayReport.revenue, countryConfig.currency)}
          </ValueFont>
        </div>
      </div>
    </section>
  );
};

export default RevenueSectionToday;
