// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import {
  BodyHighlight,
  Label,
  PageTitle,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import { TextInput } from "@ddm-design-system/textinput";
import numeral from "numeral";
import { updateSellInPrice } from "../../store/today/actions";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import Base from "../../templates/base";
import { currencyMap, VOLUME_UNIT } from "../../constants";
import BeerLense from "../../components/beer-lense/beer-lense";
import { EBeverageType } from "../../store/types";
import useIsMobile from "../../hooks/useIsMobile";
import { getDifferentBrands, getSellInPrices } from "../../store/today/selectors";
import useContent from "../../hooks/useContent";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  pricingPage: {
    color: theme.colors.black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.xxl,
    textAlign: "center",
    "@media": {
      "screen and (max-width: 700px)": {
        marginBottom: theme.spaceUnit.lg
      }
    }
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "50%",

    padding: `0 ${theme.spaceUnit.lg}`,
    boxSizing: "border-box",
    "@media": {
      "screen and (max-width: 700px)": {
        width: "100%",
        padding: 0
      }
    }
  },
  avatarWrapper: {
    marginRight: theme.spaceUnit.md,
    marginTop: theme.spaceUnit.md,
    marginBottom: theme.spaceUnit.md,
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media": {
      "screen and (max-width: 700px)": {
        marginRight: theme.spaceUnit.xs
      }
    }
  },
  inputRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spaceUnit.xl
  },
  currency: {
    color: theme.colors.grey.grey50
  },
  mainBrandLabel: {
    whiteSpace: "nowrap",
    position: "absolute",
    bottom: "-16px",
    margin: "auto",
    width: "200%"
  }
});

interface IProps {}

const Purchasing: React.FC<IProps> = () => {
  const [styles, cx] = useStyles(stylesheet);
  const dispatch = useDispatch();
  const { purchasing: content, general: contentGeneral } = useContent();
  const isMobile = useIsMobile();
  const brands = useSelector(getDifferentBrands);
  const sellInPrices = useSelector(getSellInPrices);
  const countryConfig = useSelector(getCurrentCountryConfig);

  const [valueBrands, setValueBrands] = useState(sellInPrices);

  const onChange = (beverageId: number, valueInput: any) => {
    const value = parseFloat(valueInput);
    const match = valueInput.match("[0-9]+([.,]0*)");
    const concatenate =
      match && (valueInput.endsWith(".") || valueInput.endsWith(",") || valueInput.endsWith("0"));
    const endsWithZero = match && valueInput.endsWith("0") && !match?.[1].includes("0");

    setValueBrands({
      ...valueBrands,
      [beverageId]: {
        price:
          value !== undefined
            ? concatenate
              ? `${value.toString()}${endsWithZero ? "0" : match[1]}`
              : value.toString()
            : undefined
      }
    });

    dispatch(updateSellInPrice(beverageId, !Number.isNaN(value) ? value : undefined));
  };

  const formatValues = useCallback(() => {
    const obj = {};
    Object.keys(valueBrands).forEach(k => {
      const t = valueBrands[k]?.price;
      obj[k] = { price: t !== undefined ? `${numeral(t).format("0.00")}` : undefined };
    });
    setValueBrands(obj);
  }, [valueBrands]);

  useEffect(() => {
    formatValues();
  }, []); // eslint-disable-line

  const Title = isMobile ? Subtitle : PageTitle;
  const BodyTitle = isMobile ? BodyHighlight : SectionTitle;

  return (
    <Base>
      <div className={cx(styles.pricingPage)}>
        <Title className={cx(styles.sectionTitle)}>{content.purchasing_today}</Title>
        <BodyTitle className={cx(styles.sectionTitle)}>
          {content.purchasing_today_subtitle}
        </BodyTitle>

        <div className={cx(styles.inputRow)}>
          {brands.map(beverage => (
            <div key={beverage.id} className={cx(styles.inputWrapper)}>
              <div className={cx(styles.avatarWrapper)}>
                <BeerLense beverage={beverage} />
                <Label className={cx(styles.mainBrandLabel)}>{beverage.name}</Label>
              </div>
              <div style={{ flex: 1 }}>
                <TextInput
                  type="number"
                  step="0.01"
                  onBlur={() => formatValues()}
                  value={valueBrands[beverage.id]?.price}
                  disabled={beverage.type === EBeverageType.WATER}
                  onChange={e => {
                    onChange(beverage.id, e.target.value);
                  }}
                  suffixIcon={
                    <span className={cx(styles.currency)}>
                      {currencyMap[countryConfig.currency]}/{currencyMap[VOLUME_UNIT.LITER]}
                    </span>
                  }
                  label={
                    beverage?.type === EBeverageType.WATER
                      ? contentGeneral.general_no_price
                      : content.purchasing_price_per_beer
                  }
                />
              </div>
            </div>
          ))}
          {brands.length > 1 && <div style={{ width: "50%" }} />}
        </div>
      </div>
    </Base>
  );
};

export default Purchasing;
