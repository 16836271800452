import { ApiService, IApiService } from "./api";
import { IContentfulService, ContentfulService } from "./contentful";
import { IAnalyticsService, GAAnalyticsService } from "./analytics";

export interface IAppContext {
  apiService: IApiService;
  contentfulService: IContentfulService;
  analyticsService: IAnalyticsService;
}

export const createAppContext: () => IAppContext = () => {
  const apiBaseUrl =
    process.env.REACT_APP_JCORE_BASE_URL || "https://jcore.dmbuddy.io";

  const apiService = new ApiService(apiBaseUrl);

  const contentfulAccessKey =
    process.env.REACT_APP_CONTENTFUL_API_KEY ||
    "sxSF554Yu8389czI1isdwPyXYDSlPm3UqalbZv2UTHk";
  const contentfulService = new ContentfulService(contentfulAccessKey);

  const analyticsService = new GAAnalyticsService();

  return {
    apiService,
    contentfulService,
    analyticsService
  };
};

export const getAppVersion: () => string = () =>
  process.env.REACT_APP_VERSION || "unknown version";

export default createAppContext;
