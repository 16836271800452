import React from "react";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

export const MAX_WIDTH = 966;
export const MAX_WIDTH_TABLET = 645;

export type UIMode = "DEFAULT" | "PRINT" | "SHARE";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  base: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    margin: "0 auto",
    maxWidth: MAX_WIDTH,
    position: "relative",
    "@media": {
      "screen and (max-width: 900px)": {
        maxWidth: MAX_WIDTH_TABLET
      },
      "screen and (max-width: 700px)": {
        margin: `0 ${theme.spaceUnit.lg}`
      }
    }
  },
  main: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    minHeight: 300,
    "@media": {
      "screen and (min-width: 900px)": {
        minHeight: 432
      }
    }
  }
});

export interface IBaseProps {
  title?: string;
  className?: string;
  mode?: UIMode;
  fixedHeight?: boolean;
  stickyFooter?: boolean;
  noMarginHeader?: boolean;
}

const Base: React.FC<IBaseProps> = ({
  children,
  title,
  mode = "DEFAULT",
  className = "",
  fixedHeight,
  stickyFooter,
  noMarginHeader
}) => {
  const [styles, cx] = useStyles(stylesheet);
  return (
    <div className={cx(styles.base, className)}>
      <style type="text/css" media="print">
        {`
          .root-container { overflow: visible; }
          .no-print { display: none; }
          @page { size:auto; size:A2 portrait; zoom: 70%; margin: 0mm; }
          body { -webkit-print-color-adjust: exact; }
        `}
      </style>
      <Header title={title} mode={mode} noMarginHeader={noMarginHeader} />
      <main className={cx(styles.main, fixedHeight && {})}>{children}</main>
      <Footer mode={mode} stickyFooter={stickyFooter} />
    </div>
  );
};

export default Base;
