import StyleConstants from "./styles/constants.scss";

export const CURRENT_VERSION = "1";
export const DEFAULT_LANG = "en-GB";
export const DEFAULT_COUNTRY_ID = "france";

export enum VOLUME_UNIT {
  LITER = "liter",
  HL = "hectoliter",
  BB = "barrel"
}

export enum VOLUME_TIMEFRAME {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year"
}

export const currencyMap: { [key: string]: string } = {
  GBP: "£",
  EUR: "€",
  USD: "$",
  [VOLUME_UNIT.LITER]: "l",
  [VOLUME_UNIT.HL]: "hl",
  [VOLUME_UNIT.BB]: "barrel"
};

export const volumeUnitArray: VOLUME_UNIT[] = [VOLUME_UNIT.LITER, VOLUME_UNIT.HL, VOLUME_UNIT.BB];

export const volumeTimeframeArray: VOLUME_TIMEFRAME[] = [
  VOLUME_TIMEFRAME.WEEK,
  VOLUME_TIMEFRAME.MONTH,
  VOLUME_TIMEFRAME.YEAR
];

export const volumeMultiplier = {
  [VOLUME_UNIT.LITER]: 100,
  [VOLUME_UNIT.HL]: 1,
  [VOLUME_UNIT.BB]: 0.611026,
  [VOLUME_TIMEFRAME.WEEK]: 52.143,
  [VOLUME_TIMEFRAME.MONTH]: 12,
  [VOLUME_TIMEFRAME.YEAR]: 1
};

export const MobileConstants = {
  mobileBreakpoint1: parseInt(StyleConstants["mobile-breakpoint-1"], undefined),
  mobileBreakpoint2: parseInt(StyleConstants["mobile-breakpoint-2"], undefined),
  mobileBreakpoint3: parseInt(StyleConstants["mobile-breakpoint-3"], undefined)
};

export const numeralLocaleEN = {
  delimiters: {
    thousands: " ",
    decimal: "."
  },
  abbreviations: {
    thousand: "k",
    million: "M",
    billion: "B",
    trillion: "T"
  },
  ordinal(number1: number) {
    const b = number1 % 10;

    // eslint-disable-next-line no-bitwise
    if (~~((number1 % 100) / 10) === 1) {
      return "th";
    }

    switch (b) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  },
  currency: {
    symbol: "£"
  }
};


export const numeralLocaleFR = {
  delimiters: {
    thousands: " ",
    decimal: "."
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal(number: number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€"
  }
};

export const numeralLocaleIT = {
  delimiters: {
    thousands: " ",
    decimal: "."
  },
  abbreviations: {
    thousand: "mila",
    million: "mil",
    billion: "b",
    trillion: "t"
  },
  ordinal(number: number) {
    return "º";
  },
  currency: {
    symbol: "€"
  }
};


export const numeralLocaleFI = {
  delimiters: {
    thousands: " ",
    decimal: "."
  },
  abbreviations: {
    thousand: "k",
    million: "M",
    billion: "G",
    trillion: "T"
  },
  ordinal (number: number) {
    return ".";
  },
  currency: {
    symbol: "€"
  }
};
