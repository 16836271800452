import { createStore, compose, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import { initialState as todayInitialState } from "./today/reducer";
import { IAppContext } from "../services";
import rootSaga from "../sagas";

const initialState = {
  today: todayInitialState
};

// Enable redux devtools chrome extension
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const initStore = (context: IAppContext) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga, context);

  return {
    store,
    persistor
  };
};

export default initStore;
