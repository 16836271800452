import { SyncCollection } from "contentful";
import {
  IGetContentfulAction,
  IGetContentfulSuccessAction,
  IGetContentfulErrorAction,
  ISetCurrentLanguageAction,
  ILanguageInfo,
  EContentActions
} from "./types";

export const getContentful = (): IGetContentfulAction => ({
  type: EContentActions.GET_CONTENTFUL
});

export const setCurrentLanguage = (
  lang: ILanguageInfo
): ISetCurrentLanguageAction => ({
  type: EContentActions.SET_CURRENT_LANGUAGE,
  payload: lang
});

export const getContentfulSuccess = (contentful: {
  entries: SyncCollection["entries"];
  nextSyncToken: SyncCollection["nextSyncToken"];
}): IGetContentfulSuccessAction => ({
  type: EContentActions.GET_CONTENTFUL_SUCCESS,
  payload: contentful
});

export const getContentfulError = (): IGetContentfulErrorAction => ({
  type: EContentActions.GET_CONTENTFUL_ERROR
});

export const syncSuccesful = (token: SyncCollection["nextSyncToken"]) => ({
  type: EContentActions.SYNC_SUCCESSFUL,
  payload: token
});
