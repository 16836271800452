import React, { useCallback, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import { PageTitle, Body, Subtitle, ButtonText } from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { useDispatch, useSelector } from "react-redux";
import BeveragesGrid from "./BeveragesGrid";
import "flag-icon-css/css/flag-icon.css";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Background } from "../../assets/images/ddm-background.svg";
import { ReactComponent as BackgroundDark } from "../../assets/images/ddm-background-dark.svg";
import { resetCalculator, setActivePage } from "../../store/main/actions";
import { getCurrentCountry } from "../../store/main/selectors";
import { AnalyticsContext } from "../../services/analytics";
import { getCurrentLanguage } from "../../store/content/selectors";
import { Routes } from "../../routes";
import useContent from "../../hooks/useContent";

const MAX_WIDTH_HEADER = 1440;

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.colors.black,
    minHeight: "100vh",
    position: "relative",
    boxSizing: "border-box"
  },
  innerContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    padding: `${theme.spaceUnit.xxl} 0`,
    width: "100%",
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.lg} 0`
      }
    },
    overflow: "hidden"
  },
  background: {
    position: "absolute",
    maxHeight: "100%",
    opacity: 0.5,
    zIndex: 0,
    pointerEvents: "none",
    top: 0,
    width: "100%",
    "@media": {
      "screen and (max-width: 700px)": {
        height: "120vh",
        opacity: 1
      }
    }
  },
  header: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "stretch",
    minHeight: "20vh",
    maxWidth: MAX_WIDTH_HEADER,
    margin: "auto",
    width: "100%",
    padding: `0 calc(${theme.spaceUnit.xxl} * 2)`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `0 ${theme.spaceUnit.lg}`
      }
    }
  },
  flag: {
    marginTop: 10,
    display: "block",
    width: theme.spaceUnit.xl,
    height: theme.spaceUnit.xl,
    borderRadius: theme.spaceUnit.xl,
    cursor: "pointer"
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh"
  },
  title: {
    // lineHeight: 1,
    // fontSize: 76,
    color: theme.colors.white,
    textAlign: "center",
    textTransform: "uppercase",
    "@media": {
      "screen and (max-width: 700px)": {
        fontSize: "31px !important ",
        lineHeight: "36px !important"
      }
    }
  },
  body: {
    display: "block",
    marginTop: theme.spaceUnit.lg,
    color: theme.colors.white,
    textAlign: "center",
    maxWidth: "80%",
    marginBottom: theme.spaceUnit.xxl
  },
  button: {
    ...theme.typography.buttonText,
    lineHeight: 1,
    color: theme.colors.white,
    border: `1px solid ${theme.colors.white}`,
    borderRadius: 50,
    padding: `${theme.spaceUnit.md} ${theme.spaceUnit.xxl}`
  },
  buttonDark: {
    ...theme.typography.buttonText,
    lineHeight: 1,
    color: theme.colors.black,
    border: `1px solid ${theme.colors.black}`,
    borderRadius: 50,
    padding: `${theme.spaceUnit.md} ${theme.spaceUnit.xxl}`
  },
  spacer: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "center",
    minHeight: "30vh"
  },
  anchor: {
    ...theme.typography.buttonText,
    color: theme.colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  arrow: {
    marginTop: theme.spaceUnit.md
  },
  subTitle: {
    color: theme.colors.white,
    marginBottom: `calc(${theme.spaceUnit.xxl} * 2)`,
    textAlign: "center",
    paddingTop: theme.spaceUnit.lg,
    paddingRight: theme.spaceUnit.lg,
    paddingLeft: theme.spaceUnit.lg,
    "@media": {
      "screen and (max-width: 700px)": {
        marginBottom: theme.spaceUnit.xl,
        paddingTop: 0
      }
    }
  },
  footer: {
    boxSizing: "border-box",
    backgroundColor: theme.colors.white,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `calc(${theme.spaceUnit.xxl} * 2) ${theme.spaceUnit.xl} ${theme.spaceUnit.lg} ${theme.spaceUnit.xl}`,
    position: "relative",
    justifyContent: "space-between",
    textAlign: "center"
  },
  footerButton: {
    marginTop: theme.spaceUnit.xxl,
    marginBottom: `calc(${theme.spaceUnit.xxl} * 2)`
  },
  privacyPolicy: {
    textDecoration: "none",
    cursor: "pointer",
    color: theme.colors.grey.grey50,
    position: "absolute",
    bottom: theme.spaceUnit.xl,
    right: theme.spaceUnit.xl,
    "@media": {
      "screen and (max-width: 700px)": {
        position: "initial"
      }
    }
  }
});

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const country = useSelector(getCurrentCountry);
  const language = useSelector(getCurrentLanguage);
  const { general: contentGeneral, home: content } = useContent();

  useEffect(() => {
    dispatch(resetCalculator());
  }, [dispatch]);

  const onStart = useCallback(() => {
    analytics.logEvent("START_CALCULATOR");
    dispatch(setActivePage(0));
  }, [analytics, dispatch]);

  const [styles, cx] = useStyles(stylesheet);
  return (
    <div className={cx(styles.container)}>
      <Background className={cx(styles.background)} />
      <div className={cx(styles.innerContainer)}>
        <div className={cx(styles.header)}>
          <Logo fill="white" />
          <Link to={Routes.COUNTRY_SELECTOR}>
            {country?.id?.includes("demo") ? (
              <Icon name="dm-logo" fill="white" size={30} />
            ) : (
              <span
                className={`flag-icon-background flag-icon-${country?.flag.toLowerCase()} flag-icon-squared ${cx(
                  styles.flag
                )}`}
              />
            )}
          </Link>
        </div>
        <div className={cx(styles.titleContainer)}>
          <PageTitle className={cx(styles.title)}>{contentGeneral.general_draughtmaster}</PageTitle>
          <PageTitle className={cx(styles.title)}>{contentGeneral.general_calculator}</PageTitle>
          <Body className={cx(styles.body)}>{content.home_subtitle}</Body>
          <Link to="/today/taps" className={cx(styles.button)} onClick={onStart}>
            {content.home_start_calculator}
          </Link>
        </div>
        <div className={cx(styles.spacer)}>
          <a href="#beverages" className={cx(styles.anchor)}>
            <ButtonText>{content.home_explore}</ButtonText>
            <Icon name="arrow-down" className={cx(styles.arrow)} fill="white" size={16} />
          </a>
        </div>
        <div>
          <PageTitle className={cx(styles.subTitle)} id="beverages">
            {content.home_more_beers}
          </PageTitle>
          <BeveragesGrid />
        </div>
      </div>
      <div className={cx(styles.footer)}>
        <BackgroundDark className={cx(styles.background)} />
        <Subtitle>{content.home_footer_subtitle}</Subtitle>
        <Link
          to="/today/taps"
          className={`${cx(styles.button)} ${cx(styles.buttonDark)} ${cx(styles.footerButton)}`}
          onClick={onStart}
        >
          {content.home_start_calculator}
        </Link>
        <Logo />

        <a
          href={`https://legal.dmbuddy.io/?locale=${language}#privacy_policy__cookies`}
          target="_blank"
          rel="noopener noreferrer"
          className={cx(styles.privacyPolicy)}
        >
          {contentGeneral.general_privacy_policy}
        </a>
      </div>
    </div>
  );
};

export default Home;
