import { IAppState } from "../reducers";
import {IBeverage} from "../types";

export const getTapsInfo = (state: IAppState) => state.tomorrow.tapsInfo;

export const getTotalVolumeSold = (state: IAppState) => state.tomorrow.volumePerYear;

export const getDifferentBrands = (state: IAppState) =>
    state.tomorrow.tapsInfo.reduce((agg: IBeverage[], t) => {
        if (t.beverage?.id && !agg.some(v => v.id === t.beverage?.id)) {
            agg.push(t.beverage);
        }
        return agg;
    }, []);

export const getSellInPrices = (state: IAppState) => state.tomorrow.sellInPrice;

export const getMainVolumeSold = (state: IAppState) =>
  state.tomorrow.tapsInfo.length > 0 && state.tomorrow.tapsInfo[0].config.soldPerYear;
