import { createSelector } from "reselect";
import { IAppState } from "../reducers";
import { IBeverage, ICountryConfiguration } from "../types";
import { getCurrentCountryConfig } from "../main/selectors";
import { VOLUME_TIMEFRAME, VOLUME_UNIT } from "../../constants";

export const getTapsInfo = (state: IAppState) => state.today.tapsInfo;

export const getTotalVolumeSold = (state: IAppState) => state.today.volumePerYear;

export const getDifferentBrands = (state: IAppState) =>
  state.today.tapsInfo.reduce((agg: IBeverage[], t) => {
    if (t.beverage?.id && !agg.some(v => v.id === t.beverage?.id)) {
      agg.push(t.beverage);
    }
    return agg;
  }, []);

export const getSellInPrices = (state: IAppState) => state.today.sellInPrice;

export const getMainVolumeSold = (state: IAppState) =>
  state.today.tapsInfo.length > 0 && state.today.tapsInfo[0].config.soldPerYear;

export const getVolumeConfig = createSelector<
  IAppState,
  any,
  ICountryConfiguration,
  { volumeUnit: VOLUME_UNIT; volumeTimeframe: VOLUME_TIMEFRAME }
>(
  (state: IAppState) => ({
    volumeUnit: state.today.volumeUnit,
    volumeTimeframe: state.today.volumeTimeframe
  }),
  getCurrentCountryConfig,
  (volume, config) => {
    return {
      volumeUnit: volume.volumeUnit || config.unit,
      volumeTimeframe: volume.volumeTimeframe || config.defaultTimeframe
    };
  }
);
