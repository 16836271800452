import { EMainActions } from "./types";

export const setCurrentCountry = (payload: string) => ({
  type: EMainActions.SET_CURRENT_COUNTRY,
  payload
});

export const setActivePage = (payload: number) => ({
  type: EMainActions.SET_ACTIVE_PAGE,
  payload
});

export const resetCalculator = () => ({
  type: EMainActions.RESET_CALCULATOR
});
