import React, { useCallback, useState } from "react";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import {
  Body,
  Description,
  Subtitle,
  SectionTitle,
  BodyHighlight,
  Label,
  DescriptionMedium,
  PageTitle
} from "@ddm-design-system/typography";
import { MessageTooltip } from "@ddm-design-system/tooltip";
import { useSelector } from "react-redux";
import { Icon } from "@ddm-design-system/icon";
import numeral from "numeral";
import useIsMobile from "../../hooks/useIsMobile";
import { currencyMap, VOLUME_UNIT } from "../../constants";
import AvatarTaps from "../avatar-taps/avatar-taps";
import { formatCurrency } from "../../helpers";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import useContent from "../../hooks/useContent";

interface IProps {
  todayReport: any;
  tomorrowReport: any;
}

const MIN_WIDTH_TABLE = 80;
const TABLE_TRANSITION = 0.8;
const TABLE_TRANSITION_2 = 0.4;
const TABLE_TRANSITION_DELAY = 0.3;

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  reportSection: {
    padding: `${theme.spaceUnit.xxl} calc(${theme.spaceUnit.md} * 4) ${theme.spaceUnit.xl} `,
    borderBottom: `1px solid ${theme.colors.grey.grey50}`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.xl} 0`
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.xl} 0`
      }
    }
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.lg
  },
  sectionContainer: {
    boxSizing: "border-box",
    marginTop: `calc(${theme.spaceUnit.md} * 3)`,
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  tableComparison: {
    display: "flex"
  },
  tableContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.white,
    "@selectors": {
      "&:first-child": {
        paddingRight: theme.spaceUnit.xxl,
        marginRight: theme.spaceUnit.xxl,
        borderRight: `1px solid ${theme.colors.grey.grey50}`
      },
      "&:not(:first-child)": {
        marginRight: "0 !important"
      }
    },
    "@media": {
      "screen and (max-width: 900px)": {
        paddingRight: `${theme.spaceUnit.md} !important`,
        marginRight: `${theme.spaceUnit.md} !important`
      },
      "screen and (max-width: 700px)": {
        display: "flex",
        minWidth: MIN_WIDTH_TABLE,
        transition: `flex ${TABLE_TRANSITION}s  ease-out`,
        flex: 0,
        backgroundColor: theme.colors.white,
        "@selectors": {
          "&:first-child": {
            paddingRight: "0 !important",
            marginRight: "0 !important",
            borderRight: `1px solid ${theme.colors.grey.grey25}`,
            minWidth: `calc(${MIN_WIDTH_TABLE}px + ${theme.spaceUnit.lg})`
          },
          "&:not(:first-child)": {
            boxSizing: "border-box",
            paddingLeft: `${theme.spaceUnit.lg} !important`,
            minWidth: `calc(${MIN_WIDTH_TABLE}px + ${theme.spaceUnit.lg})`
          },
          "&.active": {
            flex: "100% !important"
          },
          "&:not(.active) .dismissable": {
            opacity: 0,
            borderBottomColor: "transparent"
          },
          "&.active .dismissable": {
            transitionDelay: `${TABLE_TRANSITION_DELAY}s`
          }
        }
      }
    }
  },
  stickyElement: {
    position: "sticky",
    top: 0
  },
  stickyWithIcon: {
    boxSizing: "border-box",
    paddingRight: theme.spaceUnit.lg
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    borderBottom: `1px solid ${theme.colors.grey.grey25}`
  },
  tableHeader: {
    whiteSpace: "nowrap",
    color: theme.colors.grey.grey100,
    textAlign: "left",
    padding: `${theme.spaceUnit.md} ${theme.spaceUnit.xs}`,
    transition: `all ${TABLE_TRANSITION_2}s linear`,
    opacity: 1
  },
  tableCell: {
    padding: `${theme.spaceUnit.lg} ${theme.spaceUnit.xs}`,
    borderBottom: `1px solid ${theme.colors.grey.grey25}`,
    whiteSpace: "nowrap",
    transition: `all ${TABLE_TRANSITION_2}s linear`,
    opacity: 1,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.md} ${theme.spaceUnit.xs}`
      }
    }
  },
  tableCellTomorrow: {
    textAlign: "right",
    "@media": {
      "screen and (max-width: 700px)": {
        textAlign: "left"
      }
    }
  },
  tableCellLogo: {
    padding: `${theme.spaceUnit.xs} 0`,
    paddingRight: theme.spaceUnit.md,
    borderBottom: "1px solid transparent",

    "@media": {
      "screen and (max-width: 700px)": {
        transition: `all ${TABLE_TRANSITION_2}s linear`,
        "@selectors": {
          "&.active": {
            paddingLeft: theme.spaceUnit.md,
            borderBottomColor: theme.colors.grey.grey25
          }
        }
      }
    }
  },
  tableCellLogoTomorrow: {
    display: "flex",
    justifyContent: "flex-end",
    padding: `${theme.spaceUnit.xs} 0 `,
    paddingLeft: theme.spaceUnit.md,
    borderBottom: "1px solid transparent",
    "@media": {
      "screen and (max-width: 700px)": {
        transition: `all ${TABLE_TRANSITION_2}s linear`,
        paddingLeft: 0,
        paddingRight: theme.spaceUnit.md,
        "@selectors": {
          "&.active": {
            paddingLeft: theme.spaceUnit.md,
            borderBottomColor: theme.colors.grey.grey25
          },
          "&.active.last": {
            borderBottomColor: "transparent"
          }
        }
      }
    }
  },
  tableSummary: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: theme.spaceUnit.lg,
    "@media": {
      "screen and (max-width: 700px)": {
        alignItems: "center !important",
        justifyContent: "center !important"
      }
    }
  },
  tableSummaryEnd: {
    alignItems: "flex-end"
  },
  tapsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "uppercase"
  },
  totalRevenue: {
    "@media": {
      "screen and (max-width: 700px)": {
        whiteSpace: "nowrap",
        marginBottom: theme.spaceUnit.xs
      }
    }
  },
  totalValue: {
    whiteSpace: "nowrap",
    "@media": {
      "screen and (max-width: 700px)": {
        fontSize: `${theme.typography.sectionTitle.fontSize} !important`
      }
    }
  },
  tableIcon: {
    position: "absolute",
    right: "-20px",
    top: "calc(50% - 20px)",
    transform: "rotate(-90deg)",
    transition: `transform ${TABLE_TRANSITION}s linear`,
    width: theme.spaceUnit.lg,
    height: theme.spaceUnit.lg,
    padding: theme.spaceUnit.xs,
    outline: "none",
    backgroundColor: theme.colors.white,
    "@selectors": {
      "&.today-active": {
        transform: "rotate(90deg)"
      },
      "> .y-icon": {
        backgroundColor: theme.colors.white
      },
      "> .y-icon circle": {
        fill: theme.colors.grey.grey100
      },
      "> .y-icon path": {
        fill: theme.colors.grey.grey100
      }
    },
    "@media": {
      "screen and (min-width: 700px)": {
        display: "none"
      }
    }
  },
  tooltipLabel: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%",
    minHeight: theme.spaceUnit.md
  }
});

const RevenueSectionTomorrow: React.FC<IProps> = ({ todayReport, tomorrowReport }) => {
  const [styles, cx] = useStyles(stylesheet);
  const isMobile = useIsMobile();
  const { report: content, general: contentGeneral } = useContent();
  const countryConfig = useSelector(getCurrentCountryConfig);

  const SectionTitleFont = isMobile ? SectionTitle : Subtitle;
  const BodyFont = isMobile ? Description : Body;
  const HeadersFont = isMobile ? Description : BodyHighlight;
  const TotalTextFont = isMobile ? Description : SectionTitle;
  const ValueFont = isMobile ? DescriptionMedium : PageTitle;

  const [isTodayTableActive, setTodayTableActive] = useState(false);

  const reverseMobile = useCallback(array => (isMobile ? array.reverse() : array), [isMobile]);

  return (
    <section className={cx(styles.reportSection)}>
      <div className={cx(styles.sectionHeader)}>
        <SectionTitleFont className={cx(styles.sectionTitle)}>
          {content.report_taps}
        </SectionTitleFont>
        <BodyFont>
          {content.report_taps_tomorrow_subtitle}{" "}
          {countryConfig.useTax ? `(${contentGeneral.general_incl_tax})` : ""}
        </BodyFont>
      </div>

      <div className={cx(styles.sectionContainer)}>
        <div className={cx(styles.tapsHeader)}>
          <HeadersFont>{contentGeneral.general_today}</HeadersFont>
          <HeadersFont>{contentGeneral.general_tomorrow}</HeadersFont>
        </div>
        <div className={cx(styles.tableComparison)}>
          <div className={cx(styles.tableContainer, isMobile && isTodayTableActive && "active")}>
            <div className={cx(styles.stickyElement, styles.stickyWithIcon)}>
              <table className={cx(styles.table)}>
                <thead>
                  <tr>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th />
                    <th className={cx(styles.tableHeader, "dismissable")}>
                      <MessageTooltip
                        renderHeader={() => (
                          <Label className={cx(styles.tooltipLabel)}>
                            {isMobile ? content.report_hl_year_short : content.report_hl_year_long}
                            &nbsp;
                            <Icon name="info" size={10} />
                          </Label>
                        )}
                      >
                        {content.report_total_volume_converted}
                      </MessageTooltip>
                    </th>
                    <th className={cx(styles.tableHeader, "dismissable")}>
                      <Label>
                        {isMobile
                          ? (content.report_price_year_short || "").replace(
                              "%UNIT%",
                              currencyMap[countryConfig.currency]
                            )
                          : content.report_price_year_long}
                      </Label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {todayReport.taps.map((tap: any, i: number) => (
                    <tr key={i}>
                      <td
                        className={cx(
                          styles.tableCellLogo,
                          isMobile && !isTodayTableActive && "active"
                        )}
                      >
                        <AvatarTaps beverage={tap.beverage} />
                      </td>
                      <td className={cx(styles.tableCell, "dismissable")}>
                        <Body>
                          {tap.hlPerYear} {currencyMap[VOLUME_UNIT.HL]}
                        </Body>
                      </td>
                      <td className={cx(styles.tableCell, "dismissable")}>
                        <Body>
                          {formatCurrency(tap.pricePerServing,countryConfig.currency, false, true)}
                        </Body>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={cx(styles.tableSummary)}>
                <TotalTextFont className={cx(styles.totalRevenue)}>
                  {content.report_total_revenue}
                </TotalTextFont>
                <ValueFont className={cx(styles.totalValue)}>
                  {formatCurrency(todayReport.revenue, countryConfig.currency)}
                </ValueFont>
              </div>
              {isMobile && (
                <div
                  role="button"
                  onKeyDown={() => setTodayTableActive(!isTodayTableActive)}
                  tabIndex={-1}
                  onClick={() => setTodayTableActive(!isTodayTableActive)}
                  className={cx(styles.tableIcon, isTodayTableActive && "today-active")}
                >
                  <Icon name="arrow-down" size={24} />
                </div>
              )}
            </div>
          </div>

          <div className={cx(styles.tableContainer, isMobile && !isTodayTableActive && "active")}>
            <div className={cx(styles.stickyElement)}>
              <table className={cx(styles.table)}>
                <thead>
                  <tr>
                    {reverseMobile([
                      <th
                        key="price"
                        className={cx(styles.tableHeader, styles.tableCellTomorrow, "dismissable")}
                      >
                        <Label>
                          {isMobile
                            ? (content.report_price_year_short || "").replace(
                                "%UNIT%",
                                currencyMap[countryConfig.currency]
                              )
                            : content.report_price_year_long}
                        </Label>
                      </th>,
                      <th
                        key="volume"
                        className={cx(styles.tableHeader, styles.tableCellTomorrow, "dismissable", {
                          textAlign: "right !important"
                        })}
                      >
                        <MessageTooltip
                          renderHeader={() => (
                            <Label
                              className={cx(styles.tooltipLabel, {
                                justifyContent: "flex-end"
                              })}
                            >
                              {isMobile
                                ? content.report_hl_year_short
                                : content.report_hl_year_long}
                              &nbsp;
                              <Icon name="info" size={10} />
                            </Label>
                          )}
                        >
                          {content.report_total_volume_converted}
                        </MessageTooltip>
                      </th>,
                      // eslint-disable-next-line jsx-a11y/control-has-associated-label
                      <th key="logo" />
                    ])}
                  </tr>
                </thead>
                <tbody>
                  {tomorrowReport.taps.map((tap: any, i: number) => (
                    <tr key={i}>
                      {reverseMobile([
                        <td
                          key="price"
                          className={cx(styles.tableCell, styles.tableCellTomorrow, "dismissable")}
                        >
                          <Body>
                            {formatCurrency(tap.pricePerServing,countryConfig.currency, false, true)}
                          </Body>
                        </td>,
                        <td
                          key="volume"
                          className={cx(styles.tableCell, styles.tableCellTomorrow, "dismissable")}
                        >
                          <Body>
                            {tap.hlPerYear} {currencyMap[VOLUME_UNIT.HL]}
                          </Body>
                        </td>,
                        <td
                          key="logo"
                          className={cx(
                            styles.tableCellLogoTomorrow,
                            isMobile && isTodayTableActive && "active",
                            isMobile &&
                              isTodayTableActive &&
                              i === tomorrowReport.taps.length - 1 &&
                              "last"
                          )}
                        >
                          <AvatarTaps beverage={tap.beverage} />
                        </td>
                      ])}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={cx(styles.tableSummary, styles.tableSummaryEnd)}>
                <TotalTextFont className={cx(styles.totalRevenue)}>
                  {content.report_total_revenue}
                </TotalTextFont>
                <ValueFont className={cx(styles.totalValue)}>
                  {formatCurrency(tomorrowReport.revenue, countryConfig.currency)}
                </ValueFont>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RevenueSectionTomorrow;
