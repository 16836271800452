import { IPages } from "./pages/today/types";
import Taps from "./pages/today/taps";
import Pricing from "./pages/today/pricing";
import Co2Cost from "./pages/today/co2Cost";
import Report from "./pages/today/report";
import Brands from "./pages/today/brands";
import Volume from "./pages/today/volume";
import VolumeDistribution from "./pages/today/volumeDistribution";
import TapsTomorrow from "./pages/tomorrow/taps";
import VolumeTomorrow from "./pages/tomorrow/volume";
import BrandsTomorrow from "./pages/tomorrow/brands";
import VolumeDistributionTomorrow from "./pages/tomorrow/volumeDistribution";
import PurchasingTomorrow from "./pages/tomorrow/purchasing";
import PricingTomorrow from "./pages/tomorrow/pricing";
import ReportTomorrow from "./pages/tomorrow/reportPage";
import Purchasing from "./pages/today/purchasing";
import {EMarketType, ICo2Info, ICountryConfiguration} from "./store/types";

export enum Routes {
  HOME = "/",
  COUNTRY_SELECTOR = "/country",
  TODAY_TAPS = "/today/taps",
  TODAY_BRANDS = "/today/brands",
  TODAY_VOLUME = "/today/volume",
  TODAY_VOLUME_DISTRIBUTION = "/today/volumedistribution",
  TODAY_PURCHASING = "/today/purchasing",
  TODAY_PRICING = "/today/pricing",
  TODAY_CO2_COST = "/today/co2cost",
  TODAY_REPORT = "/today/report",
  TOMORROW_TAPS = "/tomorrow/taps",
  TOMORROW_BRANDS = "/tomorrow/brands",
  TOMORROW_VOLUME = "/tomorrow/volume",
  TOMORROW_VOLUME_DISTRIBUTION = "/tomorrow/volumedistribution",
  TOMORROW_PURCHASING = "/tomorrow/purchasing",
  TOMORROW_PRICING = "/tomorrow/pricing",
  TOMORROW_REPORT = "/tomorrow/report",
  TOMORROW_SHARE = "/tomorrow/share",
  TOMORROW_PDF = "/tomorrow/report.pdf"
}

const todayPages: IPages[] = [
  { Component: Taps, path: Routes.TODAY_TAPS },
  { Component: Brands, path: Routes.TODAY_BRANDS },
  { Component: Volume, path: Routes.TODAY_VOLUME },
  { Component: VolumeDistribution, path: Routes.TODAY_VOLUME_DISTRIBUTION },
  { Component: Purchasing, path: Routes.TODAY_PURCHASING /* guard: [EMarketType.indirect, EMarketType.both] */  },
  { Component: Pricing, path: Routes.TODAY_PRICING },
  { Component: Co2Cost, path: Routes.TODAY_CO2_COST, guard: "co2_not_fixed" },
  { Component: Report, path: Routes.TODAY_REPORT }
];

const tomorrowPages: IPages[] = [
  { Component: TapsTomorrow, path: Routes.TOMORROW_TAPS },
  { Component: BrandsTomorrow, path: Routes.TOMORROW_BRANDS },
  { Component: VolumeTomorrow, path: Routes.TOMORROW_VOLUME },
  {
    Component: VolumeDistributionTomorrow,
    path: Routes.TOMORROW_VOLUME_DISTRIBUTION
  },
  { Component: PurchasingTomorrow, path: Routes.TOMORROW_PURCHASING /* guard:[ EMarketType.indirect, EMarketType.both] */  },
  { Component: PricingTomorrow, path: Routes.TOMORROW_PRICING },
  { Component: ReportTomorrow, path: Routes.TOMORROW_REPORT }
];

export const calculatorPages = {
  today: todayPages,
  tomorrow: tomorrowPages
};

export function getCalculatorPages(marketType?: EMarketType,co2Costs?: ICountryConfiguration["co2Costs"]) {
  return {
    today: calculatorPages.today.filter(p => !p.guard || (p.guard === "co2_not_fixed" && co2Costs?.fixed === -1) || marketType && p.guard.includes(marketType)),
    tomorrow: calculatorPages.tomorrow.filter(p => !p.guard || marketType && p.guard.includes(marketType))
  };
}
