import React from "react";
import {CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles} from "@ddm-design-system/base";
import {Body, BodyHighlight, Description, SectionTitle, Subtitle} from "@ddm-design-system/typography";
import {useSelector} from "react-redux";
import useIsMobile from "../../hooks/useIsMobile";
import WasteLine from "./WasteLine";
import {getCurrentCountryConfig} from "../../store/main/selectors";
import {formatCurrency, NUMBER_OF_HOURS_PER_CLEANING} from "../../helpers";
import useContent from "../../hooks/useContent";

interface IProps {
  todayReport: any;
  tomorrowReport?: any;
}

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  reportSection: {
    padding: `${theme.spaceUnit.xxl} 0 ${theme.spaceUnit.xl} `,
    borderBottom: `1px solid ${theme.colors.grey.grey50}`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.xl} 0`
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.xl} 0`
      }
    }
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.lg
  },
  sectionContainer: {
    boxSizing: "border-box",
    marginTop: `calc(${theme.spaceUnit.md} * 3)`,
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  wasteHeaders: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "uppercase",
    padding: `${theme.spaceUnit.lg} calc(${theme.spaceUnit.md} * 4)`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.lg} 0`
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.lg} 0`
      }
    }
  }
});

const CostSection: React.FC<IProps> = ({todayReport, tomorrowReport}) => {
  const [styles, cx] = useStyles(stylesheet);
  const countryConfig = useSelector(getCurrentCountryConfig);
  const isMobile = useIsMobile();
  const {report: content, general: contentGeneral} = useContent();
  const useSteelSimplifiedVersion = countryConfig.wastage.simplifiedWasteSteel !== -1;
  const useDMSimplifiedVersion = countryConfig.wastage.simplifiedWasteDm !== -1;
  const existsRentalCost = countryConfig.wastage.steelRentalCost;
  const SectionTitleFont = isMobile ? SectionTitle : Subtitle;
  const BodyFont = isMobile ? Description : Body;
  const HeadersFont = isMobile ? Description : BodyHighlight;
  
  return (
    <section
      className={cx(styles.reportSection, {
        paddingBottom: "0 !important"
      })}
    >
      <div className={cx(styles.sectionHeader)}>
        <SectionTitleFont className={cx(styles.sectionTitle)}>
          {content.report_costs}
        </SectionTitleFont>
        <BodyFont>{content.report_costs_subtitle}</BodyFont>
      </div>
      <div className={cx(styles.sectionContainer)}>
        {tomorrowReport && (
          <div className={cx(styles.wasteHeaders)}>
            <HeadersFont>{contentGeneral.general_today}</HeadersFont>
            <HeadersFont>{contentGeneral.general_tomorrow}</HeadersFont>
          </div>
        )}
        {countryConfig.useTax && (
          <WasteLine
            todayValue={-(todayReport.wastage.taxCost || 0)}
            tomorrowValue={tomorrowReport && -(tomorrowReport.wastage.taxCost || 0)}
            description={(content.report_tax_costs_desc || "").replace(
              "%PERCENTAGE%",
              `${Math.round(countryConfig.tax * 100)}%`
            )}
          >
            {content.report_tax_costs}
          </WasteLine>
        )}
        <WasteLine
          todayValue={-(todayReport.wastage.purchasingCost || 0)}
          tomorrowValue={tomorrowReport && -(tomorrowReport.wastage.purchasingCost || 0)}
          description={content.report_cogs_desc}
        >
          {content.report_cogs}
        </WasteLine>
        
        <WasteLine
          todayValue={-(todayReport.wastage.co2Cost || 0)}
          tomorrowValue={tomorrowReport && -(tomorrowReport.wastage.co2Cost || 0)}
          description={content.report_co2_cost_desc}
        >
          {content.report_co2_cost}
        </WasteLine>
        
        {existsRentalCost && (
          <WasteLine
            todayValue={-(todayReport.wastage.rentalCost || 0)}
            tomorrowValue={tomorrowReport && -(tomorrowReport.wastage.rentalCost || 0)}
            description={content.report_rental_cost_desc || "Rental Cost desc"}
          >
            {content.report_rental_cost || "Annual Rental Cost"}
          </WasteLine>
        )}
        
        {!useSteelSimplifiedVersion && (
          <WasteLine
            todayValue={-(todayReport.wastage.wasteChangingKeg || 0)}
            tomorrowValue={tomorrowReport && -(tomorrowReport.wastage.wasteChangingKeg || 0)}
            description={`${countryConfig.wastage.wasteFromBeerChanging !== 0 ? (content.report_waste_changing_desc || "").replace(
              "%NUMBER%",
              `${countryConfig.wastage.wasteFromBeerChanging}`
            ): ""}\n${countryConfig.wastage.wasteFromChangingLabour !== 0 ? (content.report_waste_changing_labour_desc || "").replace(
              "%NUMBER%",
              `${formatCurrency(countryConfig.wastage.wasteFromChangingLabour, countryConfig.currency, false)}`
            ): ""}`}
          >
            {content.report_waste_changing}
          </WasteLine>
        )}
        
        {!useSteelSimplifiedVersion && (
          <WasteLine
            todayValue={-(todayReport.wastage.wasteRemainingBeer || 0)}
            tomorrowValue={tomorrowReport && -(tomorrowReport.wastage.wasteRemainingBeer || 0)}
            description={(content.report_waste_remaining_desc || "").replace(
              "%PERCENTAGE%",
              `${countryConfig.wastage.beerRemainingInsideKeg}%`
            )}
          >
            {content.report_waste_remaining}
          </WasteLine>
        )}
        
        {!useSteelSimplifiedVersion && (
          <WasteLine
            todayValue={-(todayReport.wastage.wasteBeerGoneBad || 0)}
            tomorrowValue={tomorrowReport && -(tomorrowReport.wastage.wasteBeerGoneBad || 0)}
            description={content.report_waste_beer_desc}
          >
            {content.report_waste_beer}
          </WasteLine>
        )}
        
        <WasteLine
          todayValue={-(todayReport.wastage.wasteCleaningSteel || 0)}
          tomorrowValue={tomorrowReport && -(tomorrowReport.wastage.wasteCleaningSteel || 0)}
          description={
            !useSteelSimplifiedVersion
              ? (content.report_waste_cleaning_steel_desc_not_simplified || "").replace(
                "%NUMBER%",
                `${countryConfig.wastage.wasteFromCleaningSteel}`
              )
              : (content.report_waste_cleaning_steel_desc_simplified || "").replace(
                "%PERCENTAGE%",
                `${countryConfig.wastage.simplifiedWasteSteel}%`
              )
          }
        >
          {content.report_waste_cleaning_steel}
        </WasteLine>
        
        {tomorrowReport && (
          <WasteLine
            todayValue={-(todayReport.wastage.wasteCleaningDm || 0)}
            tomorrowValue={-(tomorrowReport.wastage.wasteCleaningDm || 0)}
            last
            description={
              !useDMSimplifiedVersion
                ? (content.report_waste_cleaning_dm_desc_not_simplified || "")
                  .replace("%NUMBER%", `${countryConfig.wastage.wasteFromCleaningDm}`)
                  .replace("%HOURS%", `${NUMBER_OF_HOURS_PER_CLEANING}`)
                : (content.report_waste_cleaning_dm_desc_simplified || "").replace(
                  "%PERCENTAGE%",
                  `${countryConfig.wastage.simplifiedWasteDm}%`
                )
            }
          >
            {content.report_waste_cleaning_dm}
          </WasteLine>
        )}
        
        <WasteLine
          todayValue={-(todayReport.cost || 0)}
          tomorrowValue={tomorrowReport && -(tomorrowReport.cost || 0)}
          last
          status
        >
          {content.report_total_costs}
        </WasteLine>
        
        <WasteLine
          todayValue={todayReport.revenue || 0}
          tomorrowValue={tomorrowReport && (tomorrowReport.revenue || 0)}
          last
          status
        >
          {content.report_total_revenue}
        </WasteLine>
        
        <WasteLine
          todayValue={todayReport.profit || 0}
          tomorrowValue={tomorrowReport && (tomorrowReport.profit || 0)}
          profit
        >
          {content.report_profit}
        </WasteLine>
      </div>
    </section>
  );
};

export default CostSection;
