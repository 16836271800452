import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import { InputSpinner } from "@ddm-design-system/input-spinner";
import { SectionTitle, PageTitle, Subtitle, BodyHighlight } from "@ddm-design-system/typography";
import { TapAvatar } from "@ddm-design-system/avatar";
import Base, { MAX_WIDTH, MAX_WIDTH_TABLET } from "../../templates/base";
import { IAppState } from "../../store/reducers";
import { setTotalTaps } from "../../store/today/actions";
import useIsMobile from "../../hooks/useIsMobile";
import useContent from "../../hooks/useContent";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  tapsPage: {
    color: theme.colors.black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.xxl,
    "@media": {
      "screen and (max-width: 700px)": {
        marginBottom: theme.spaceUnit.lg
      }
    }
  },
  tapsContainer: {
    height: 200,
    minHeight: 200,
    position: "relative",
    "@media": {
      "screen and (max-width: 700px)": {
        height: 96,
        minHeight: 96
      }
    }
  },
  tapsCarousel: {
    display: "flex",
    overflowX: "auto",
    position: "absolute",
    left: "calc(-50vw + 50%)",
    width: "100vw",
    scrollbarWidth: "none",
    marginTop: theme.spaceUnit.lg,
    "-ms-overflow-style": "none",
    "@selectors": {
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0
      }
    }
  },
  tapRow: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    padding: `0 calc(calc(100vw - ${MAX_WIDTH}px) / 2)`,
    "@media": {
      "screen and (max-width: 900px)": {
        padding: `0 calc(calc(100vw - ${MAX_WIDTH_TABLET}px) / 2)`
      },
      "screen and (max-width: 700px)": {
        padding: `0 ${theme.spaceUnit.lg}`
      }
    }
  },
  avatarWrapper: {
    margin: theme.spaceUnit.lg,
    "@media": {
      "screen and (max-width: 700px)": {
        margin: theme.spaceUnit.xs
      }
    }
  }
});

interface IProps {}

const Taps: React.FC<IProps> = () => {
  const [styles, cx] = useStyles(stylesheet);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { taps: content } = useContent();
  const taps = useSelector((state: IAppState) => state.today.tapsInfo);

  const onChange = useCallback(
    (v: number) => {
      if (!Number.isNaN(v) && v >= 0) {
        dispatch(setTotalTaps(v));
      }
    },
    [dispatch]
  );

  const TitleFont = isMobile ? Subtitle : PageTitle;
  const SubtitleFont = isMobile ? BodyHighlight : SectionTitle;

  return (
    <Base fixedHeight>
      <div className={cx(styles.tapsPage)}>
        <TitleFont className={cx(styles.sectionTitle)}>{content.taps_today}</TitleFont>
        <SubtitleFont className={cx(styles.sectionTitle)}>
          {content.taps_today_subtitle}
        </SubtitleFont>
        <InputSpinner value={taps.length} onChange={onChange} max={12} />
        <section className={cx(styles.tapsContainer)}>
          <div className={cx(styles.tapsCarousel)}>
            <div className={cx(styles.tapRow)}>
              {taps.map((t, i) => (
                <div key={i} className={cx(styles.avatarWrapper)}>
                  <TapAvatar size="sm" />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Base>
  );
};

export default Taps;
