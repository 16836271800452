import React from "react";

export interface IAnalyticsService {
  setup(): void;
  getCookieConsent(): boolean;
  logPageView(name: string): void;
  logEvent(
    category: string,
    action?: string,
    label?: string,
    value?: number
  ): void;
}

declare let ga: any;

export class GAAnalyticsService implements IAnalyticsService {
  getCookieConsent() {
    const cc = localStorage.getItem("cookieConsent") || "true";
    return cc === "true";
  }

  setup() {
    const gaKey = "UA-148889548-6";
    const gaOptimizeKey = "";
    if (!gaKey) {
      return;
    }
    const script = document.createElement("script");
    script.innerHTML = `
       (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

     ga('create', '${gaKey}', 'auto'); ga('require', '${gaOptimizeKey}');ga('set', 'anonymizeIp', true);
    `;
    document.head.appendChild(script);

    // enableExperiment();
  }

  logPageView(name: string) {
    if (this.getCookieConsent() && typeof ga === "function") {
      ga("set", "page", name);
      ga("send", "pageview");
    }
  }

  logEvent(category: string, action?: string, label?: string, value?: number) {
    if (this.getCookieConsent() && typeof ga === "function") {
      ga("send", "event", {
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value
      });
    }
  }
}
export const AnalyticsContext = React.createContext<IAnalyticsService>(
  new GAAnalyticsService()
);
export const AnalyticsProvider = AnalyticsContext.Provider;
export const AnalyticsConsumer = AnalyticsContext.Consumer;
