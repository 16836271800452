import React, { useMemo } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCalculatorPages, Routes } from "../../routes";
import ShareTomorrow from "./share";
import PrintTomorrow from "./reportPDF";
import {getCurrentCountryCo2Costs, getCurrentCountryMarketType} from "../../store/main/selectors";

const TapsTomorrowWizard = () => {
  const marketType = useSelector(getCurrentCountryMarketType);
  const co2Costs = useSelector(getCurrentCountryCo2Costs);
  const calculatorPages = useMemo(() => getCalculatorPages(marketType, co2Costs), [marketType]);
  const pages = calculatorPages.tomorrow;

  return (
    <Switch>
      <Route path="/tomorrow" exact>
        <Redirect to={pages[0].path} />
      </Route>
      {pages.map(
        ({ Component, path }, index) =>
          pages[index] && (
            <Route path={path} key={path}>
              <Component />
            </Route>
          )
      )}
      <Route path={Routes.TOMORROW_SHARE}>
        <ShareTomorrow />
      </Route>
      <Route path={Routes.TOMORROW_PDF}>
        <PrintTomorrow />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default TapsTomorrowWizard;
