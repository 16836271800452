import { SyncCollection, Entry } from "contentful";

export enum EContentActions {
  GET_CONTENTFUL = "content/GET_CONTENTFUL",
  GET_CONTENTFUL_SUCCESS = "content/GET_CONTENTFUL_SUCCESS",
  GET_CONTENTFUL_ERROR = "content/GET_CONTENTFUL_ERROR",
  SET_CURRENT_LANGUAGE = "content/SET_CURRENT_LANGUAGE",
  SYNC_SUCCESSFUL = "content/SYNC_SUCCESSFUL"
}
export interface ILanguageInfo {
  code: string;
  name: string;
  country: string;
}
export interface IGetContentfulAction {
  type: EContentActions.GET_CONTENTFUL;
}
export interface IGetContentfulSuccessAction {
  type: EContentActions.GET_CONTENTFUL_SUCCESS;
  payload: {
    entries: SyncCollection["entries"];
    nextSyncToken: SyncCollection["nextSyncToken"];
  };
}
export interface IGetContentfulErrorAction {
  type: EContentActions.GET_CONTENTFUL_ERROR;
}
export interface ISetCurrentLanguageAction {
  type: EContentActions.SET_CURRENT_LANGUAGE;
  payload: ILanguageInfo;
}

export interface ISyncSuccessFul {
  type: EContentActions.SYNC_SUCCESSFUL;
  payload: SyncCollection["nextSyncToken"];
}

export interface IContentState {
  entries: IContentfulContent;
  contentfulError: boolean;
  loadingContentful: boolean;
  contentfulLoaded: boolean;
  currentLanguage: string;
  nextSyncToken?: SyncCollection["nextSyncToken"];
}
export interface IContent {
  [key: string]: string;
}
export interface IContentfulContent {
  [key: string]: {
    [language: string]: any;
  };
}
export type IReducerActions =
  | IGetContentfulAction
  | IGetContentfulSuccessAction
  | IGetContentfulErrorAction
  | ISetCurrentLanguageAction
  | ISyncSuccessFul;

export interface ILanguageContent {
  [key: string]: {};
}
export interface IEntries {
  [key: string]: {
    ordered: Array<Entry<any>>;
    keyed: {
      [key: string]: Entry<any>;
    };
  };
}
export interface ILanguageAvailable {
  [key: string]: ILanguageContent;
}
