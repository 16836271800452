import React, {useEffect, useMemo} from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { getCalculatorPages } from "../../routes";
import {getCurrentCountryCo2Costs, getCurrentCountryMarketType} from "../../store/main/selectors";
import {updateCo2Information} from "../../store/today/actions";

const TapsTodayWizard = () => {
  const dispatch = useDispatch();
  const marketType = useSelector(getCurrentCountryMarketType);
  const co2Costs = useSelector(getCurrentCountryCo2Costs);
  const calculatorPages = useMemo(() => getCalculatorPages(marketType, co2Costs), [marketType]);
  const pages = calculatorPages.today;
  
  useEffect(() => {
    if(co2Costs.fixed !== -1) {
      dispatch(updateCo2Information({
        cost: co2Costs.fixed
      }));
    }
  },[co2Costs]);
  
  return (
    <Switch>
      <Route path="/today" exact>
        <Redirect to={pages[0].path} />
      </Route>
      {pages.map(
        ({ Component, path }, index) =>
          pages[index] && (
            <Route path={path} key={path}>
              <Component />
            </Route>
          )
      )}

      <Redirect to="/" />
    </Switch>
  );
};

export default TapsTodayWizard;
