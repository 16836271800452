import { Reducer } from "redux";
import produce from "immer";
import numeral from "numeral";
import { mapContentfulEntries } from ".";
import { EContentActions, IReducerActions, IContentState } from "./types";
import { DEFAULT_LANG } from "../../constants";

const initialState: IContentState = {
  contentfulLoaded: false,
  loadingContentful: false,
  contentfulError: false,
  currentLanguage: DEFAULT_LANG,
  entries: {}
};

const reducer: Reducer<IContentState, IReducerActions> = (
  state = initialState,
  action: IReducerActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case EContentActions.GET_CONTENTFUL:
        draft.loadingContentful = true;
        draft.contentfulError = false;
        break;
      case EContentActions.GET_CONTENTFUL_SUCCESS:
        draft.loadingContentful = false;
        try {
          const obj = mapContentfulEntries(action.payload.entries);
          draft.entries = obj;
          draft.contentfulLoaded = true;
          draft.nextSyncToken = action.payload.nextSyncToken;
          draft.contentfulError = false;
          draft.loadingContentful = false;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("error parsing contentful", e);
          draft.contentfulError = true;
        }

        break;
      case EContentActions.SET_CURRENT_LANGUAGE:
        draft.currentLanguage = action.payload.code;
        numeral.locale(action.payload.code.toLocaleLowerCase());
        break;
      case EContentActions.GET_CONTENTFUL_ERROR:
        draft.loadingContentful = false;
        draft.contentfulError = true;
        break;
      case EContentActions.SYNC_SUCCESSFUL:
        draft.loadingContentful = false;
        draft.contentfulError = false;
        break;
      default:
        break;
    }
  });

export default reducer;
