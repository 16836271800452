import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BodyHighlight,
  Label,
  PageTitle,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import { TapAvatar } from "@ddm-design-system/avatar";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import { Icon } from "@ddm-design-system/icon";
import { MessageTooltip } from "@ddm-design-system/tooltip";
import {setBeverage, updateSellInPrice} from "../../store/today/actions";
import {EMarketType, IBeverage} from "../../store/types";
import {
  getCurrentCountryMarketType,
  getCurrentCountrySteelBeverages, getCurrentCountryUseSellInPrice,
  getCurrentCountryWater
} from "../../store/main/selectors";
import Base, { MAX_WIDTH, MAX_WIDTH_TABLET } from "../../templates/base";
import {getSellInPrices, getTapsInfo} from "../../store/today/selectors";
import { animateScrollLeft } from "../../helpers";
import useIsMobile from "../../hooks/useIsMobile";
import useContent from "../../hooks/useContent";

interface IProps {}

const styleSheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  base: {
    maxHeight: "100vh"
  },
  pageTitle: {
    marginBottom: theme.spaceUnit.lg,
    textAlign: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.lg,
    textAlign: "center"
  },
  tapsContainer: {
    height: 128,
    minHeight: 128,
    position: "relative",
    "@media": {
      "screen and (max-width: 700px)": {
        height: 96,
        minHeight: 96
      }
    }
  },
  tapsCarousel: {
    display: "flex",
    overflowX: "auto",
    position: "absolute",
    left: "calc(-50vw + 50%)",
    width: "100vw",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "@selectors": {
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0
      }
    }
  },
  tapRow: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    padding: `0 calc(calc(100vw - ${MAX_WIDTH}px) / 2)`,
    "@media": {
      "screen and (max-width: 900px)": {
        padding: `0 calc(calc(100vw - ${MAX_WIDTH_TABLET}px) / 2)`
      },
      "screen and (max-width: 700px)": {
        padding: `0 ${theme.spaceUnit.lg}`
      }
    }
  },
  tapItemWrapper: {
    borderBottom: "3px solid transparent",
    margin: `0 ${theme.spaceUnit.lg}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 1,
    "@media": {
      "screen and (max-width: 900px)": {
        margin: `0 ${theme.spaceUnit.sm}`
      },
      "screen and (max-width: 700px)": {
        margin: `0 ${theme.spaceUnit.xs}`
      }
    }
  },
  mainLabel: {
    marginTop: theme.spaceUnit.xs,
    marginBottom: theme.spaceUnit.xs,
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap"
  },
  tapItemSelected: {
    borderBottom: `3px solid ${theme.colors.black}`
  },
  beverageList: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    justifyContent: "center",
    borderTop: `3px solid ${theme.colors.grey.grey25}`,
    paddingTop: theme.spaceUnit.lg,
    marginTop: -5,
    "@media": {
      "screen and (max-width: 900px)": {
        paddingTop: theme.spaceUnit.sm
      },
      "screen and (max-width: 700px)": {
        paddingTop: theme.spaceUnit.xs
      }
    }
  },
  beverageListItem: {
    margin: theme.spaceUnit.lg,
    borderRadius: "50%",
    position: "relative",
    cursor: "pointer",
    height: 80,
    width: 80,
    "@media": {
      "screen and (max-width: 900px)": {
        margin: theme.spaceUnit.sm
      },
      "screen and (max-width: 700px)": {
        margin: theme.spaceUnit.xs,
        height: 52,
        width: 52
      }
    }
  },
  beverageImg: {
    border: `1px solid ${theme.colors.grey.grey25}`,
    cursor: "pointer",
    height: "100%",
    width: "100%",
    borderRadius: "50%"
  },
  overlayImg: {
    position: "absolute",
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    top: 0,
    left: 0,
    "@selectors": {
      "&.selected": {
        backgroundColor: "rgba(0,0,0,0.3)",
        border: "1px solid rgba(0,0,0,0.3)"
      }
    }
  }
});

const Brands: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const [styles, cx] = useStyles(styleSheet);
  const isMobile = useIsMobile();
  const { brands: content, general: contentGeneral } = useContent();
  const tapsInfo = useSelector(getTapsInfo);
  const beverages = useSelector(getCurrentCountrySteelBeverages);
  const waterBeverages = useSelector(getCurrentCountryWater);
  const countryMarketType = useSelector(getCurrentCountryMarketType);
  const useSellInPrice = useSelector(getCurrentCountryUseSellInPrice);
  const sellInPrices = useSelector(getSellInPrices);
  const [selectedTap, setSelectedTap] = useState(0);
  const scrollDiv = useRef<HTMLDivElement>(null);
  const bevList = useRef<HTMLDivElement>(null);
  const tapWidth = isMobile ? 80 : 144;

  const animateScrollLeftCallback = useCallback(
    (index: number) => {
      const element: any = scrollDiv?.current;
      const eventXRight = tapWidth * (index + 1) - element.scrollLeft;
      const eventXLeft = tapWidth * index - element.scrollLeft;
      const listSize = bevList?.current?.offsetWidth || 0;
      const isOutsideRight = eventXRight > listSize;
      const isOutsideLeft = eventXLeft < element.scrollLeft;
      if (isOutsideRight) {
        animateScrollLeft(element, element.scrollLeft + (eventXRight - listSize));
      } else if (isOutsideLeft) {
        animateScrollLeft(element, element.scrollLeft + eventXLeft);
      }
    },
    [tapWidth]
  );

  const onSelectTap = useCallback(
    (index: number) => {
      setSelectedTap(index);

      animateScrollLeftCallback(index);
    },
    [animateScrollLeftCallback]
  );
  
  const onSelect = useCallback(
    (bev: IBeverage) => {
      dispatch(setBeverage(bev, selectedTap));
      if (selectedTap < tapsInfo.length - 1) {
        onSelectTap(selectedTap + 1);
      }

      if ((countryMarketType === EMarketType.direct || countryMarketType === EMarketType.both) && useSellInPrice) {
          if (bev.id && bev.pricePerHl && !sellInPrices[bev.id]) {
              dispatch(updateSellInPrice(bev.id, bev.pricePerHl / 100));
          }
      }
    },
    [onSelectTap, dispatch, tapsInfo, selectedTap]
  );

  const taps = tapsInfo.map((tap, i) => {
    const selected = selectedTap === i;
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div key={i} style={{ cursor: "pointer" }} onClick={() => onSelectTap(i)}>
        <div
          className={cx(
            styles.tapItemWrapper,
            selected && styles.tapItemSelected
            // { pointerEvents: "none" }
          )}
        >
          <TapAvatar
            alt={tap.beverage?.id || ""}
            src={tap.beverage?.lense || ""}
            selected={selected}
            backgroundColor={tap.beverage?.mainColor}
            size="sm"
          />
          <Label className={cx(styles.mainLabel)}>
            {i === 0 && (
              <MessageTooltip
                renderHeader={() => (
                  <Label>
                    {contentGeneral.general_main_brand} <Icon name="info" size={10} />
                  </Label>
                )}
              >
                {contentGeneral.general_main_brand_tooltip}
              </MessageTooltip>
            )}
            &nbsp;
          </Label>
        </div>
      </div>
    );
  });

  const Title = isMobile ? Subtitle : PageTitle;
  const Description = isMobile ? BodyHighlight : SectionTitle;

  return (
    <Base className={cx(styles.base)} fixedHeight>
      <Title className={cx(styles.pageTitle)}>{content.brands_today}</Title>
      <Description className={cx(styles.sectionTitle)}>{content.brands_today_subtitle}</Description>

      <section className={cx(styles.tapsContainer)}>
        <div className={cx(styles.tapsCarousel)} ref={scrollDiv}>
          <div className={cx(styles.tapRow)}>{taps}</div>
        </div>
      </section>

      <section className={cx(styles.beverageList)} ref={bevList}>
        {beverages.concat(waterBeverages).map(bev => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
          <div
            key={bev.name}
            title={bev.name}
            className={cx(styles.beverageListItem)}
            onClick={() => onSelect(bev)}
          >
            <img className={cx(styles.beverageImg)} key={bev.id} alt="beer" src={bev?.lense} />
            <div
              className={cx(
                styles.overlayImg,
                bev.id === tapsInfo[selectedTap]?.beverage?.id && "selected"
              )}
            />
          </div>
        ))}
      </section>
    </Base>
  );
};

export default Brands;
