import React, { useState } from "react";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import { Body, Description, BodyHighlight } from "@ddm-design-system/typography";
import { SecondaryButton } from "@ddm-design-system/button";
import { Icon } from "@ddm-design-system/icon";
import useIsMobile from "../../hooks/useIsMobile";
import { UIMode } from "../../templates/base";
import useContent from "../../hooks/useContent";

interface IProps {
  mode?: UIMode;
}

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  reportSection: {
    padding: `${theme.spaceUnit.xxl} calc(${theme.spaceUnit.md} * 4) ${theme.spaceUnit.xl} `,
    borderBottom: `1px solid ${theme.colors.grey.grey50}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.xl} 0`
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.xl} 0`
      }
    }
  },
  legalNote: {
    maxWidth: 400
    // textAlign:"left"
  },
  overflow: {
    position: "absolute",
    left: 0,
    right: 0,
    background: `linear-gradient(transparent, ${theme.colors.white}, ${theme.colors.white})`,
    color: theme.colors.white,
    bottom: 0,
    paddingTop: theme.spaceUnit.xxl,
    height: theme.spaceUnit.xxl,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  textContainer: {
    height: 95,
    overflowY: "hidden",
    position: "relative",
    transition: "0.3s all",
    "@media": {
      "screen and (max-width: 700px)": {
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  },
  textContainerShowAll: {
    height: "auto"
  }
});

const LegalSection: React.FC<IProps> = ({ mode }) => {
  const [styles, cx] = useStyles(stylesheet);
  const isMobile = useIsMobile();
  const { report: content } = useContent();
  const [legalOpen, setLegalOpen] = useState(mode === "PRINT");

  const LegalText = isMobile ? Description : Body;

  return (
    <section className={cx(styles.reportSection)}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={cx(styles.buttonSection, {
          flexDirection: "column",
          cursor: "pointer"
        })}
        onClick={() => setLegalOpen(!legalOpen)}
      >
        <BodyHighlight className={cx(styles.buttonReport)}>{content.report_legal}</BodyHighlight>
        <div className={cx(styles.legalNote, (legalOpen || mode === "PRINT") && "open")}>
          <div className={cx(styles.textContainer, legalOpen && styles.textContainerShowAll)}>
            <div>
              <LegalText>{content.legal_note_text}</LegalText>
            </div>
            {!legalOpen && (
              <div className={cx(styles.overflow)}>
                <SecondaryButton
                  onClick={() => setLegalOpen(true)}
                  style={{
                    backgroundColor: "transparent",
                    borderColor: "transparent"
                  }}
                >
                  {content.report_read_more}&nbsp;
                  <Icon name="chevron-down" className={cx(styles.overflowIcon)} size={12} />
                </SecondaryButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LegalSection;
