import { PersistPartial } from "redux-persist/es/persistReducer";
import { IBeverage, ITapsInfo } from "../types";
import { IResetCalculator } from "../main/types";
import { VOLUME_TIMEFRAME, VOLUME_UNIT } from "../../constants";

export enum ETomorrowActions {
  SET_TOTAL_TAPS = "tomorrow/SET_TOTAL_TAPS",
  SET_BEVERAGE = "tomorrow/SET_BEVERAGE",
  UPDATE_TAP_INFO = "tomorrow/UPDATE_TAP_INFO",
  UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR = "tomorrow/UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR",
  SET_RECOMMENDED_TAPS = "tomorrow/SET_RECOMMENDED_TAPS",
  UPDATE_SELL_IN_PRICE = "tomorrow/UPDATE_SELL_IN_PRICE",
  UPDATE_VOLUME_CONFIG = "tomorrow/UPDATE_VOLUME_CONFIG"
}

export interface ITomorrowState extends PersistPartial {
  tapsInfo: ITapsInfo[];
  recommendedTaps: number | null;
  volumePerYear?: number;
  sellInPrice: { [key: string]: { price: number } };
  volumeUnit?: VOLUME_UNIT;
  volumeTimeframe?: VOLUME_TIMEFRAME;
}

export interface ITomorrowStateSave {
  tomorrowReport: any;
}

export interface ISetTotalTaps {
  type: ETomorrowActions.SET_TOTAL_TAPS;
  payload: number;
}

export interface ISetBeverage {
  type: ETomorrowActions.SET_BEVERAGE;
  payload: {
    beverage: IBeverage;
    tapNumber: number;
  };
}

export interface IUpdateTapInfoActions {
  type: ETomorrowActions.UPDATE_TAP_INFO;
  payload: {
    tapInfo: ITapsInfo;
    tapNumber: number;
  };
}

export interface IUpdateTotalVolumeSoldPerYear {
  type: ETomorrowActions.UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR;
  payload: number;
}

export interface ISetRecommendedTaps {
  type: ETomorrowActions.SET_RECOMMENDED_TAPS;
  payload: number;
}

export interface IUpdateSellInPrice {
  type: ETomorrowActions.UPDATE_SELL_IN_PRICE;
  payload: {
    beverageId: string;
    price: number;
  };
}

export interface IUpdateVolumeConfig {
  type: ETomorrowActions.UPDATE_VOLUME_CONFIG;
  payload: {
    volumeUnit: VOLUME_UNIT;
    volumeTimeframe: VOLUME_TIMEFRAME;
  };
}

export type IReducerActions =
  | ISetTotalTaps
  | ISetBeverage
  | IUpdateTapInfoActions
  | ISetRecommendedTaps
  | IUpdateTotalVolumeSoldPerYear
  | IUpdateSellInPrice
  | IUpdateVolumeConfig
  | IResetCalculator;
