// @ts-nocheck
import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import { Dropdown } from "@ddm-design-system/dropdown";
import {
  SectionTitle,
  PageTitle,
  Label,
  Subtitle,
  BodyHighlight
} from "@ddm-design-system/typography";
import { TextInput } from "@ddm-design-system/textinput";
import numeral from "numeral";
import { updateTapInformation } from "../../store/today/actions";
import { IAppState } from "../../store/reducers";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import Base from "../../templates/base";
import { currencyMap } from "../../constants";
import BeerLense from "../../components/beer-lense/beer-lense";
import { EBeverageType, IBeverage, IGlass } from "../../store/types";
import useIsMobile from "../../hooks/useIsMobile";
import useContent from "../../hooks/useContent";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  pricingPage: {
    color: theme.colors.black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.xxl,
    textAlign: "center",
    "@media": {
      "screen and (max-width: 700px)": {
        marginBottom: theme.spaceUnit.lg
      }
    }
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "50%",

    padding: `0 ${theme.spaceUnit.lg}`,
    boxSizing: "border-box",
    marginTop: theme.spaceUnit.md,
    marginBottom: theme.spaceUnit.md,
    "@media": {
      "screen and (max-width: 700px)": {
        width: "100%",
        padding: 0
      }
    }
  },
  avatarWrapper: {
    marginRight: theme.spaceUnit.md,
    textAlign: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media": {
      "screen and (max-width: 700px)": {
        marginRight: theme.spaceUnit.xs
      }
    }
  },
  inputRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spaceUnit.xl
  },
  currency: {
    color: theme.colors.grey.grey50
  },
  mainBrandLabel: {
    position: "absolute",
    bottom: "-16px",
    margin: "auto",
    width: "200%"
  },
  dropdown: {
    minWidth: "100% !important",
    marginBottom: 6
  }
});

interface IProps {}

const Pricing: React.FC<IProps> = () => {
  const [styles, cx] = useStyles(stylesheet);
  const dispatch = useDispatch();
  const { pricing: content, general: contentGeneral } = useContent();
  const isMobile = useIsMobile();
  const taps = useSelector((state: IAppState) => state.today.tapsInfo);
  const countryConfig = useSelector(getCurrentCountryConfig);

  const [valueTaps, setValueTaps] = useState(taps.map(t => t.config.cost?.toString()));

  const onChange = (tapNumber: number, valueInput: any) => {
    const value = parseFloat(valueInput);
    const match = valueInput.match("[0-9]+([.,]0*)");
    const concatenate =
      match && (valueInput.endsWith(".") || valueInput.endsWith(",") || valueInput.endsWith("0"));
    const endsWithZero = match && valueInput.endsWith("0") && !match?.[1].includes("0");

    setValueTaps([
      ...valueTaps.slice(0, tapNumber),
      value !== undefined
        ? concatenate
          ? `${value.toString()}${endsWithZero ? "0" : match[1]}`
          : value.toString()
        : undefined,
      ...valueTaps.slice(tapNumber + 1)
    ]);
    dispatch(
      updateTapInformation(
        {
          ...taps[tapNumber],
          config: {
            ...taps[tapNumber].config,
            cost: !Number.isNaN(value) ? value : undefined,
            glass: taps[tapNumber].config.glass || countryConfig.defaultGlass
          }
        },
        tapNumber
      )
    );
  };
  const onChangeGlassSize = useCallback(
    (tapNumber: number, value: IGlass) => {
      dispatch(
        updateTapInformation(
          {
            ...taps[tapNumber],
            config: {
              ...taps[tapNumber].config,
              glass: value
            }
          },
          tapNumber
        )
      );
    },
    [dispatch, taps]
  );

  const formatValues = useCallback(() => {
    setValueTaps(
      valueTaps.map(t => (t !== undefined ? `${numeral(t).format("0.00")}` : undefined))
    );
  }, [valueTaps]);

  useEffect(() => {
    formatValues();
  }, []); // eslint-disable-line

  const Title = isMobile ? Subtitle : PageTitle;
  const BodyTitle = isMobile ? BodyHighlight : SectionTitle;

  return (
    <Base>
      <div className={cx(styles.pricingPage)}>
        <Title className={cx(styles.sectionTitle)}>{content.pricing_today}</Title>
        <BodyTitle className={cx(styles.sectionTitle)}>
          {content.pricing_today_subtitle || ""}{" "}
          {countryConfig.useTax && `(${contentGeneral.general_incl_tax})`}
        </BodyTitle>

        <div className={cx(styles.inputRow)}>
          {taps.map((tap, i) => (
            <div key={i} className={cx(styles.inputWrapper)}>
              <div className={cx(styles.avatarWrapper)}>
                <BeerLense beverage={tap.beverage as IBeverage} size={96} />
                {i === 0 && (
                  <Label className={cx(styles.mainBrandLabel)}>
                    {contentGeneral.general_main_brand}
                  </Label>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <Dropdown
                  className={cx(styles.dropdown)}
                  items={countryConfig.glasses.map(f => ({ text: f.glassName, id: f }))}
                  text={tap.config.glass?.glassName || countryConfig.defaultGlass.glassName}
                  placeholder={contentGeneral.general_glass_size}
                  onItemSelected={val => onChangeGlassSize(i, val.id)}
                />
                <TextInput
                  type="number"
                  step="0.01"
                  onBlur={() => formatValues()}
                  value={valueTaps[i]}
                  disabled={tap.beverage?.type === EBeverageType.WATER}
                  onChange={e => {
                    onChange(i, e.target.value);
                  }}
                  suffixIcon={
                    <span className={cx(styles.currency)}>
                      {currencyMap[countryConfig.currency]}
                    </span>
                  }
                  label={
                    tap.beverage?.type === EBeverageType.WATER
                      ? contentGeneral.general_no_price
                      : content.pricing_price_per_beer
                  }
                />
              </div>
            </div>
          ))}
          {taps.length > 1 && <div style={{ width: "50%" }} />}
        </div>
      </div>
    </Base>
  );
};

export default Pricing;
