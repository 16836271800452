import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@ddm-design-system/icon";
import { DDMTheme, CustomDDMThemeStyleSheetFactory, useStyles } from "@ddm-design-system/base";
import { Body, PageTitle, SectionTitle, Label } from "@ddm-design-system/typography";
import { useDispatch, useSelector } from "react-redux";
import "flag-icon-css/css/flag-icon.css";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Background } from "../../assets/images/ddm-background.svg";
import { setCurrentCountry } from "../../store/main/actions";
import { getCurrentCountry } from "../../store/main/selectors";
import { AnalyticsContext } from "../../services/analytics";
import useContent from "../../hooks/useContent";
import { setCurrentLanguage } from "../../store/content/actions";
import { Routes } from "../../routes";
import { EMarketType } from "../../store/types";

const MAX_WIDTH_HEADER = 1440;

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.colors.black,
    minHeight: "100vh",
    position: "relative",
    boxSizing: "border-box",
    overflow: "hidden"
  },
  innerContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    padding: `${theme.spaceUnit.xxl} 0`,
    width: "100%",
    height: "100vh",
    overflow: "auto",
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.lg} 0`
      }
    }
  },
  background: {
    position: "absolute",
    height: 2374,
    opacity: 0.5,
    zIndex: 0,
    pointerEvents: "none",
    top: 0,
    width: "100%",
    "@media": {
      "screen and (max-width: 700px)": {
        height: "120vh",
        opacity: 1
      }
    }
  },
  header: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignSelf: "stretch",
    minHeight: "20vh",
    maxWidth: MAX_WIDTH_HEADER,
    width: "100%",
    padding: `0 calc(${theme.spaceUnit.xxl} * 2)`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `0 ${theme.spaceUnit.lg}`
      }
    }
  },
  flag: {
    display: "block",
    width: theme.spaceUnit.xl,
    height: theme.spaceUnit.xl,
    borderRadius: theme.spaceUnit.xl,
    cursor: "pointer"
  },
  titleContainer: {
    justifyContent: "center",
    height: "50vh",
    marginTop: theme.spaceUnit.xl
  },
  title: {
    color: theme.colors.white,
    textTransform: "uppercase",
    textAlign: "center",
    "@media": {
      "screen and (max-width: 700px)": {
        fontSize: "31px !important ",
        lineHeight: "36px !important"
      }
    }
  },
  card: {
    background: theme.colors.black,
    border: `1px solid ${theme.colors.grey.greenish}`,
    boxSizing: "border-box",
    borderRadius: 10,
    padding: theme.spaceUnit.lg,
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spaceUnit.xl,
    color: theme.colors.white,
    minWidth: 350,
    margin: theme.spaceUnit.lg,
    height:"100%",
    "@media": {
      "screen and (max-width: 700px)": {
        minWidth: "initial"
      }
    }
  },
  countryInfo: {
    display: "flex",
    marginTop: theme.spaceUnit.lg,
    padding: theme.spaceUnit.sm,
    border: `1px solid ${theme.colors.grey.greenish}`,
    borderRadius: 10,
    alignItems: "center",
    cursor: "pointer",
    "@selectors": {
      "&:hover": {
        backgroundColor: theme.colors.grey.grey100
      },
      "&.selected": {
        color: theme.colors.black,
        backgroundColor: theme.colors.white
      }
    }
  },
  countryDesc: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spaceUnit.md
  },
  marketType: {
    color: theme.colors.grey.grey50,
    "@selectors": {
      "&.selected": {
        color: theme.colors.grey.grey100
      }
    }
  },
  button: {
    ...theme.typography.buttonText,
    lineHeight: 1,
    color: theme.colors.white,
    border: `1px solid ${theme.colors.white}`,
    borderRadius: 50,
    padding: `${theme.spaceUnit.md} ${theme.spaceUnit.xxl}`,
    marginTop: theme.spaceUnit.xl,
    "@selectors": {
      "&.disabled": {
        opacity: 0.5,
        color: theme.colors.grey.grey50,
        borderColor: theme.colors.grey.grey50,
        pointerEvents: "none"
      }
    }
  }
});

const CountrySelector: React.FC = () => {
  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const country = useSelector(getCurrentCountry);
  const {
    countries,
    languageInfo: languages,
    countrySelector: content,
    general: contentGeneral
  } = useContent();

  const changeCountry = useCallback(
    (countryId: string) => {
      const countryObj = countries.find(c => c.id === countryId);

      if (countryObj) {
        dispatch(setCurrentCountry(countryObj.id));
        analytics.logEvent("CHANGE_COUNTRY", countryObj.name);
        const newLanguage = languages.find(l => l.country === countryObj?.flag);
        if (newLanguage) {
          dispatch(setCurrentLanguage(newLanguage));
        }
      }
    },
    [languages, countries, analytics, dispatch]
  );

  const [styles, cx] = useStyles(stylesheet);
  const countriesSorted = [...countries].sort((a,b) => a.id < b.id ? 1:-1);
  return (
    <div className={cx(styles.container)}>
      <Background className={cx(styles.background)} />
      <div className={cx(styles.innerContainer)}>
        <div className={cx(styles.header)}>
          <Logo fill="white" />
        </div>
        <div className={cx(styles.titleContainer)}>
          <PageTitle className={cx(styles.title)}>{contentGeneral.general_draughtmaster}</PageTitle>
          <PageTitle className={cx(styles.title)}>{contentGeneral.general_calculator}</PageTitle>
          <div className={cx(styles.card)}>
            <SectionTitle>{content.country_selector_choose_location}</SectionTitle>
            <div style={{overflow:"auto"}}>
              {countriesSorted.map(c => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  onClick={() => changeCountry(c.id)}
                  key={c.id}
                  className={cx(styles.countryInfo, country?.id === c.id && "selected")}
                >
                  {c.id.includes("demo") ? (
                    <Icon name="dm-logo" fill={country?.id === c.id ? "black" : "white"} size={30} />
                ) : (
                  <div>
                    <span
                      className={`flag-icon-background flag-icon-${c?.flag.toLowerCase()} flag-icon-squared ${cx(
                        styles.flag
                      )}`}
                    />
                  </div>
                )}
                  <div className={cx(styles.countryDesc)}>
                    <Body>{c.name}</Body>
                    <Label className={cx(styles.marketType, country?.id === c.id && "selected")}>
                      {c.configuration.marketType === EMarketType.direct &&
                      content.country_selector_direct_market}
                      {c.configuration.marketType === EMarketType.indirect &&
                      content.country_selector_indirect_market}
                      {c.configuration.marketType === EMarketType.both &&
                      content.country_selector_both_market}
                    </Label>
                  </div>
                </div>
            ))}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to={Routes.HOME} className={cx(styles.button, !country && "disabled")}>
                {content.country_selector_continue}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountrySelector;
