import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Stepper } from "@ddm-design-system/stepper";
import { PageTitle, DescriptionMedium } from "@ddm-design-system/typography";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import {getActivePage, getCurrentCountryCo2Costs, getCurrentCountryMarketType} from "../../store/main/selectors";
import { getCalculatorPages } from "../../routes";
import { UIMode } from "../../templates/base";
import useContent from "../../hooks/useContent";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  header: {
    display: "flex",
    flexDirection: "column"
  },
  headerContent: {
    marginBottom: `calc(${theme.spaceUnit.xxl} *1.5)`,
    "@media": {
      "screen and (max-width: 700px)": {
        marginBottom: `calc(${theme.spaceUnit.xxl} + ${theme.spaceUnit.md})`
      }
    }
  },
  noMarginHeader: {
    marginBottom: 44
  },
  stepperWrapper: {
    boxSizing: "border-box",
    height: 44,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    border: `1px solid ${theme.colors.grey.grey50}`,
    display: "flex",
    alignItems: "center",
    position: "absolute",
    width: "calc(100vw - 70px)",
    padding: `0 ${theme.spaceUnit.md}`,
    borderTop: "none"
  },
  stepper: {
    flex: 1
  },
  todayText: {
    paddingRight: theme.spaceUnit.md
  },
  tomorrowText: {
    paddingLeft: theme.spaceUnit.md
  },
  titleWrapper: {
    textAlign: "center"
  },
  titleWrapperPrint: {
    marginTop: theme.spaceUnit.xxl
  },
  title: {
    marginBottom: theme.spaceUnit.xxl
  }
});

export interface IHeaderProps {
  title?: string;
  mode: UIMode;
  noMarginHeader?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ title, mode, noMarginHeader = false }) => {
  const [styles, cx] = useStyles(stylesheet);
  const { general: contentGeneral } = useContent();
  const activePage = useSelector(getActivePage);
  const marketType = useSelector(getCurrentCountryMarketType);
  const co2Costs = useSelector(getCurrentCountryCo2Costs);
  const calculatorPages = useMemo(() => getCalculatorPages(marketType, co2Costs), [marketType]);

  const pages = useMemo(
    () => calculatorPages.today.concat(calculatorPages.tomorrow),
    [calculatorPages]
  );
  const isToday = useMemo(
    () => activePage < calculatorPages.today.length,
    [activePage, calculatorPages]
  );

  return (
    <header className={cx(styles.header)}>
      {mode !== "PRINT" && (
        <div
          className={cx(styles.headerContent, noMarginHeader && styles.noMarginHeader, "no-print")}
        >
          {mode === "DEFAULT" && (
            <div
              className={cx(
                styles.stepperWrapper,
                isToday
                  ? {
                      left: 0,
                      width: `calc(calc(calc(50vw + 45%) * ${pages.length}) / ${calculatorPages.today.length})`
                    }
                  : {
                      right: 0,
                      width: `calc(calc(calc(50vw + 40%) * ${pages.length}) / ${calculatorPages.tomorrow.length})`
                    }
              )}
            >
              {isToday && (
                <DescriptionMedium className={cx(styles.todayText)}>
                  {contentGeneral.general_today}
                </DescriptionMedium>
              )}
              <Stepper
                activeStep={activePage}
                steps={pages.length}
                className={cx(styles.stepper)}
              />
              {!isToday && (
                <DescriptionMedium className={cx(styles.tomorrowText)}>
                  {contentGeneral.general_tomorrow}
                </DescriptionMedium>
              )}
            </div>
          )}
        </div>
      )}
      <div className={cx(styles.titleWrapper, mode === "PRINT" && styles.titleWrapperPrint)}>
        {title && <PageTitle className={cx(styles.title)}>{title}</PageTitle>}
      </div>
    </header>
  );
};

export default Header;
