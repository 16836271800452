import React, { useCallback } from "react";
import { Description, PageTitle } from "@ddm-design-system/typography";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import useIsMobile from "../../hooks/useIsMobile";

export interface IProgressBarItem {
  whiteValue: number;
  whiteLabel?: string;
  blackValue: number;
  blackLabel?: string;
}

export interface IProgressBarProps {
  inverted?: boolean;
  orientation?: "vertical" | "horizontal";
  item: IProgressBarItem;
}

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  progressBarWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flex: 0,
    "@selectors": {
      "&.horizontal .progress-bar-white": {
        flexDirection: "column",
        marginRight: theme.spaceUnit.xxs
      },
      "&.horizontal .progress-bar-white .progress-bar": {
        border: `1px solid ${theme.colors.grey.grey100}`,
        backgroundColor: theme.colors.white
      },
      "&.horizontal .progress-bar-black": {
        flexDirection: "column",
        marginLeft: theme.spaceUnit.xxs,
        alignItems: "flex-end"
      },
      "&.horizontal .progress-bar-black .progress-bar": {
        border: `1px solid ${theme.colors.black}`,
        backgroundColor: theme.colors.black,
        width: "100%"
      },
      "&.horizontal .progress-bar-black .progress-bar-label": {
        textAlign: "right"
      },
      "&.horizontal .progress-bar-label": {
        marginTop: theme.spaceUnit.md,
        marginBottom: theme.spaceUnit.xs,
        "@media": {
          "screen and (max-width: 700px)": {
            marginTop: theme.spaceUnit.xs,
            marginBottom: theme.spaceUnit.xxs
          }
        }
      },
      ".progress-bar": {
        margin: `${theme.spaceUnit.xxs} 0`
      },
      "&.vertical": {
        flexDirection: "column",
        height: "100%"
      },
      "&.vertical .progress-bar-label": {
        textAlign: "left"
      },
      "&.vertical.inverted .progress-bar-white": {
        flexDirection: "row-reverse"
      },
      "&.vertical.inverted .progress-bar-black": {
        flexDirection: "row-reverse"
      },
      "&.vertical.inverted .progress-bar-label": {
        textAlign: "right"
      },
      "&.vertical .progress-bar-white": {
        flexDirection: "row",
        marginTop: theme.spaceUnit.xxs,
        alignItems: "flex-end"
      },
      "&.vertical .progress-bar-white .progress-bar": {
        width: theme.spaceUnit.md,
        backgroundColor: theme.colors.white,
        border: `1px solid ${theme.colors.grey.grey100}`,
        height: "100%"
      },
      "&.vertical .progress-bar-black": {
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
        alignItems: "flex-start",
        marginBottom: theme.spaceUnit.xxs
      },
      "&.vertical .progress-bar-black .progress-bar": {
        width: theme.spaceUnit.md,
        backgroundColor: theme.colors.black,
        border: `1px solid ${theme.colors.black}`,
        height: "100%"
      },
      "&.vertical .progress-bar": {
        margin: `0 ${theme.spaceUnit.md}`,
        "@media": {
          "screen and (max-width: 700px)": {
            margin: `0 ${theme.spaceUnit.xs}`
          }
        }
      }
    }
  },
  progressBar: {
    height: theme.spaceUnit.md,
    borderRadius: 5
  },
  progressBarWhite: {
    display: "flex",
    boxSizing: "border-box",
    color: theme.colors.grey.grey100
  },
  progressBarBlack: {
    display: "flex",
    boxSizing: "border-box",
    color: theme.colors.black
  },
  progressBarLabel: {
    minWidth: 100,
    "@media": {
      "screen and (max-width: 700px)": {
        minWidth: 40
      }
    }
  }
});

const ProgressBar: React.FC<IProgressBarProps> = ({
  orientation = "horizontal",
  item,
  inverted
}) => {
  const [styles, cx] = useStyles(stylesheet);
  const isMobile = useIsMobile();
  const { whiteLabel, whiteValue, blackLabel, blackValue } = item;

  const FontText = isMobile ? Description : PageTitle;

  const reverseVertical = useCallback(
    array => (orientation === "vertical" ? array.reverse() : array),
    [orientation]
  );

  return (
    <div className={cx(styles.progressBarWrapper, orientation, inverted && "inverted")}>
      {reverseVertical([
        <div
          key="white"
          style={{
            ...(orientation === "vertical"
              ? { height: `${whiteValue}%`, minHeight: 5 }
              : { flexBasis: `${whiteValue}%`, minWidth: 5 })
          }}
          className={cx(styles.progressBarWhite, "progress-bar-white")}
        >
          <FontText className={cx(styles.progressBarLabel, "progress-bar-label")}>
            {`${whiteValue}%`}
          </FontText>
          <div className={cx(styles.progressBar, "progress-bar")} />
          {whiteLabel && (
            <Description className={cx(styles.progressBarLabel, "progress-bar-label")}>
              {whiteLabel}
            </Description>
          )}
        </div>,
        <div
          key="black"
          style={{
            ...(orientation === "vertical"
              ? { height: `${blackValue}%`, minHeight: 5 }
              : { flexBasis: `${blackValue}%`, minWidth: 5 })
          }}
          className={cx(styles.progressBarBlack, "progress-bar-black")}
        >
          <FontText className={cx(styles.progressBarLabel, "progress-bar-label")}>
            {`${blackValue}%`}
          </FontText>
          <div className={cx(styles.progressBar, "progress-bar")} />
          {blackLabel && (
            <Description className={cx(styles.progressBarLabel, "progress-bar-label")}>
              {blackLabel}
            </Description>
          )}
        </div>
      ])}
    </div>
  );
};

export default ProgressBar;
