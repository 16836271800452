import {Reducer} from "redux";
import produce from "immer";
import {PersistState} from "redux-persist/es/types";
import {ETodayActions, IReducerActions, ITodayState} from "./types";
import {EMainActions} from "../main/types";
import {EBeverageCategory, EBeverageType} from "../types";

export const initialState: ITodayState = {
    tapsInfo: [],
    co2: {
        cost: undefined
    },
    volumePerYear: undefined,
    sellInPrice: {},
    _persist: {} as PersistState
};

const reducer: Reducer<ITodayState, IReducerActions> = (
    state = initialState,
    action: IReducerActions
) =>
    produce(state, draft => {
        switch (action.type) {
            case ETodayActions.SET_TOTAL_TAPS:
                draft.tapsInfo = Array(action.payload).fill({
                    config: {
                        cost: undefined,
                        soldPerYear: undefined,
                        newCost: false
                    },
                    beverage: null
                });
                break;
            case ETodayActions.SET_BEVERAGE:
                const beveragePayload = action.payload.beverage;
                const newTaps = state.tapsInfo.map((tapInfo, i) => ({
                    ...tapInfo,
                    beverage: i === action.payload.tapNumber ? {
                        ...beveragePayload,
                        id: beveragePayload.id + (beveragePayload.category === EBeverageCategory.OTHER ? i.toString() : "")
                    } : tapInfo.beverage,
                    config: {
                        ...tapInfo.config,
                        newCost: false
                    }
                }));
                draft.tapsInfo = newTaps;

                const brands = newTaps.map(t => t.beverage);

                const newSellIn: typeof state.sellInPrice = {};
                brands.forEach(b => {
                    if (b?.id && !newSellIn[b.id] && b?.type !== EBeverageType.WATER) {
                        newSellIn[b.id] = state.sellInPrice[b.id];
                    }
                });
                draft.sellInPrice = newSellIn;
                break;

            case ETodayActions.UPDATE_TAP_INFO:
                draft.tapsInfo = draft.tapsInfo.map((tapInfo, i) => ({
                    ...tapInfo,
                    config: {
                        ...tapInfo.config,
                        ...(i === action.payload.tapNumber ? action.payload.tapInfo.config || {} : {})
                    }
                }));
                break;

            case ETodayActions.UPDATE_CO2_INFO:
                draft.co2 = action.payload;
                break;
            case ETodayActions.UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR:
                draft.volumePerYear = action.payload;
                break;
            case ETodayActions.UPDATE_SELL_IN_PRICE:
                if (action.payload.beverageId) {
                    const sellIn = {
                        ...state.sellInPrice,
                        [action.payload.beverageId]: {
                            price: action.payload.price
                        }
                    };
                    draft.sellInPrice = sellIn;
                }
                break;
            case ETodayActions.UPDATE_VOLUME_CONFIG:
                draft.volumeUnit = action.payload.volumeUnit;
                draft.volumeTimeframe = action.payload.volumeTimeframe;

                break;
            case EMainActions.RESET_CALCULATOR:
                Object.assign(draft, initialState);
                break;
            default:
                break;
        }
    });

export default reducer;
