import React, { useState, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import { getCurrentCountryDMBeverages } from "../../store/main/selectors";
import { IBeverage } from "../../store/types";
import BeveragesGridItem from "./BeveragesGridItem";
import { MAX_WIDTH, MAX_WIDTH_TABLET } from "../../templates/base";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  grid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "90%",
    margin: "0 auto",
    maxWidth: MAX_WIDTH,
    minWidth: MAX_WIDTH,
    "@media": {
      "screen and (max-width: 900px)": {
        maxWidth: MAX_WIDTH_TABLET,
        minWidth: "initial"
      },
      "screen and (max-width: 700px)": {
        width: `calc(100% - 2 * ${theme.spaceUnit.xs})`,
        margin: `0 ${theme.spaceUnit.xs}`,
        minWidth: "initial"
      }
    }
  }
});

const BeveragesGrid: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  const [styles, cx] = useStyles(stylesheet);
  const beverages = useSelector(getCurrentCountryDMBeverages);
  const [selectedBeverageId, selectBeverage] = useState<IBeverage["id"]>();
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>();

  const updateSize = () => {
    setWidth(ref.current?.getBoundingClientRect().width);
  };

  useLayoutEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [ref]);

  return (
    <div {...props} ref={ref} className={cx(styles.grid)}>
      {beverages.map((b, index) => (
        <BeveragesGridItem
          beverage={b}
          key={b.id}
          index={index}
          inactive={selectedBeverageId !== undefined && b.id !== selectedBeverageId}
          onClick={() =>
            b.id !== selectedBeverageId ? selectBeverage(b.id) : selectBeverage(undefined)
          }
          open={b.id === selectedBeverageId}
          containerWidth={width}
          numItems={beverages.length}
        />
      ))}
    </div>
  );
};

export default BeveragesGrid;
