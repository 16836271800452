import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { PageTitle, Subtitle } from "@ddm-design-system/typography";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import { IAppState } from "../../store/reducers";
import { ITodayState } from "../../store/today/types";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import { calculateReport } from "../../helpers";
import Base from "../../templates/base";
import useIsMobile from "../../hooks/useIsMobile";
import CostSection from "../../components/report/CostSection";
import DiversitySection from "../../components/report/DiversitySection";
import RevenueSectionToday from "../../components/report/RevenueSectionToday";
import useContent from "../../hooks/useContent";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  reportContainer: {
    display: "flex",
    flexDirection: "column",
    color: theme.colors.black,
    width: "100%"
  },
  reportTitle: {
    textAlign: "center"
  }
});

interface IProps {}

const Report: React.FC<IProps> = () => {
  const todayInfo = useSelector<IAppState, ITodayState>(state => state.today);
  const countryConfig = useSelector(getCurrentCountryConfig);
  const [styles, cx] = useStyles(stylesheet);
  const isMobile = useIsMobile();
  const { report: content } = useContent();

  const todayReport = useMemo(
    () => calculateReport("TODAY", todayInfo, countryConfig),
    [todayInfo, countryConfig]
  );

  const Title = isMobile ? Subtitle : PageTitle;

  return (
    <Base>
      <div className={cx(styles.reportContainer)}>
        <Title className={cx(styles.reportTitle)}>{content.report_today}</Title>

        <RevenueSectionToday todayReport={todayReport} />
        <DiversitySection todayReport={todayReport} />

        <CostSection todayReport={todayReport} />
      </div>
    </Base>
  );
};

export default Report;
