import * as Sentry from "@sentry/browser";

const ENV_DSN =
  process.env.REACT_APP_SENTRY_DSN ||
  "https://8c90789ab4644f1abd01c65b63b29150@sentry.io/5182768";
const ENABLE_IN_DEVELOPMENT = false;

export default {
  setup: (dsn: string = ENV_DSN) => {
    if (process.env.NODE_ENV === "production" || ENABLE_IN_DEVELOPMENT) {
      Sentry.init({ dsn });
      Sentry.setTags({
        environment:
          "staging"
      });
    }
  }
};
