import React from "react";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import {
  Body,
  Description,
  Subtitle,
  SectionTitle,
  BodyHighlight
} from "@ddm-design-system/typography";
import {useSelector} from "react-redux";
import useIsMobile from "../../hooks/useIsMobile";
import ProgressBar from "../progress-bar/progress-bar";
import useContent from "../../hooks/useContent";
import {getCurrentCountryConfig} from "../../store/main/selectors";

interface IProps {
  todayReport: any;
  tomorrowReport?: any;
}

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  reportSection: {
    padding: `${theme.spaceUnit.xxl} calc(${theme.spaceUnit.md} * 4) ${theme.spaceUnit.xl} `,
    borderBottom: `1px solid ${theme.colors.grey.grey50}`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.xl} 0`
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.xl} 0`
      }
    }
  },
  sectionHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.lg
  },
  sectionContainer: {
    boxSizing: "border-box",
    marginTop: `calc(${theme.spaceUnit.md} * 3)`,
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  diversityRow: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 300,
    width: "100%",
    paddingBottom: theme.spaceUnit.md,

    "@media": {
      "screen and (max-width: 700px)": {
        padding: 0
      }
    }
  },
  diversityHeaders: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.spaceUnit.lg} 0`,
    textTransform: "uppercase"
  },
  diversityLabels: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.black,
    height: "100%",
    textAlign: "center"
  },
  diversityDefault: {
    color: theme.colors.grey.grey100
  }
});

const DiversitySection: React.FC<IProps> = ({ todayReport, tomorrowReport }) => {
  const [styles, cx] = useStyles(stylesheet);
  const isMobile = useIsMobile();
  const { report: content, general: contentGeneral } = useContent();
  const countryConfig = useSelector(getCurrentCountryConfig);

  const SectionTitleFont = isMobile ? SectionTitle : Subtitle;
  const BodyFont = isMobile ? Description : Body;
  const HeadersFont = isMobile ? Description : BodyHighlight;

  return (
    <section className={cx(styles.reportSection)}>
      <div className={cx(styles.sectionHeader)}>
        <SectionTitleFont className={cx(styles.sectionTitle)}>
          {content.report_diversity}
        </SectionTitleFont>
        <BodyFont>{content.report_diversity_subtitle}</BodyFont>
      </div>
      <div className={cx(styles.sectionContainer)}>
        {tomorrowReport ? (
          <>
            <div className={cx(styles.diversityHeaders)}>
              <HeadersFont>{contentGeneral.general_today}</HeadersFont>
              <HeadersFont>{contentGeneral.general_tomorrow}</HeadersFont>
            </div>
            <div className={cx(styles.diversityRow)}>
              <ProgressBar
                orientation="vertical"
                item={{
                  whiteLabel: "",
                  whiteValue: Math.round((1 - todayReport.diversity) * 100),
                  blackLabel: "",
                  blackValue: Math.round(todayReport.diversity * 100)
                }}
              />
              <div className={cx(styles.diversityLabels)}>
                <Body>{countryConfig.usePremium ? content.report_premium_brands : content.report_speciality_brands}</Body>
                <Body className={cx(styles.diversityDefault)}>
                  {content.report_mainstream_brands}
                </Body>
              </div>
              <ProgressBar
                orientation="vertical"
                inverted
                item={{
                  whiteLabel: "",
                  whiteValue: Math.round((1 - tomorrowReport.diversity) * 100),
                  blackLabel: "",
                  blackValue: Math.round(tomorrowReport.diversity * 100)
                }}
              />
            </div>
          </>
        ) : (
          <ProgressBar
            item={{
              whiteLabel: content.report_mainstream_brands,
              whiteValue: Math.round((1 - todayReport.diversity) * 100),
              blackLabel: countryConfig.usePremium ? content.report_premium_brands : content.report_speciality_brands,
              blackValue: Math.round(todayReport.diversity * 100)
            }}
          />
        )}
      </div>
    </section>
  );
};

export default DiversitySection;
