import { DEFAULT_LANG } from "../../constants";
import {
  translate,
  translateBoolean,
  translateImage,
  translateNumber,
  translateString
} from "./translate";
import {
  IBeverage,
  EBeverageCategory,
  IBeverageInfo,
  EBeverageType
} from "../types";

export function mapBeverageInfo(b: any, lang = DEFAULT_LANG): IBeverageInfo {
  const parsedBeverageInfo = {
    name: translateString(b.fields.name, lang),
    description: translateString(b.fields.description, lang),
    category: translateString(b.fields.category, lang) as EBeverageCategory,
    origin: translateString(b.fields.origin, lang),
    abv: translateNumber(b.fields.abv, lang),
    ibu: translateNumber(b.fields.ibu, lang),
    speciality: translateBoolean(b.fields.speciality, lang),
    lense: `${translateImage(b.fields.lense, lang).path}?q=90&w=140`,
    mainColor: translateString(b.fields.mainColor,lang)
  };
  return parsedBeverageInfo;
}

export default function mapBeverage(b: any, lang = DEFAULT_LANG): IBeverage {
  const parsedBeverage = {
    id: translateString(b.fields.beverageId, lang),
    type: translateString(b.fields.type, lang) as EBeverageType,
    ...mapBeverageInfo(translate(b.fields.beverageInfo,lang), lang),
    priceIndex: translateNumber(b.fields.priceIndex, lang),
    pricePerHl: translateNumber(b.fields.pricePerHl, lang),
    pricePerKeg: translateNumber(b.fields.pricePerKeg, lang),
    litersPerKeg: translateNumber(b.fields.litersPerKeg, lang)
  };
  return parsedBeverage;
}
