import React, { useCallback, useContext, useEffect, useState } from "react";
import CookieCard from "./CookieCard";
import { AnalyticsContext } from "../../services/analytics";
import Hotjar from "../../services/hotjar";

interface IProps {}

const Cookies: React.FC<IProps> = () => {
  const [visible, setVisible] = useState(!localStorage.getItem("cookieConsent"));
  const AnalyticsService = useContext(AnalyticsContext);

  const saveConsent = (consent: boolean) =>
    localStorage.setItem("cookieConsent", consent ? "true" : "false");

  const setupTracking = useCallback(() => {
    Hotjar.setup();
    AnalyticsService.setup();
  }, [AnalyticsService]);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "true") {
      setupTracking();
    }
  }, [setupTracking]);

  return visible ? (
    <CookieCard
      onAccept={() => {
        saveConsent(true);
        setupTracking();
        setVisible(false);
      }}
      onCancel={() => {
        saveConsent(false);
        setVisible(false);
      }}
    />
  ) : (
    <></>
  );
};

export default Cookies;
