import { init } from "@ddm-design-system/base";
import Sentry from "../services/sentry";
import {CURRENT_VERSION} from "../constants";
// import initPullToRefresh from "./pullToRefresh";

const initialSetup = () => {
  /**
   * Init theme from ddm
   */
  init();

  /**
   * Init pull to refresh feature on mobile
   */
  // initPullToRefresh();

  /**
   * Init Sentry
   */
  Sentry.setup();
  
  /**
   * Version Local Storage
   */
  const version = localStorage.getItem("version");
  
  if(!version || version !== CURRENT_VERSION) {
    localStorage.clear();
    localStorage.setItem("version",CURRENT_VERSION);
    window.location.reload();
  }
};

export { default as history } from "./history";

export default initialSetup;
