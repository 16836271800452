import { createSelector } from "reselect";
import { get } from "lodash";
import { IAppState } from "../reducers";
import { DEFAULT_LANG } from "../../constants";
import { IContentfulContent, ILanguageInfo } from "./types";

const getLanguages = (state: IAppState): IContentfulContent =>
  get(state, "content.entries", "");

export const getCurrentLanguage = (state: IAppState): string =>
  get(state, "content.currentLanguage", "");

export const getCurrentContent = createSelector<
  IAppState,
  IContentfulContent,
  string,
  { [language: string]: any }
>(
  getLanguages,
  getCurrentLanguage,
  (langs, currentLang) => langs[currentLang || DEFAULT_LANG] ?? {}
);

export const getD3TimeFormat = createSelector<
  IAppState,
  IContentfulContent,
  string,
  any
>(getLanguages, getCurrentLanguage, (langs, currentLang) => {
  const content = langs[currentLang || DEFAULT_LANG];
  return content.languageInfo.filter(
    (l: ILanguageInfo) => l.code === currentLang
  )[0].d3TimeFormat;
});
