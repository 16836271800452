import { Reducer } from "redux";
import produce from "immer";
import { EMainActions, IMainState, IReducerActions } from "./types";

export const initalState: IMainState = {
  currentCountry: null,
  activePage: -1
};

const reducer: Reducer<IMainState, IReducerActions> = (
  state = initalState,
  action: IReducerActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case EMainActions.SET_CURRENT_COUNTRY:
        draft.currentCountry = action.payload;
        break;
      case EMainActions.SET_ACTIVE_PAGE:
        draft.activePage = action.payload;
        break;
      case EMainActions.RESET_CALCULATOR:
        draft.activePage = -1;
        break;
      default:
        break;
    }
  });

export default reducer;
