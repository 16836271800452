import { fork, put, select, take } from "redux-saga/effects";
import { REHYDRATE } from "redux-persist/lib/constants";
import {
  getContentfulError,
  getContentfulSuccess,
  syncSuccesful,
  getContentful as getContentfulAction
} from "../store/content/actions";
import { IContentfulService } from "../services/contentful";
import { IContentState } from "../store/content/types";
import { IAppContext } from "../services";
import { IAppState } from "../store/reducers";
import {translations} from "../store/content";

function* getContentful(contentfulService: IContentfulService) {
  yield take((action: any) => action.type === REHYDRATE && action.key === "language");
  yield put(getContentfulAction());
  try {
    const content: IContentState = yield select((state: IAppState) => state.content);
    const { nextSyncToken, entries } = content;
    
    // make sure all new translations are loaded
    if (nextSyncToken && translations.every(t => Object.keys(entries).includes(t.code))) {
      const changes = yield contentfulService.checkChanges(nextSyncToken);
      if (!changes.entries.length) {
        yield put(syncSuccesful(changes.nextSyncToken));
        return;
      }
    }
    const results = yield contentfulService.getEntries();
    yield put(
      getContentfulSuccess({
        entries: results.entries,
        nextSyncToken: results.nextSyncToken
      })
    );
  } catch (error) {
    yield put(getContentfulError());
  }
}

export function* contentWatcher(context: IAppContext) {
  yield fork(getContentful, context.contentfulService);
}

export default contentWatcher;
