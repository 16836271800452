import { DEFAULT_LANG } from "../../constants";
import { translateString } from "./translate";
import { IEntries } from "./types";

export default function mapLanguageInfo(
  entries: IEntries,
  lang = DEFAULT_LANG
) {
  const parsedLanguages = entries.languageInfo.ordered.map(languageInfo => ({
    code: translateString(languageInfo.fields.languageCode, lang),
    name: translateString(languageInfo.fields.languageName, DEFAULT_LANG),
    country: translateString(languageInfo.fields.languageCountry, lang)
  }));

  return {
    languageInfo: parsedLanguages
  };
}
