import { PersistPartial } from "redux-persist/es/persistReducer";
import { IBeverage, ICo2Info, ITapsInfo } from "../types";
import { IResetCalculator } from "../main/types";
import { VOLUME_TIMEFRAME, VOLUME_UNIT } from "../../constants";

export enum ETodayActions {
  SET_TOTAL_TAPS = "today/SET_TOTAL_TAPS",
  SET_BEVERAGE = "today/SET_BEVERAGE",
  UPDATE_TAP_INFO = "today/UPDATE_TAP_INFO",
  UPDATE_CO2_INFO = "today/UPDATE_CO2_INFO",
  UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR = "today/UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR",
  UPDATE_SELL_IN_PRICE = "today/UPDATE_SELL_IN_PRICE",
  UPDATE_VOLUME_CONFIG = "today/UPDATE_VOLUME_CONFIG"
}

export interface ITodayState extends PersistPartial {
  tapsInfo: ITapsInfo[];
  co2: ICo2Info;
  volumePerYear?: number;
  sellInPrice: { [key: string]: { price: number } };
  volumeUnit?: VOLUME_UNIT;
  volumeTimeframe?: VOLUME_TIMEFRAME;
}

export interface ITodayStateSave {
  todayReport: any;
}

export interface ISetTotalTaps {
  type: ETodayActions.SET_TOTAL_TAPS;
  payload: number;
}

export interface ISetBeverage {
  type: ETodayActions.SET_BEVERAGE;
  payload: {
    beverage: IBeverage;
    tapNumber: number;
  };
}

export interface IUpdateTapInfoActions {
  type: ETodayActions.UPDATE_TAP_INFO;
  payload: {
    tapInfo: ITapsInfo;
    tapNumber: number;
  };
}

export interface IUpdateTotalVolumeSoldPerYear {
  type: ETodayActions.UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR;
  payload: number;
}

export interface IUpdateCo2InfoActions {
  type: ETodayActions.UPDATE_CO2_INFO;
  payload: ICo2Info;
}

export interface IUpdateSellInPrice {
  type: ETodayActions.UPDATE_SELL_IN_PRICE;
  payload: {
    beverageId: string;
    price: number;
  };
}

export interface IUpdateVolumeConfig {
  type: ETodayActions.UPDATE_VOLUME_CONFIG;
  payload: {
    volumeUnit: VOLUME_UNIT;
    volumeTimeframe: VOLUME_TIMEFRAME;
  };
}

export type IReducerActions =
  | ISetTotalTaps
  | ISetBeverage
  | IUpdateTapInfoActions
  | IUpdateCo2InfoActions
  | IUpdateTotalVolumeSoldPerYear
  | IResetCalculator
  | IUpdateSellInPrice
  | IUpdateVolumeConfig;
