import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Body,
  BodyHighlight,
  PageTitle,
  SectionTitle,
  Subtitle
} from "@ddm-design-system/typography";
import { TextInput } from "@ddm-design-system/textinput";
import { Dropdown } from "@ddm-design-system/dropdown";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import {
  updateTapInformation,
  updateTotalVolumeSoldPerYear,
  updateVolumeConfig
} from "../../store/today/actions";
import Base from "../../templates/base";
import { getTapsInfo, getTotalVolumeSold, getVolumeConfig } from "../../store/today/selectors";
import { ITapsInfo } from "../../store/types";
import { calculateOtherTapsVolume } from "../../helpers";
import useIsMobile from "../../hooks/useIsMobile";
import useContent from "../../hooks/useContent";
import { currencyMap, volumeTimeframeArray, volumeUnitArray } from "../../constants";

interface IProps {}

const styleSheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  sectionTitle: {
    marginBottom: theme.spaceUnit.xxl,
    "@media": {
      "screen and (max-width: 700px)": {
        marginBottom: theme.spaceUnit.lg
      }
    }
  },
  inputLitersPerYear: {
    minWidth: "300px !important",
    marginTop: theme.spaceUnit.lg,
    marginBottom: theme.spaceUnit.md
  },
  suffix: {
    color: theme.colors.grey.grey50
  },
  volumeConfig: {
    display: "flex",
    alignItems: "center"
  },
  volumeConfigPer: {
    margin: `0 ${theme.spaceUnit.md}`
  },
  dropdown: {
    minWidth: "124px !important"
  }
});

const Volume: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const tapsInfo = useSelector(getTapsInfo);
  const isMobile = useIsMobile();
  const volumeConfig = useSelector(getVolumeConfig);

  const { volume: content, general: contentGeneral } = useContent();
  const todayTotalVolume = useSelector(getTotalVolumeSold);
  const [styles, cx] = useStyles(styleSheet);

  const updateOtherTapsVolume = useCallback(
    (volume: number) => {
      const otherTapsVolume = calculateOtherTapsVolume(volume, tapsInfo);
      otherTapsVolume?.forEach((volumeTap: number, index: number) =>
        dispatch(
          updateTapInformation(
            {
              config: {
                soldPerYear: Math.round(volumeTap)
              }
            } as ITapsInfo,
            index
          )
        )
      );
    },
    [dispatch, tapsInfo]
  );

  const onChangeTotalVolumeChange = useCallback(
    volume => {
      dispatch(updateTotalVolumeSoldPerYear(!Number.isNaN(volume) ? Math.round(volume) : 0));

      updateOtherTapsVolume(volume);
    },
    [dispatch, updateOtherTapsVolume]
  );

  const onChangeVolumeConfig = useCallback(
    (vU, vT) => {
      dispatch(updateVolumeConfig(vU, vT));
    },
    [dispatch]
  );

  const Title = isMobile ? Subtitle : PageTitle;
  const BodyTitle = isMobile ? BodyHighlight : SectionTitle;

  return (
    <Base noMarginHeader>
      <Title className={cx(styles.sectionTitle)}>{content.volume_today}</Title>
      <BodyTitle>{content.volume_today_subtitle}</BodyTitle>
      <TextInput
        type="number"
        min={0}
        value={todayTotalVolume || ""}
        className={cx(styles.inputLitersPerYear)}
        label={content.volume_sold_per_year}
        onChange={ev => onChangeTotalVolumeChange(parseInt(ev.currentTarget.value))}
        suffixIcon={
          <span className={cx(styles.suffix)}>
            {currencyMap[volumeConfig.volumeUnit]}/
            {contentGeneral[`unit_${volumeConfig.volumeTimeframe}`]}
          </span>
        }
      />

      <div className={cx(styles.volumeConfig)}>
        <Dropdown
          className={cx(styles.dropdown)}
          items={volumeUnitArray.map(f => ({ text: contentGeneral[`unit_${f}`], id: f }))}
          text={contentGeneral[`unit_${volumeConfig.volumeUnit}`]}
          onItemSelected={val => onChangeVolumeConfig(val.id, volumeConfig.volumeTimeframe)}
        />
        <Body className={cx(styles.volumeConfigPer)}>{content.volume_per}</Body>
        <Dropdown
          className={cx(styles.dropdown)}
          items={volumeTimeframeArray.map(f => ({ text: contentGeneral[`unit_${f}`], id: f }))}
          text={contentGeneral[`unit_${volumeConfig.volumeTimeframe}`]}
          onItemSelected={val => onChangeVolumeConfig(volumeConfig.volumeUnit, val.id)}
        />
      </div>
    </Base>
  );
};

export default Volume;
