import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useStyles,
  useTheme,
  DDMTheme,
  CustomDDMThemeStyleSheetFactory
} from "@ddm-design-system/base";
import { InputSpinner } from "@ddm-design-system/input-spinner";
import {
  SectionTitle,
  PageTitle,
  Description,
  BodyHighlight,
  Subtitle
} from "@ddm-design-system/typography";
import { TapAvatar } from "@ddm-design-system/avatar";
import ReactMarkdown from "react-markdown";
import { IAppState } from "../../store/reducers";
import Base, { MAX_WIDTH, MAX_WIDTH_TABLET } from "../../templates/base";
import { setRecommendedTaps, setTotalTaps } from "../../store/tomorrow/actions";
import { calculateRecommendedTaps } from "../../helpers";
import { getTotalVolumeSold, getTapsInfo as getTodayTapsInfo } from "../../store/today/selectors";
import useIsMobile from "../../hooks/useIsMobile";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import useContent from "../../hooks/useContent";
import {ITodayState} from "../../store/today/types";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  tapsPage: {
    color: theme.colors.black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.xxl,
    textAlign: "center"
  },
  tapsContainer: {
    height: 200,
    minHeight: 200,
    position: "relative",
    "@media": {
      "screen and (max-width: 700px)": {
        height: 96,
        minHeight: 96
      }
    }
  },
  tapsCarousel: {
    display: "flex",
    overflowX: "auto",
    position: "absolute",
    left: "calc(-50vw + 50%)",
    width: "100vw",
    scrollbarWidth: "none",
    marginTop: theme.spaceUnit.lg,
    "-ms-overflow-style": "none",
    "@selectors": {
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0
      }
    }
  },
  tapRow: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    padding: `0 calc(calc(100vw - ${MAX_WIDTH}px) / 2)`,
    "@media": {
      "screen and (max-width: 900px)": {
        padding: `0 calc(calc(100vw - ${MAX_WIDTH_TABLET}px) / 2)`
      },
      "screen and (max-width: 700px)": {
        padding: `0 ${theme.spaceUnit.lg}`
      }
    }
  },
  avatarWrapper: {
    margin: theme.spaceUnit.lg,
    "@media": {
      "screen and (max-width: 700px)": {
        margin: theme.spaceUnit.xs
      }
    }
  },
  spinner: {
    "@selectors": {
      "&.success .diana-input-spinner-value": {
        color: `${theme.colors.success.success100} !important`
      },
      "&.alert .diana-input-spinner-value": {
        color: `${theme.colors.alert.alert100} !important`
      },
      "&.warning .diana-input-spinner-value": {
        color: `${theme.colors.warning.warning100} !important`
      }
    }
  },
  recommendedTaps: {
    whiteSpace: "nowrap",
    color: `${theme.colors.success.success100} !important`
  },
  statusMessage: {
    marginTop: theme.spaceUnit.xs
  },
  statusWarning: {
    color: theme.colors.warning.warning100
  },
  statusAlert: {
    color: theme.colors.alert.alert100
  },
  statusSuccess: {
    color: theme.colors.success.success100
  }
});

interface IProps {}

const Taps: React.FC<IProps> = () => {
  const [styles, cx] = useStyles(stylesheet);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { taps: content } = useContent();
  const todayInfo = useSelector<IAppState, ITodayState>(state => state.today);
  const todayTaps = useSelector(getTodayTapsInfo).length;
  const countryConfig = useSelector(getCurrentCountryConfig);
  const taps = useSelector((state: IAppState) => state.tomorrow.tapsInfo);
  const recommendedTaps = useSelector((state: IAppState) => state.tomorrow.recommendedTaps);

  useEffect(() => {
    const recomTaps = calculateRecommendedTaps(
      todayInfo,
      countryConfig
    );
    dispatch(setRecommendedTaps(recomTaps));
    dispatch(setTotalTaps(recomTaps || todayTaps));
  }, [dispatch, todayInfo, countryConfig, todayTaps]);

  const onChange = useCallback(
    (v: number) => {
      if (!Number.isNaN(v) && v >= 0) {
        dispatch(setTotalTaps(v));
      }
    },
    [dispatch]
  );

  const recommendedDiff = useMemo(() => {
    return (recommendedTaps && Math.abs(taps.length - recommendedTaps)) || 0;
  }, [recommendedTaps, taps]);

  const TitleFont = isMobile ? Subtitle : PageTitle;
  const SubtitleFont = isMobile ? BodyHighlight : SectionTitle;

  return (
    <Base fixedHeight>
      <div className={cx(styles.tapsPage)}>
        <TitleFont className={cx(styles.sectionTitle)}>{content.taps_tomorrow}</TitleFont>
        {recommendedTaps ? (
          <SubtitleFont className={cx(styles.sectionTitle)}>
            <ReactMarkdown
              components={{
                strong: props => (
                  <strong className={cx(styles.recommendedTaps)}>{props.children}</strong>
                )
              }}
            >
              {(content.taps_tomorrow_recommend || "").replace("%NUMBER%", `${recommendedTaps}`)}
            </ReactMarkdown>
          </SubtitleFont>
        ) : (
          <SubtitleFont className={cx(styles.sectionTitle)}>
            {content.taps_tomorrow_subtitle}
          </SubtitleFont>
        )}
        <InputSpinner
          initialValue={recommendedTaps || 0}
          value={taps.length}
          onChange={onChange}
          max={12}
          className={cx(
            styles.spinner,
            recommendedTaps
              ? recommendedDiff > 1
                ? "alert"
                : recommendedDiff === 1
                ? "warning"
                : "success"
              : ""
          )}
        />

        {recommendedTaps && (
          <Description className={cx(styles.statusMessage)}>
            {recommendedDiff === 0 && <span>&nbsp;</span>}
            {recommendedDiff === 1 && content.taps_tomorrow_recommend_ok}
            {recommendedDiff > 1 && content.taps_tomorrow_recommend_not_ok}
          </Description>
        )}

        <section className={cx(styles.tapsContainer)}>
          <div className={cx(styles.tapsCarousel)}>
            <div className={cx(styles.tapRow)}>
              {taps.map((_, i) => (
                <div className={cx(styles.avatarWrapper)} key={i}>
                  <TapAvatar
                    size="sm"
                    borderColor={
                      // @ts-ignore
                      (recommendedTaps &&
                        (recommendedDiff > 1
                          ? theme.colors.alert.alert100
                          : recommendedDiff === 1
                          ? theme.colors.warning.warning100
                          : theme.colors.success.success100)) ||
                      ""
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Base>
  );
};

export default Taps;
