import { ITapsInfo, IBeverage } from "../types";
import { ETomorrowActions } from "./types";
import {VOLUME_TIMEFRAME, VOLUME_UNIT} from "../../constants";

export const setTotalTaps = (payload: number) => ({
  type: ETomorrowActions.SET_TOTAL_TAPS,
  payload
});

export const setBeverage = (beverage: IBeverage | null, tapNumber: number, diffPrice = 1) => ({
  type: ETomorrowActions.SET_BEVERAGE,
  payload: {
    beverage,
    tapNumber,
    diffPrice
  }
});

export const updateTapInformation = (
  tapInfo: ITapsInfo,
  tapNumber: number
) => ({
  type: ETomorrowActions.UPDATE_TAP_INFO,
  payload: {
    tapInfo,
    tapNumber
  }
});

export const updateTotalVolumeSoldPerYear = (payload: number) => ({
  type: ETomorrowActions.UPDATE_TOTAL_VOLUME_SOLD_PER_YEAR,
  payload
});

export const setRecommendedTaps = (payload: number | null) => ({
  type: ETomorrowActions.SET_RECOMMENDED_TAPS,
  payload
});

export const updateSellInPrice = (beverageId: string, price: number) => ({
  type: ETomorrowActions.UPDATE_SELL_IN_PRICE,
  payload: {
    beverageId,
    price
  }
});

export const updateVolumeConfig = (volumeUnit: VOLUME_UNIT, volumeTimeframe: VOLUME_TIMEFRAME) => ({
  type: ETomorrowActions.UPDATE_VOLUME_CONFIG,
  payload: {
    volumeUnit,
    volumeTimeframe
  }
});
