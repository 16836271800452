import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import contentReducer from "./content/reducer";
import todayReducer from "./today/reducer";
import tomorrowReducer from "./tomorrow/reducer";
import mainReducer from "./main/reducer";

const persistRootConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2
};

const persistMainConfig = {
  key: "main",
  storage
};

const persistContentConfig = {
  key: "language",
  storage
};

const persistTodayConfig = {
  key: "tapsInfo",
  storage
};

const persistTomorrowConfig = {
  key: "tapsInfo",
  storage
};

const combinedReducer = combineReducers({
  main: persistReducer(persistMainConfig, mainReducer),
  today: persistReducer(persistTodayConfig, todayReducer),
  tomorrow: persistReducer(persistTomorrowConfig, tomorrowReducer),
  content: persistReducer(persistContentConfig, contentReducer)
});
export type IAppState = ReturnType<typeof combinedReducer>;

const rootReducer = persistReducer<IAppState>(
  persistRootConfig,
  combinedReducer
);

export default rootReducer;
