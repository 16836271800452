import { useSelector } from "react-redux";
import { getCurrentContent } from "../store/content/selectors";
import { ILanguageInfo, IContent } from "../store/content/types";
import { ICountry } from "../store/types";
import { IAppState } from "../store/reducers";

interface IContentfulMap {
  languageInfo: ILanguageInfo[];
  countries: ICountry[];
  general: IContent;
  countrySelector: IContent;
  home: IContent;
  taps: IContent;
  brands: IContent;
  volume: IContent;
  purchasing: IContent;
  pricing: IContent;
  co2Cost: IContent;
  report: IContent;
}

const useContent: () => IContentfulMap = () => {
  const content = useSelector((state: IAppState) => getCurrentContent(state));
  return {
    ...(content as IContentfulMap)
  };
};

export default useContent;
