import React, { useState } from "react";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import {
  Body,
  Description,
  DescriptionMedium,
  SectionTitle,
  PageTitle
} from "@ddm-design-system/typography";
import { Icon } from "@ddm-design-system/icon";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../helpers";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import useIsMobile from "../../hooks/useIsMobile";

interface IProps {
  todayValue: number;
  tomorrowValue?: number;
  last?: boolean;
  status?: boolean;
  profit?: boolean;
  description?: string;
}

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  wasteLineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
    // whiteSpace: "nowrap",
  },
  wasteLine: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spaceUnit.lg} calc(${theme.spaceUnit.md} * 4)`,
    borderBottom: `1px solid ${theme.colors.grey.grey25}`,
    textAlign: "center",
    cursor: "pointer",
    boxSizing: "border-box",
    backgroundColor: "transparent",
    transition: "0.3s all",
    "@selectors": {
      "&.open": {
        backgroundColor: theme.colors.grey.grey25,
        borderBottom: `1px solid ${theme.colors.grey.grey50}`
      },
      "&.open .description": {
        opacity: 1,
        height: 60,
        paddingTop: theme.spaceUnit.md,
        maxWidth: 600
      },
      "&.open .title": {
        color: theme.colors.black
      }
    },
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.lg} calc(50vw - 50%)`,
        width: "100vw",
        marginLeft: "calc(-50vw + 50%)"
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.lg} calc(50vw - 50%)`,
        width: "100vw",
        marginLeft: "calc(-50vw + 50%)"
      }
    }
  },
  wasteLineTomorrow: {
    alignItems: "center",
    borderBottom: `1px solid ${theme.colors.grey.grey25}`,
    textAlign: "center"
  },
  wasteValue: {
    minWidth: "30%",
    textAlign: "left",
    "@selectors": {
      "&.right": {
        textAlign: "right",
        justifyContent: "flex-end !important"
      }
    }
  },
  wasteLineToday: {
    borderBottom: "1px solid transparent",
    alignItems: "flex-start",
    "@selectors": {
      "> .description": {
        textAlign: "left"
      }
    }
  },
  wasteDescription: {
    paddingTop: 0,
    opacity: 0,
    transition: "0.3s all",
    height: 0
  },
  wasteLineDefault: {
    color: theme.colors.grey.grey100,
    display: "flex",
    alignItems: "center"
  },
  wasteLineLast: {
    borderBottomColor: theme.colors.black
  },

  wasteLineLastToday: {
    borderBottomColor: theme.colors.grey.grey25
  },
  statusAlert: {
    color: theme.colors.alert.alert100
  },
  statusSuccess: {
    color: theme.colors.success.success100
  },
  iconHelp: {
    fill: theme.colors.grey.grey100,
    minWidth: theme.spaceUnit.md,
    "@selectors": {
      "&.open": {
        fill: theme.colors.black
      }
    },
    "screen and (max-width: 700px)": {
      minWidth: theme.spaceUnit.xs
    }
  }
});

const WasteLine: React.FC<IProps> = ({
  children,
  todayValue,
  tomorrowValue,
  last,
  status,
  profit,
  description
}) => {
  const [styles, cx] = useStyles(stylesheet);
  const countryConfig = useSelector(getCurrentCountryConfig);
  const isMobile = useIsMobile();
  const [isOpen, setOpen] = useState(false);

  const ValueFont = profit
    ? isMobile
      ? DescriptionMedium
      : PageTitle
    : isMobile
    ? DescriptionMedium
    : Body;
  const ValueFontToday = profit ? (isMobile ? SectionTitle : PageTitle) : Body;
  const WasteTextFont = isMobile ? Description : SectionTitle;
  const TotalTextFont = isMobile ? DescriptionMedium : SectionTitle;
  const DescriptionFont = isMobile ? Description : Body;

  const todayStatus =
    status && todayValue !== 0 && (todayValue < 0 ? styles.statusAlert : styles.statusSuccess);
  const tomorrowStatus =
    status &&
    tomorrowValue &&
    tomorrowValue !== 0 &&
    (tomorrowValue < 0 ? styles.statusAlert : styles.statusSuccess);

  return tomorrowValue !== undefined ? (
    <div
      className={cx(
        styles.wasteLine,
        styles.wasteLineTomorrow,
        last ? styles.wasteLineLast : {},
        profit ? { borderBottom: "none" } : {},
        isOpen && "open"
      )}
      onClick={() => description && setOpen(!isOpen)}
    >
      <div className={cx(styles.wasteLineWrapper)}>
        <ValueFont className={cx(styles.wasteValue, todayStatus)}>
          {formatCurrency(todayValue, countryConfig.currency)}
        </ValueFont>
        {!status && !profit ? (
          <WasteTextFont className={cx(styles.wasteLineDefault, "title")}>
            {children}
            {description && (
              <>
                &nbsp;
                <Icon name="info" className={cx(styles.iconHelp)} size={isMobile ? 8 : 16} />
              </>
            )}
          </WasteTextFont>
        ) : (
          <TotalTextFont>{children}</TotalTextFont>
        )}
        <ValueFont className={cx(styles.wasteValue, "right", tomorrowStatus || {})}>
          {formatCurrency(tomorrowValue, countryConfig.currency)}
        </ValueFont>
      </div>
      {description && (
        <div className={cx(styles.wasteDescription, "description")}>
          <DescriptionFont>{description}</DescriptionFont>
        </div>
      )}
    </div>
  ) : (
    <div
      className={cx(
        styles.wasteLine,
        styles.wasteLineToday,
        last ? styles.wasteLineLastToday : {},
        isOpen && "open"
      )}
      onClick={() => description && setOpen(!isOpen)}
    >
      <div className={cx(styles.wasteLineWrapper)}>
        {!status && !profit ? (
          <WasteTextFont className={cx(styles.wasteLineDefault, "title")}>
            {children}
            {description && (
              <>
                &nbsp;
                <Icon
                  name="info"
                  className={cx(styles.iconHelp, isOpen && "open")}
                  size={isMobile ? 8 : 16}
                />
              </>
            )}
          </WasteTextFont>
        ) : (
          <TotalTextFont>{children}</TotalTextFont>
        )}
        <ValueFontToday className={cx(todayStatus)}>
          {formatCurrency(todayValue, countryConfig.currency)}
        </ValueFontToday>
      </div>
      {description && (
        <div className={cx(styles.wasteDescription, "description")}>
          <DescriptionFont>{description}</DescriptionFont>
        </div>
      )}
    </div>
  );
};

export default WasteLine;
