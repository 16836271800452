import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PageTitle,
  SectionTitle,
  BodyHighlight,
  Description,
  Subtitle
} from "@ddm-design-system/typography";
import { TextInput } from "@ddm-design-system/textinput";
import { DDMTheme, CustomDDMThemeStyleSheetFactory, useStyles } from "@ddm-design-system/base";
import {
  updateTapInformation,
  updateTotalVolumeSoldPerYear,
  updateVolumeConfig
} from "../../store/tomorrow/actions";
import Base from "../../templates/base";
import { getTapsInfo, getTotalVolumeSold } from "../../store/tomorrow/selectors";
import { ITapsInfo } from "../../store/types";
import { calculateVolumeUplift, calculateOtherTapsVolume } from "../../helpers";
import {
  getTapsInfo as getTodayTapsInfo,
  getTotalVolumeSold as getTodayTotalVolumeSold,
  getVolumeConfig
} from "../../store/today/selectors";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import useIsMobile from "../../hooks/useIsMobile";
import useContent from "../../hooks/useContent";
import { currencyMap } from "../../constants";

interface IProps {}

const styleSheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  sectionTitle: {
    marginBottom: theme.spaceUnit.xxl,
    "@media": {
      "screen and (max-width: 700px)": {
        marginBottom: theme.spaceUnit.lg
      }
    }
  },
  description: {
    marginTop: theme.spaceUnit.xs,
    color: theme.colors.grey.grey100
  },
  inputLitersPerYear: {
    minWidth: "300px !important",
    marginTop: theme.spaceUnit.lg,
    marginBottom: theme.spaceUnit.xl
  },
  mainBrandBody: {
    marginTop: theme.spaceUnit.xxl,
    marginBottom: theme.spaceUnit.lg,
    "@media": {
      "screen and (max-width: 700px)": {
        marginTop: theme.spaceUnit.lg
      }
    }
  },
  descriptionMain: {
    marginBottom: theme.spaceUnit.lg
  },
  inputMainBrand: {
    minWidth: 300,
    marginTop: theme.spaceUnit.lg
  },
  suffix: {
    color: theme.colors.grey.grey50
  },
  lenseWrapper: {
    marginTop: theme.spaceUnit.md
  }
});

const Volume: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { volume: content, general: contentGeneral } = useContent();
  const todayTapsInfo = useSelector(getTodayTapsInfo);
  const volumeConfig = useSelector(getVolumeConfig);
  const tapsInfo = useSelector(getTapsInfo);
  const todayTotalVolume = useSelector(getTodayTotalVolumeSold);
  const countryConfig = useSelector(getCurrentCountryConfig);
  const tomorrowTotalVolume = useSelector(getTotalVolumeSold);
  const [styles, cx] = useStyles(styleSheet);
  const [firstEnter, setFirstEnter] = useState(true);

  const updateOtherTapsVolume = useCallback(
    (volume: number) => {
      const otherTapsVolume = calculateOtherTapsVolume(volume, tapsInfo);
      otherTapsVolume?.forEach((volumeTap: any, index: number) =>
        dispatch(
          updateTapInformation(
            {
              config: {
                soldPerYear: Math.round(volumeTap)
              }
            } as ITapsInfo,
            index
          )
        )
      );
    },
    [dispatch, tapsInfo]
  );

  const onChangeTotalVolumeChange = useCallback(
    volume => {
      dispatch(updateTotalVolumeSoldPerYear(!Number.isNaN(volume) ? Math.round(volume) : 0));

      updateOtherTapsVolume(volume);
    },
    [dispatch, updateOtherTapsVolume]
  );

  useEffect(() => {
    if (firstEnter) {
      let newVolume: number;
      // set total as 10% more
      if (!tomorrowTotalVolume) {
        newVolume = Math.round(
          calculateVolumeUplift(todayTotalVolume || 0, countryConfig.DMUplift)
        );
        onChangeTotalVolumeChange(newVolume);
      }

      setTimeout(() => {
        updateOtherTapsVolume(newVolume || tomorrowTotalVolume || 0);
      });
    }

    dispatch(updateVolumeConfig(volumeConfig.volumeUnit, volumeConfig.volumeTimeframe));
    setFirstEnter(false);
  }, [
    dispatch,
    countryConfig.DMUplift,
    todayTotalVolume,
    tomorrowTotalVolume,
    firstEnter,
    tapsInfo,
    todayTapsInfo,
    onChangeTotalVolumeChange,
    updateOtherTapsVolume,
    volumeConfig
  ]);

  const Title = isMobile ? Subtitle : PageTitle;
  const BodyTitle = isMobile ? BodyHighlight : SectionTitle;

  return (
    <Base noMarginHeader>
      <Title className={cx(styles.sectionTitle)}>{content.volume_tomorrow}</Title>
      <BodyTitle>{content.volume_tomorrow_subtitle}</BodyTitle>
      <Description className={cx(styles.description)}>
        {(content.volume_tomorrow_uplift || "").replace("%NUMBER%", `${countryConfig.DMUplift}%`)}
      </Description>
      <TextInput
        type="number"
        min={0}
        value={tomorrowTotalVolume || ""}
        className={cx(styles.inputLitersPerYear)}
        label={content.volume_sold_per_year}
        onChange={ev => onChangeTotalVolumeChange(parseInt(ev.currentTarget.value))}
        suffixIcon={
          <span className={cx(styles.suffix)}>
            {currencyMap[volumeConfig.volumeUnit]}/
            {contentGeneral[`unit_${volumeConfig.volumeTimeframe}`]}
          </span>
        }
      />
    </Base>
  );
};

export default Volume;
