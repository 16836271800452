import { get } from "lodash";
import { IAppState } from "../reducers";
import {
  EBeverageType,
  IBeverage,
  ICountryConfiguration,
  ICountry, EMarketType, EBeverageCategory
} from "../types";
import { getCurrentContent } from "../content/selectors";

export const getCurrentCountry = (state: IAppState): ICountry | undefined => {
  const countryId = get(state, "main.currentCountry", "");
  const content = getCurrentContent(state);
  if (!content) {
    return undefined;
  }
  const { countries } = content;
  return (countries || []).find((c: ICountry) => c.id === countryId);
};

export const getCurrentCountryDMBeverages = (state: IAppState): IBeverage[] => {
  const country = getCurrentCountry(state);
  if (country) {
    return (country.beverages || []).filter(
      (b: IBeverage) => b.type === EBeverageType.DM
    );
  }
  return [];
};

export const getCurrentCountrySteelBeverages = (
  state: IAppState
): IBeverage[] => {
  const country = getCurrentCountry(state);
  if (country) {
    return (country.beverages || [])
      .filter((b: IBeverage) => b.type === EBeverageType.STEEL)
        .sort((a, b) => (b.name < a.name ? 1 : -1))
        .sort((a, b) => (a.category === EBeverageCategory.OTHER ? 1 : -1));
  }
  return [];
};

export const getCurrentCountryWater = (
    state: IAppState
): IBeverage[] => {
  const country = getCurrentCountry(state);
  if (country) {
    return (country.beverages || [])
        .filter((b: IBeverage) => b.type === EBeverageType.WATER);
  }
  return [];
};

export const getCurrentCountryConfig = (
  state: IAppState
): ICountryConfiguration => {
  const { countries = [] } = getCurrentContent(state);
  const country = getCurrentCountry(state);
  if (country) {
    return country.configuration;
  }
  return countries[0]?.configuration;
};

export const getCurrentCountryConfigSafe = (
  state: IAppState
): ICountryConfiguration | undefined => {
  const { countries = [] } = getCurrentContent(state);
  const country = getCurrentCountry(state);
  if (country) {
    return country.configuration;
  }
  if (countries.length) {
    return countries[0].configuration;
  }
  return undefined;
};

export const getCurrentCountryCo2Costs = (
  state: IAppState
): ICountryConfiguration["co2Costs"] => {
  const { countries } = getCurrentContent(state);
  const country = getCurrentCountry(state);
  if (country) {
    return country.configuration.co2Costs;
  }
  return countries[0].configuration.co2Costs;
};

export const getCurrentCountryMarketType = (
    state: IAppState
): EMarketType => {
  const { countries } = getCurrentContent(state);
  const country = getCurrentCountry(state);
  if (country) {
    return country.configuration.marketType;
  }
  return countries[0].configuration.marketType;
};


export const getCurrentCountryUseSellInPrice = (
  state: IAppState
): boolean => {
  const { countries } = getCurrentContent(state);
  const country = getCurrentCountry(state);
  if (country) {
    return country.configuration.useSellInPrice;
  }
  return countries[0].configuration.useSellInPrice;
};

export const getActivePage = (state: IAppState): number => {
  return get(state, "main.activePage", 0);
};
