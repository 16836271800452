import { DEFAULT_LANG } from "../../constants";

export function translate(translatable: any, lang: string = DEFAULT_LANG): any {
  if (!translatable) {
    return null;
  }

  if (translatable[lang]) {
    return translatable[lang];
  }
  if (translatable[DEFAULT_LANG] !== undefined) {
    return translatable[DEFAULT_LANG];
  }
  return null;
}

export function translateString(
  translatable: { [language: string]: string },
  lang: string = DEFAULT_LANG
): string {
  const translated = translate(translatable, lang);
  return typeof translated === "string" ? translated : "";
}

export function translateNumber(translatable: any, lang: string = DEFAULT_LANG): number {
  const translated = translate(translatable, lang);
  return typeof translated === "number" ? translated : -1;
}

export function translateBoolean(translatable: any, lang: string = DEFAULT_LANG): boolean {
  const translated = translate(translatable, lang);
  return typeof translated === "boolean" ? translated : false;
}

interface ITranslatedImageFile {
  url: string;
  details: { image: { width: number; height: number } };
}
interface ITranslatedImage {
  fields: {
    file: any;
    title: any;
  };
}

function isTranslatedImage(image: any): image is ITranslatedImage {
  return !!(image && image.fields);
}

function isTranslatedImageFile(file: any): file is ITranslatedImageFile {
  return !!(file && file.url && file.details && file.details.image);
}

export function translateImage(
  translatable: any,
  lang: string = DEFAULT_LANG,
  alreadyTranslated = false
) {
  const image = alreadyTranslated ? translatable : translate(translatable);

  if (isTranslatedImage(image)) {
    const file = translate(image.fields.file, lang);
    if (isTranslatedImageFile(file)) {
      return {
        title: translateString(image.fields.title, lang),
        path: `https:${file.url}`,
        width: file.details.image.width,
        height: file.details.image.height
      };
    }
  }
  return {
    title: "",
    path: "",
    width: 0,
    height: 0
  };
}
