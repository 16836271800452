import numeral from "numeral";
import * as d3 from "d3";
import {EBeverageType, IBeverage, ICountryConfiguration, IGlass, ITapsInfo} from "./store/types";
import {ITodayState} from "./store/today/types";
import {ITomorrowState} from "./store/tomorrow/types";
import {VOLUME_TIMEFRAME, VOLUME_UNIT, volumeMultiplier} from "./constants";

const MINIMUM_KEGS_REQUIRED = 60;
export const NUMBER_OF_HOURS_PER_CLEANING = 2;

export function convertHltoL(hl: number) {
  return hl * 100;
}

export function formatCurrency(
  value: number,
  currency: string,
  usePlus = true,
  useDecimals = false
) {
  return `${usePlus && value > 0 ? "+" : ""}${numeral(value).format(
     `${currency === "GBP" ? "$ " : ""}0,0${useDecimals ? ".00" : ""}${currency !== "GBP" ? "$":""}`
  )}`;
}

export const calculateOtherTapsVolume = (totalVolume: number, tapsInfo: ITapsInfo[]) => {
  if (totalVolume && totalVolume > 0) {
    const diff = totalVolume;
    const taps = tapsInfo.filter(t => t.beverage?.type !== EBeverageType.WATER);
    const otherTapsVolumeEach = Math.round(diff / taps.length);
    const otherTapsVolumes: number[] = [];
    tapsInfo.forEach((tap, index) => {
      let remaining = 0;
      if (index === 0) {
        remaining = diff - otherTapsVolumeEach * taps.length;
      }
      otherTapsVolumes.push(
        tap.beverage?.type === EBeverageType.WATER ? 0 : otherTapsVolumeEach + remaining
      );
    });
    return otherTapsVolumes;
  }
  return undefined;
};

export function calculateRecommendedTaps(info: ITodayState, countryConfig: ICountryConfiguration) {
  const volumePerYear = volumeConverter(info.volumePerYear || 0, info.volumeUnit || countryConfig.unit, info.volumeTimeframe || countryConfig.defaultTimeframe);
  const possibleRecommendations = countryConfig.tapRecommendations?.filter(t => t.volumeRequired <= volumePerYear);
  
  if (possibleRecommendations?.length) {
    return possibleRecommendations[possibleRecommendations.length - 1].numberOfTaps;
  }
  
  return null;
}

export function calculateVolumeUplift(volume: number, volumeUplift: number) {
  return volume * (1 + volumeUplift / 100);
}

export function calculateBeveragePriceIndex(mainBrand: ITapsInfo, tap: ITapsInfo) {
  const mainPrice = mainBrand?.config?.cost || 0;
  const mainIndex = mainBrand?.beverage?.priceIndex || 1;
  const currentIndex = tap?.beverage?.priceIndex || 1;
  
  return {
    value: (currentIndex * mainPrice) / mainIndex,
    priceIndex: currentIndex / mainIndex
  };
}

export function volumeConverter(
  volume: number | undefined = 0,
  unit: VOLUME_UNIT | undefined = VOLUME_UNIT.HL,
  timeframe: VOLUME_TIMEFRAME | undefined = VOLUME_TIMEFRAME.YEAR
) {
  return (volume * volumeMultiplier[timeframe]) / volumeMultiplier[unit];
}

export function calculateReport(
  reportType: "TODAY" | "TOMORROW",
  info: ITodayState | ITomorrowState,
  countryConfig: ICountryConfiguration
) {
  let revenue = 0;
  const volumePerYear = volumeConverter(info.volumePerYear, info.volumeUnit || countryConfig.unit, info.volumeTimeframe || countryConfig.defaultTimeframe);
  const wastage = {
    wasteChangingKeg: 0,
    wasteRemainingBeer: 0,
    wasteCleaningSteel: 0,
    wasteCleaningDm: 0,
    wasteBeerGoneBad: 0,
    purchasingCost: 0,
    co2Cost: ((info as ITodayState).co2?.cost || 0) * volumePerYear,
    taxCost: 0,
    rentalCost: 0
  };
  let cost = 0;
  let profit = 0;
  let diversity = 0;
  const useSimplifiedVersion = countryConfig.wastage.simplifiedWasteSteel !== -1;
  const {tax} = countryConfig;
  
  const taps = info.tapsInfo
    .filter(tap => tap.beverage)
    .map(tap => {
      // variables to revenue
      const sellInPrice = info.sellInPrice[tap.beverage?.id as string];
      const {litersPerKeg} = tap.beverage as IBeverage || 20;
      const pricePerKeg =
        sellInPrice !== undefined
          ? sellInPrice.price * litersPerKeg
          : (tap.beverage as IBeverage).pricePerKeg;
      const pricePerLiter = pricePerKeg / litersPerKeg;
      const soldPerYear = volumeConverter(
        tap.config.soldPerYear,
        info.volumeUnit || countryConfig.unit,
        info.volumeTimeframe || countryConfig.defaultTimeframe
      );
      const litersPerYear = convertHltoL(soldPerYear);
      const numberOfKegs = litersPerYear / litersPerKeg;
      const pricePerServing = tap.config.cost || 0;
      const {glassSize} = countryConfig.defaultGlass;
      const minimumLitersRequired = litersPerKeg * MINIMUM_KEGS_REQUIRED;
      const isQualityRisk = litersPerYear < minimumLitersRequired;
      
      // calculate revenue
      wastage.purchasingCost += pricePerLiter * litersPerYear || 0;
      const sPricePerLY = (pricePerServing / glassSize) * litersPerYear;
      
      revenue += sPricePerLY;
      
      // waste variables
      if (reportType === "TODAY") {
        if (!useSimplifiedVersion) {
          const beersWastedFromChanging =
            numberOfKegs * countryConfig.wastage.wasteFromBeerChanging;

          const litersWastedFromChanging = beersWastedFromChanging * glassSize;
          wastage.wasteChangingKeg += pricePerLiter * litersWastedFromChanging;
  
          const litersRemainingInsideKeg =
            numberOfKegs * litersPerKeg * (countryConfig.wastage.beerRemainingInsideKeg / 100);
          wastage.wasteRemainingBeer += pricePerLiter * litersRemainingInsideKeg;
          
          const wasteBeersFromCleaningSteel =
            numberOfKegs * countryConfig.wastage.wasteFromCleaningSteel;
          const litersWastedFromCleaningSteel = wasteBeersFromCleaningSteel * glassSize;
          wastage.wasteCleaningSteel += pricePerLiter * litersWastedFromCleaningSteel;
          
          if (isQualityRisk) {
            const litersOnRisk = minimumLitersRequired - litersPerYear;
            
            const kegsOnRisk = litersOnRisk / litersPerKeg;
            wastage.wasteBeerGoneBad += kegsOnRisk * pricePerKeg || 0;
          }
        } else {
          const liters =
            litersPerYear * (countryConfig.wastage.simplifiedWasteSteel / 100);
          wastage.wasteCleaningSteel += pricePerLiter * liters || 0;
        }
      } else if (reportType === "TOMORROW") {
        if (!useSimplifiedVersion) {
          const litersWastedFromCleaningDm = countryConfig.wastage.wasteFromCleaningDm * glassSize;
          wastage.wasteCleaningDm += pricePerLiter * litersWastedFromCleaningDm;
        } else {
          const liters =
            litersPerYear * (countryConfig.wastage.simplifiedWasteDm / 100);
          wastage.wasteCleaningDm += pricePerLiter * liters || 0;
        }
      }
      
      if (countryConfig.useTax) {
        wastage.taxCost += sPricePerLY * (tax / (1 + tax));
      }
      
      if (countryConfig.useTax) {
        wastage.taxCost += sPricePerLY * (tax / (1 + tax));
      }
      
      if (tap.beverage?.speciality) {
        diversity += litersPerYear;
      }
      
      return {
        beverage: {
          mainColor: tap.beverage?.mainColor,
          lense: tap.beverage?.lense
        },
        minimumLitersRequired,
        isQualityRisk: tap.beverage?.type === EBeverageType.WATER ? false : isQualityRisk,
        pricePerServing,
        litersPerServing: glassSize,
        hlPerYear: soldPerYear.toFixed(2)
      };
    }, 0);
  
  if (reportType === "TODAY") {
    wastage.rentalCost = countryConfig.wastage.steelRentalCost ? getRentalCosts(countryConfig.wastage.steelRentalCost, taps.length):0;
    // changing labour defined
    const changingLabourCost = countryConfig.wastage.wasteFromChangingLabour * volumeMultiplier[VOLUME_TIMEFRAME.WEEK];
    wastage.wasteChangingKeg += changingLabourCost;
    
    cost =
      wastage.rentalCost +
      wastage.wasteCleaningSteel +
      wastage.wasteRemainingBeer +
      wastage.wasteChangingKeg +
      wastage.wasteBeerGoneBad +
      wastage.purchasingCost +
      (wastage.co2Cost || 0) +
      wastage.taxCost;
  } else if (reportType === "TOMORROW") {
    wastage.rentalCost = countryConfig.wastage.dmRentalCost ? getRentalCosts(countryConfig.wastage.dmRentalCost, taps.length):0;
    
    if (!useSimplifiedVersion) {
      wastage.wasteCleaningDm =
        (wastage.wasteCleaningDm +
          NUMBER_OF_HOURS_PER_CLEANING * countryConfig.wastage.dmCleaningHourlyRate) *
        countryConfig.wastage.dmCleansPerYear;
    }
    cost = wastage.rentalCost +
      wastage.wasteCleaningDm + wastage.purchasingCost + wastage.taxCost;
  }
  profit = revenue - cost;
  
  return {
    taps,
    revenue,
    wastage,
    cost,
    profit,
    diversity: Math.round((diversity / ((volumePerYear || 1) * 100)) * 100) / 100
  };
}

export const getRentalCosts = (rentalCosts: number[], tapsLength: number) => {
  return rentalCosts[Math.min( rentalCosts.length,tapsLength)-1] * 12;
};

export const animateScrollLeft = (elem: any, scrollTo: number) => {
  // eslint-disable-next-line no-shadow
  function scrollLeftTween(currentScroll: number, scrollTop: number) {
    return function (this: any) {
      const i = d3.interpolateNumber(currentScroll, scrollTop);
      
      return function (this: any, t: any) {
        elem?.scrollTo(i(t), 0);
      };
    };
  }
  
  d3.select(elem)
    .interrupt()
    .transition()
    .duration(300)
    .tween("tweenscroll", scrollLeftTween(elem.scrollLeft, scrollTo));
};

export const autoAdjustSlidersValue = (
  lockedTaps: boolean[],
  otherTabsInternal: (number | undefined)[]
) => (tapId: number, diff: number) => {
  const tapsVolumeSold = otherTabsInternal.map((soldPerYear, index) => ({
    locked: lockedTaps[index],
    soldPerYear: soldPerYear || 0,
    index
  }));
  
  let tapsNotLocked = tapsVolumeSold
    .filter(
      (tap, index) =>
        !tap.locked && index !== tapId && ((diff > 0 && tap.soldPerYear > 0) || diff < 0)
    )
    .sort((tapa, tapb) =>
      diff < 0 ? tapa.soldPerYear - tapb.soldPerYear : tapb.soldPerYear - tapa.soldPerYear
    )
    .map((tap, index) => ({...tap, newIndex: index}));
  
  let totalLeft = diff;
  const newTapsNotLocked = [...tapsNotLocked];
  while (totalLeft !== 0 && tapsNotLocked.length > 0) {
    tapsNotLocked = tapsNotLocked.filter(tap => (diff > 0 && tap.soldPerYear > 0) || diff < 0);
    // eslint-disable-next-line no-loop-func
    totalLeft = tapsNotLocked.reduce((acc, tap, index) => {
      const left = Math.round(acc / (tapsNotLocked.length - index)) || acc;
      if (newTapsNotLocked[tap.newIndex].soldPerYear >= left) {
        newTapsNotLocked[tap.newIndex].soldPerYear -= left;
        return acc - left;
      }
      const newAcc = acc - tap.soldPerYear;
      newTapsNotLocked[tap.newIndex].soldPerYear = 0;
      return newAcc;
    }, totalLeft);
  }
  
  const newInternalOtherTaps = [...otherTabsInternal];
  // eslint-disable-next-line array-callback-return
  newTapsNotLocked.map(tap => {
    if (!tap.locked) {
      newInternalOtherTaps[tap.index] = tap.soldPerYear;
    }
  });
  return newInternalOtherTaps;
};

export function copyToClipboard(text: string) {
  // @ts-ignore
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    // @ts-ignore
    return clipboardData.setData("Text", text);
  }
  if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    const textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}
