import { DEFAULT_LANG } from "../../constants";
import { translate, translateString } from "./translate";
import { IEntries } from "./types";
import { ICountry } from "../types";
import mapBeverage from "./mapBeverage";
import mapConfiguration from "./mapConfiguration";

export default function mapCountries(entries: IEntries, lang = DEFAULT_LANG) {
  const parsedCountries: ICountry[] = entries.country.ordered.filter(country => !(country?.fields?.countryId?.[DEFAULT_LANG] || "").includes("demo")).map(country => {
    const beverages = translate(country.fields.beverages) || [];
    const parsedBeverages = beverages
      .filter((b: any) => b.fields)
      .map((b: any) => mapBeverage(b, lang));

    const configuration = translate(country.fields.configuration) || [];
    const parsedConfiguration = mapConfiguration(configuration, lang);

    return {
      id: translateString(country.fields.countryId, lang),
      name: translateString(country.fields.countryName, lang),
      flag: translateString(country.fields.countryFlag, lang),
      beverages: parsedBeverages,
      configuration: parsedConfiguration
    };
  });

  return {
    countries: parsedCountries
  };
}
