import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Subtitle } from "@ddm-design-system/typography";
import { ITodayStateSave } from "../../store/today/types";
import { ITomorrowStateSave } from "../../store/tomorrow/types";
import { getCurrentCountry, getCurrentCountryConfigSafe } from "../../store/main/selectors";
import Report from "./report";
import { setCurrentCountry } from "../../store/main/actions";
import { Routes } from "../../routes";
import Base from "../../templates/base";
import { setCurrentLanguage } from "../../store/content/actions";
import useContent from "../../hooks/useContent";

const Share: React.FC = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { countries, report: content, languageInfo: languages } = useContent();
  const params = new URLSearchParams(search);
  const todayInfoS = params.get("today");
  const tomorrowInfoS = params.get("tomorrow");
  const config = params.get("config");
  const currentCountryId = useSelector(getCurrentCountry);
  const currentCountry = useSelector(getCurrentCountryConfigSafe);
  useEffect(() => {
    if (config != null) {
      const { country } = JSON.parse(atob(config));
      if (country) {
        const countryObj = countries.find(c => c.id === country);
        dispatch(setCurrentCountry(country ?? currentCountryId ?? "unitedKingdom"));
        const newLanguage = languages.find(l => l.country === countryObj?.flag);
        if (newLanguage) {
          dispatch(setCurrentLanguage(newLanguage));
        }
      }
    }
  }, [languages, countries, config, dispatch, currentCountryId]);

  const result = useMemo(() => {
    if (!todayInfoS || !tomorrowInfoS) {
      return <Redirect to={Routes.HOME} />;
    }
    const todayInfoSave: ITodayStateSave = JSON.parse(atob(todayInfoS));
    const tomorrowInfoSave: ITomorrowStateSave = JSON.parse(atob(tomorrowInfoS));
    if (config != null) {
      const { limitDate } = JSON.parse(atob(config));
      if (limitDate && +new Date() > limitDate) {
        return (
          <Base mode="SHARE">
            <Subtitle>{content.report_limit_expired}</Subtitle>
          </Base>
        );
      }
    }

    return currentCountry ? (
      <Report
        mode="SHARE"
        countryConfig={currentCountry}
        shareReport={{
          todayReport: todayInfoSave.todayReport,
          tomorrowReport: tomorrowInfoSave.tomorrowReport
        }}
      />
    ) : (
      <div />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry, languages, content.report_limit_expired]);
  return result;
};

export default Share;
