import Hotjar from "react-hotjar";

const ENV_HJID = process.env.REACT_APP_HOTJAR_ID || "";
const ENV_HJSV = process.env.REACT_APP_HOTJAR_VERSION || "";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let hj: any;

export default {
  setup: (hjid: string = ENV_HJID, hjsv: string = ENV_HJSV) => {
    const cc = localStorage.getItem("cookieConsent") || true;
    if (cc && process.env.NODE_ENV === "production") {
      if (hjid && hjsv) {
        Hotjar.hotjar.initialize(hjid, hjsv);
      }
    }
  },
  trigger: (poll: string) => hj("trigger", poll)
};
