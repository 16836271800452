import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PageTitle,
  SectionTitle,
  DescriptionMedium,
  Body,
  Subtitle,
  Label
} from "@ddm-design-system/typography";
import { useStyles, DDMTheme, CustomDDMThemeStyleSheetFactory } from "@ddm-design-system/base";
import { LockedSlider } from "@ddm-design-system/slider";
import { TapAvatar } from "@ddm-design-system/avatar";
import { getTapsInfo, getTotalVolumeSold, getMainVolumeSold } from "../../store/tomorrow/selectors";
import Base from "../../templates/base";
import { updateTapInformation } from "../../store/tomorrow/actions";
import { EBeverageType, ITapsInfo } from "../../store/types";
import useIsMobile from "../../hooks/useIsMobile";
import { autoAdjustSlidersValue } from "../../helpers";
import useContent from "../../hooks/useContent";
import { currencyMap } from "../../constants";
import { getVolumeConfig } from "../../store/today/selectors";

const styleSheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  pageTitle: {
    textAlign: "center"
  },
  sectionTitle: {
    marginTop: theme.spaceUnit.lg,
    marginBottom: theme.spaceUnit.lg
  },
  section: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  mainSection: {
    height: 112,
    alignItems: "center",
    "@media": {
      "screen and (max-width: 700px)": {
        height: 56
      }
    }
  },
  mainBackground: {
    height: 112,
    width: "100vw",
    position: "absolute",
    // background: theme.colors.grey.grey25,
    left: "calc((-100vw + 100%) / 2)",
    zIndex: -1,
    "@media": {
      "screen and (max-width: 700px)": {
        height: 56
      }
    }
  },
  labelWrapper: {
    display: "flex",
    marginBottom: theme.spaceUnit.xs
  },
  sectionQuantity: {
    flex: 1,
    textAlign: "right"
  },
  row: {
    display: "flex",
    marginBottom: theme.spaceUnit.lg
  },
  slider: {
    marginLeft: 32
  },
  totalVolume: {
    position: "sticky",
    width: "100%",
    padding: `${theme.spaceUnit.md}`,
    borderTop: "1px solid #E5EBE8",
    borderBottom: "1px solid #E5EBE8",
    bottom: 106,
    backgroundColor: theme.colors.white
  },
  mobileSticky: {
    bottom: 90
  }
});

const VolumeDistribution: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { volume: content, general: contentGeneral } = useContent();
  const volumeConfig = useSelector(getVolumeConfig);
  const otherTaps = useSelector(getTapsInfo);
  const mainTap = otherTaps[0];
  const totalVolume = useSelector(getTotalVolumeSold) || 0;
  const mainVolume = useSelector(getMainVolumeSold) || 0;
  const [styles, cx] = useStyles(styleSheet);
  const [lockedTaps, setLockedTaps] = useState<boolean[]>(
    otherTaps.map(t => t.beverage?.type === EBeverageType.WATER)
  );
  const othersTapsVolume = totalVolume - mainVolume;
  const [otherTabsInternal, setOtherTabsInternal] = useState(
    otherTaps.map(tap => tap.config.soldPerYear)
  );

  const autoAdjust = useCallback(autoAdjustSlidersValue, [lockedTaps, otherTabsInternal])(
    lockedTaps,
    otherTabsInternal
  );

  const onValueChange = useCallback(
    (tapId, newValue, previousValue) => {
      const newInternalOtherTaps = autoAdjust(tapId, newValue - previousValue);
      newInternalOtherTaps[tapId] = newValue;
      const total = newInternalOtherTaps.reduce((acc = 0, tap) => tap && tap + acc, 0);
      if (total && total > totalVolume) {
        return;
      }
      newInternalOtherTaps.forEach((soldPerYear, index) => {
        dispatch(
          updateTapInformation(
            {
              config: {
                soldPerYear
              }
            } as ITapsInfo,
            index
          )
        );
      });
      setOtherTabsInternal(newInternalOtherTaps);
    },
    [autoAdjust, dispatch, totalVolume]
  );

  const onLockChange = useCallback(
    (tapId, newLockValue) => {
      const newLocked = [...lockedTaps];
      newLocked[tapId] = newLockValue;
      setLockedTaps(newLocked);
    },
    [lockedTaps]
  );

  const Title = isMobile ? Subtitle : PageTitle;
  const Labels = isMobile ? Label : DescriptionMedium;
  const TotalValue = isMobile ? SectionTitle : PageTitle;

  return (
    <Base stickyFooter>
      <Title className={cx(styles.pageTitle)}>{content.volume_distribution_tomorrow}</Title>
      <SectionTitle className={cx(styles.sectionTitle)}>
        {" "}
        {content.volume_distribution_tomorrow_subtitle}
      </SectionTitle>

      <section className={cx(styles.section)}>
        <div className={cx(styles.labelWrapper)}>
          <Labels>{contentGeneral.general_main_brand}</Labels>
          <Labels className={cx(styles.sectionQuantity)}>
            {mainVolume} {currencyMap[volumeConfig.volumeUnit]}/
            {contentGeneral[`unit_${volumeConfig.volumeTimeframe}`]}
          </Labels>
        </div>
        {mainTap?.beverage && (
          <div className={cx(styles.row, styles.mainSection)}>
            <div className={cx(styles.mainBackground)} />
            <TapAvatar
              alt={mainTap.beverage.id}
              src={mainTap.beverage.lense}
              backgroundColor={mainTap.beverage.mainColor}
              size="sm"
            />
            <LockedSlider
              className={cx(styles.slider)}
              min={0}
              max={totalVolume}
              value={mainVolume}
              lockedIcon="locked"
              unlockedIcon="unlocked"
              onValueChange={(nv: number) => {
                const lockedVolume = lockedTaps.reduce<number>(
                  (acc, locked, ind) =>
                    locked ? acc + (otherTaps[ind].config.soldPerYear || 0) : acc,
                  0
                );
                if (
                  !(nv > totalVolume - lockedVolume) &&
                  lockedTaps.filter(locked => !locked).length > 1
                ) {
                  onValueChange(0, nv, mainTap.config.soldPerYear);
                }
              }}
              onLockChange={(newLockValue: boolean) => onLockChange(0, newLockValue)}
            />
          </div>
        )}
      </section>

      <section className={cx(styles.section)}>
        <div className={cx(styles.labelWrapper)}>
          <Labels>{content.volume_other_brands}</Labels>
          <Labels className={cx(styles.sectionQuantity)}>
            {othersTapsVolume} {currencyMap[volumeConfig.volumeUnit]}/
            {contentGeneral[`unit_${volumeConfig.volumeTimeframe}`]}
          </Labels>
        </div>

        {otherTaps
          .map(
            (tap, index) =>
              tap?.beverage &&
              index > 0 && (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${tap.beverage.id}-${index}`}
                  className={cx(styles.row)}
                >
                  <TapAvatar
                    alt={tap.beverage.id}
                    src={tap.beverage.lense}
                    backgroundColor={tap.beverage.mainColor}
                    size="sm"
                  />
                  <LockedSlider
                    disabled={tap.beverage?.type === EBeverageType.WATER}
                    lockedIcon="locked"
                    unlockedIcon="unlocked"
                    className={cx(styles.slider)}
                    value={tap.config.soldPerYear}
                    locked={lockedTaps[index]}
                    min={0}
                    max={totalVolume}
                    onValueChange={(nv: number) => {
                      const lockedVolume = lockedTaps.reduce<number>(
                        (acc, locked, ind) =>
                          locked ? acc + (otherTaps[ind].config.soldPerYear || 0) : acc,
                        0
                      );
                      if (
                        !(nv > totalVolume - lockedVolume) &&
                        lockedTaps.filter(locked => !locked).length > 1
                      ) {
                        onValueChange(index, nv, tap.config.soldPerYear);
                      }
                    }}
                    onLockChange={(newLockValue: boolean) => onLockChange(index, newLockValue)}
                  />
                </div>
              )
          )
          .filter(Boolean)}
      </section>

      <section className={cx(styles.totalVolume, isMobile && styles.mobileSticky)}>
        <Body>{content.volume_total_year}</Body>
        <br />
        <TotalValue>
          {totalVolume} {currencyMap[volumeConfig.volumeUnit]}/
          {contentGeneral[`unit_${volumeConfig.volumeTimeframe}`]}
        </TotalValue>
      </section>
    </Base>
  );
};

export default VolumeDistribution;
