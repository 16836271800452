
export enum EMainActions {
  SET_CURRENT_COUNTRY = "main/SET_CURRENT_COUNTRY",
  SET_ACTIVE_PAGE = "main/SET_ACTIVE_PAGE",
  RESET_CALCULATOR = "main/RESET_CALCULATOR"
}

export interface IMainState {
  currentCountry: string | null;
  activePage: number;
}

export interface IChooseCurrentCountry {
  type: EMainActions.SET_CURRENT_COUNTRY;
  payload: string;
}

export interface ISetActivePage {
  type: EMainActions.SET_ACTIVE_PAGE;
  payload: number;
}

export interface IResetCalculator {
  type: EMainActions.RESET_CALCULATOR;
}


export type IReducerActions =
  | IChooseCurrentCountry
  | ISetActivePage
  | IResetCalculator;
