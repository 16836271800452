import { ContentfulClientApi, SyncCollection, createClient } from "contentful";

const CONTENTFUL_CONFIG = {
  space: process.env.REACT_APP_CONTENTFUL_SPACE || "mu10fnvidohw"
};

export interface IContentfulService {
  getEntries(): Promise<SyncCollection>;
  checkChanges(token: string): Promise<SyncCollection>;
}

export class ContentfulService implements IContentfulService {
  client: ContentfulClientApi;

  constructor(accessToken: string, config = CONTENTFUL_CONFIG) {
    const { space } = config;
    this.client = createClient({
      space,
      accessToken,
      environment: "master"
    });
  }

  checkChanges(token: string) {
    return this.client.sync({ nextSyncToken: token });
  }

  getEntries() {
    return this.client.sync({ initial: true });
  }
}
