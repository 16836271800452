import React from "react";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import { Body } from "@ddm-design-system/typography";
import { PrimaryButton } from "@ddm-design-system/button";
import ReactMarkdown from "react-markdown";
import useContent from "../../hooks/useContent";

interface IProps {
  onCancel?: () => void;
  onAccept: () => void;
}

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  cookieCardWrapper: {
    boxSizing: "border-box",
    position: "fixed",
    zIndex: 10,
    padding: theme.spaceUnit.md,
    right: theme.spaceUnit.xxl,
    bottom: theme.spaceUnit.xxl,
    backgroundColor: theme.colors.black,
    border: `1px solid ${theme.colors.grey.greenish}`,
    color: theme.colors.white,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 300,
    "@media": {
      "screen and (max-width: 900px)": {
        right: theme.spaceUnit.xxl,
        bottom: theme.spaceUnit.xxl
      },
      "screen and (max-width: 700px)": {
        right: theme.spaceUnit.sm,
        bottom: theme.spaceUnit.sm
      }
    }
  },
  text: {
    marginBottom: theme.spaceUnit.md
  },
  clickable: {
    textDecoration: "underline",
    cursor: "pointer"
  },
  acceptButton: {
    borderColor: `${theme.colors.white} !important`,
    color: `${theme.colors.white} !important`,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "@selectors": {
      "&:hover:not([class*=\"disabled\"])": {
        width: "100%",
        backgroundColor: "#2e2e2e"
      },

      "&:active([class*=\"disabled\"])": {
        backgroundColor: `${theme.colors.white}`,
        color: `${theme.colors.black} !important`
      },

      "&[class*=\"disabled\"]": {
        borderColor: `${theme.colors.grey.grey50} !important`,
        color: `${theme.colors.grey.grey50} !important`
      }
    }
  }
});

const CookieCard: React.FC<IProps> = ({ onAccept, onCancel }) => {
  const [styles, cx] = useStyles(stylesheet);
  const { general: contentGeneral } = useContent();
  const optOutTokens = (contentGeneral?.general_cookie_opt_out || "").split("%SPLIT%");

  return contentGeneral ? (
    <div className={cx(styles.cookieCardWrapper)}>
      <div className={cx(styles.text)}>
        <Body>
          <ReactMarkdown
            components={{
              a: props => (
                <a
                  href={props.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "white",
                    textDecoration: "underline"
                  }}
                >
                  {props.children}
                </a>
              )
            }}
          >
            {contentGeneral.general_cookie_text}
          </ReactMarkdown>
          {optOutTokens.length >= 3 && (
            <p>
              {optOutTokens[0]}
              <span
                style={{
                  color: "white",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={onCancel}
              >
                {optOutTokens[1]}
              </span>
              {optOutTokens[2]}
            </p>
          )}
        </Body>
      </div>
      <PrimaryButton className={cx(styles.acceptButton)} onClick={onAccept}>
        {contentGeneral.general_cookie_accept}
      </PrimaryButton>
    </div>
  ) : (
    <></>
  );
};

export default CookieCard;
