import React from "react";
import { Avatar, IAvatarProps } from "@ddm-design-system/avatar";
import { DDMTheme } from "@ddm-design-system/base";
import BeerLense from "../beer-lense/beer-lense";
import { IBeverage } from "../../store/types";

interface IAvatarTapsProps {
  beverage?: IBeverage | null;
}

const getStylesheet = (props: IAvatarProps) => (theme: DDMTheme) => ({
  wrapper: {
    border: `1px solid ${theme.colors.grey.grey25}`
  },
  selected: {
    border: `1px solid ${theme.colors.black}`
  },
  circle: {
    border: `1px dashed ${theme.colors.grey.grey50}`
  }
});

const AvatarTaps: React.FC<IAvatarProps & IAvatarTapsProps> = (
  props: IAvatarProps & IAvatarTapsProps
) => {
  const StyledAvatar = Avatar.extendStyles(getStylesheet(props));

  return props.beverage ? (
    <BeerLense {...props} beverage={props.beverage} />
  ) : (
    <StyledAvatar size="lg" {...props} />
  );
};

export default AvatarTaps;
