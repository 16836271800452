import React, { useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducers";
import { ITodayState } from "../../store/today/types";
import { ITomorrowState } from "../../store/tomorrow/types";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import Report from "./report";
import { AnalyticsContext } from "../../services/analytics";

const ReportPDF: React.FC = () => {
  const analytics = useContext(AnalyticsContext);
  const todayInfo = useSelector<IAppState, ITodayState>(state => state.today);
  const tomorrowInfo = useSelector<IAppState, ITomorrowState>(state => state.tomorrow);
  const countryConfig = useSelector(getCurrentCountryConfig);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      window.print();
      analytics.logEvent("PRINT_REPORT");
      window.close();
    }, 2000);
    return () => clearTimeout(timeout);
  }, [analytics]);

  return (
    <Report
      todayInfo={todayInfo}
      tomorrowInfo={tomorrowInfo}
      countryConfig={countryConfig}
      mode="PRINT"
    />
  );
};

export default ReportPDF;
