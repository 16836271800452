import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import numeral from "numeral";
import { SplashLoading } from "@ddm-design-system/icon";
import Home from "./pages/home/home";
import initStore from "./store/index";
import TapsToday from "./pages/today";
import TapsTomorrow from "./pages/tomorrow";
import initialSetup, { history } from "./setup";
import createAppContext from "./services";
import { AnalyticsProvider } from "./services/analytics";
import "./App.styles.scss";
import {numeralLocaleEN, numeralLocaleFI, numeralLocaleFR, numeralLocaleIT} from "./constants";
import Cookies from "./components/cookie-card/Cookies";
import { IAppState } from "./store/reducers";
import { getCurrentCountry } from "./store/main/selectors";
import CountrySelector from "./pages/home/countrySelector";
import { Routes } from "./routes";
import ShareTomorrow from "./pages/tomorrow/share";

export const context = createAppContext();
const { store, persistor } = initStore(context);

history.listen((listener: { pathname: any; search: any }) => {
  context.analyticsService.logPageView(listener.pathname + listener.search);
});

numeral.register("locale", "en-GB", numeralLocaleEN);
numeral.register("locale", "fr-FR", numeralLocaleFR);
numeral.register("locale", "it-IT", numeralLocaleIT);
numeral.register("locale", "fi-FI", numeralLocaleFI);
numeral.locale("en-GB");

initialSetup();
const App = () => {
  return (
    <div className="root-container">
      <AnalyticsProvider value={context.analyticsService}>
        <Provider store={store}>
          <Router history={history}>
            <PersistGate loading={null} persistor={persistor}>
              <InnerApp />
              <Cookies />
            </PersistGate>
          </Router>
        </Provider>
      </AnalyticsProvider>
    </div>
  );
};

export const InnerAppComponent: React.FC = () => {
  const loadingContentful = useSelector((state: IAppState) => state.content.loadingContentful);
  const country = useSelector(getCurrentCountry);

  return !loadingContentful ? (
    <>
      <Switch>
        <Route exact path={Routes.COUNTRY_SELECTOR}>
          <CountrySelector />
        </Route>

        <Route path={Routes.TOMORROW_SHARE}>
          <ShareTomorrow />
        </Route>
        {!country && <Redirect to={Routes.COUNTRY_SELECTOR} />}
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/today">
          <TapsToday />
        </Route>
        <Route path="/tomorrow">
          <TapsTomorrow />
        </Route>
        <Redirect to="/" />
      </Switch>
    </>
  ) : (
    <div
      style={{
        flex: "1",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <SplashLoading infinite />
    </div>
  );
};
export const InnerApp = InnerAppComponent;

export default App;
