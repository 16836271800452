import React from "react";
import { TapAvatar, IAvatarProps } from "@ddm-design-system/avatar";
import { IBeverage } from "../../store/types";

export interface IBeerLenseProps {
  beverage: IBeverage;
  size?: IAvatarProps["size"];
}

const BeerLense: React.FC<IBeerLenseProps> = ({ beverage, ...rest }) => {
  return (
    <TapAvatar
      alt={beverage?.id}
      src={beverage?.lense}
      backgroundColor={beverage?.mainColor}
      size="sm"
      {...rest}
    />
  );
};

export default BeerLense;
