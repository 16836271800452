import axios, { AxiosInstance } from "axios";

export interface IApiService {}

export class ApiService implements IApiService {
  baseUrl: string;

  instance: AxiosInstance;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: baseUrl,
      timeout: 15000
    });
  }
}
