import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStyles, CustomDDMThemeStyleSheetFactory, DDMTheme } from "@ddm-design-system/base";
import { SectionTitle, PageTitle, Subtitle, BodyHighlight } from "@ddm-design-system/typography";
import { RadioGroup, Radio } from "@ddm-design-system/radio";
import { updateCo2Information } from "../../store/today/actions";
import { IAppState } from "../../store/reducers";
import { getCurrentCountryCo2Costs, getCurrentCountryConfig } from "../../store/main/selectors";
import Base from "../../templates/base";
import { currencyMap, VOLUME_UNIT } from "../../constants";
import useIsMobile from "../../hooks/useIsMobile";
import { formatCurrency } from "../../helpers";
import useContent from "../../hooks/useContent";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  co2Page: {
    color: theme.colors.black,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  sectionTitle: {
    marginBottom: theme.spaceUnit.xxl,
    "@media": {
      "screen and (max-width: 700px)": {
        marginBottom: theme.spaceUnit.lg
      }
    }
  },
  inputOther: {
    appearance: "none",
    "-webkit-appearance": "none",
    "-moz-appearance": "textfield",
    borderRadius: 0,
    border: "none",
    outline: "none",
    backgroundColor: "none",
    borderBottom: `1px solid ${theme.colors.grey.grey50}`,
    color: theme.colors.black,
    ...theme.typography.body,
    paddingRight: 30,
    "@selectors": {
      "&:focus": {
        borderColor: theme.colors.black
      },
      "&:active": {
        borderColor: theme.colors.black
      }
    }
  },
  radio: {
    padding: `${theme.spaceUnit.xl} 0`,
    borderTop: `1px solid ${theme.colors.grey.grey25}`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.lg} 0`
      }
    }
  },
  currency: {
    color: theme.colors.grey.grey50,
    marginLeft: "-30px"
  }
});

interface IProps {}

const Co2Cost: React.FC<IProps> = () => {
  const [styles, cx] = useStyles(stylesheet);
  const isMobile = useIsMobile();
  const { co2Cost: content } = useContent();
  const dispatch = useDispatch();
  const co2Costs = useSelector(getCurrentCountryCo2Costs);
  const countryConfig = useSelector(getCurrentCountryConfig);
  const co2Cost = useSelector((state: IAppState) => state.today.co2.cost);
  const otherRef = useRef<any>();

  const [customValue, setCustomValue] = useState<number>();

  const onChange = useCallback(
    (value: string, customV?: number) => {
      if (value === "custom") {
        dispatch(
          updateCo2Information({
            cost: customV
          })
        );
        setCustomValue(customV);
        setTimeout(() => {
          otherRef.current?.focus();
        }, 50);
      } else if (value) {
        dispatch(
          updateCo2Information({
            cost: parseFloat(value)
          })
        );
      }
    },
    [dispatch]
  );

  const isOther = useMemo(() => {
    return (
      co2Cost !== undefined &&
      co2Costs.small !== co2Cost &&
      co2Costs.medium !== co2Cost &&
      co2Costs.large !== co2Cost
    );
  }, [co2Cost, co2Costs]);

  useEffect(() => {
    if (co2Cost !== undefined && isOther) {
      setCustomValue(co2Cost);
    }
  }, []); // eslint-disable-line

  const selectedValue =
    co2Cost !== undefined ? (isOther ? "custom" : co2Cost.toString()) : undefined;

  const Title = isMobile ? Subtitle : PageTitle;
  const BodyTitle = isMobile ? BodyHighlight : SectionTitle;

  return (
    <Base>
      <div className={cx(styles.co2Page)}>
        <Title className={cx(styles.sectionTitle)}>{content.co2Cost_today}</Title>
        <BodyTitle className={cx(styles.sectionTitle)}>{content.co2Cost_today_subtitle}</BodyTitle>

        {co2Costs && (
          <RadioGroup name="co2" onValueSelect={onChange} selectedValue={selectedValue}>
            <Radio
              className={cx(styles.radio)}
              value={co2Costs.small.toString()}
              label={(content.co2Cost_small_label || "")
                .replace("%UNIT%", currencyMap[VOLUME_UNIT.HL])
                .replace(
                  "%UNIT2%",
                  `${formatCurrency(co2Costs.small, countryConfig.currency, false, true)}/${
                    currencyMap[VOLUME_UNIT.HL]
                  }`
                )}
            />
            <Radio
              className={cx(styles.radio)}
              value={co2Costs.medium.toString()}
              label={(content.co2Cost_medium_label || "")
                .replace("%UNIT%", currencyMap[VOLUME_UNIT.HL])
                .replace(
                  "%UNIT2%",
                  `${formatCurrency(co2Costs.medium, countryConfig.currency, false, true)}/${
                    currencyMap[VOLUME_UNIT.HL]
                  }`
                )}
            />
            <Radio
              className={cx(styles.radio)}
              value={co2Costs.large.toString()}
              label={(content.co2Cost_large_label || "")
                .replace("%UNIT%", currencyMap[VOLUME_UNIT.HL])
                .replace(
                  "%UNIT2%",
                  `${formatCurrency(co2Costs.large, countryConfig.currency, false, true)}/${
                    currencyMap[VOLUME_UNIT.HL]
                  }`
                )}
            />
            <Radio
              className={cx(styles.radio)}
              value="custom"
              label={
                <div>
                  <input
                    ref={otherRef}
                    placeholder={content.co2Cost_other_label}
                    className={cx(styles.inputOther)}
                    type="number"
                    value={customValue}
                    onChange={e => {
                      const v = parseFloat(e.target.value) || 0;
                      onChange("custom", v);
                    }}
                  />
                  <span className={cx(styles.currency)}>
                    {currencyMap[countryConfig.currency]}/{currencyMap[VOLUME_UNIT.HL]}
                  </span>
                </div>
              }
            />
          </RadioGroup>
        )}
      </div>
    </Base>
  );
};

export default Co2Cost;
