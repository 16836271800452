import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/reducers";
import { ITodayState } from "../../store/today/types";
import { ITomorrowState } from "../../store/tomorrow/types";
import { getCurrentCountryConfig } from "../../store/main/selectors";
import Report from "./report";
import useIsPrint from "../../hooks/useIsPrint";

const ReportPage: React.FC = () => {
  const todayInfo = useSelector<IAppState, ITodayState>(state => state.today);
  const tomorrowInfo = useSelector<IAppState, ITomorrowState>(state => state.tomorrow);
  const countryConfig = useSelector(getCurrentCountryConfig);
  const isPrinting = useIsPrint();

  return (
    <Report
      todayInfo={todayInfo}
      tomorrowInfo={tomorrowInfo}
      countryConfig={countryConfig}
      mode={isPrinting ? "PRINT" : "DEFAULT"}
    />
  );
};

export default ReportPage;
