import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DDMTheme, CustomDDMThemeStyleSheetFactory, useStyles } from "@ddm-design-system/base";
import { PrimaryButton, SecondaryButton } from "@ddm-design-system/button";
import { Icon } from "@ddm-design-system/icon";
import {getActivePage, getCurrentCountryCo2Costs, getCurrentCountryMarketType} from "../../store/main/selectors";
import { getCalculatorPages, Routes } from "../../routes";
import { resetCalculator, setActivePage } from "../../store/main/actions";
import useIsMobile from "../../hooks/useIsMobile";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import {
  getDifferentBrands,
  getSellInPrices,
  getTapsInfo,
  getTotalVolumeSold
} from "../../store/today/selectors";
import {
  getTapsInfo as tomorrowGetTapsInfo,
  getTotalVolumeSold as tomorrowGetTotalVolumeSold,
  getSellInPrices as tomorrowGetSellInPrices,
  getDifferentBrands as tomorrowGetDifferentBrands
} from "../../store/tomorrow/selectors";
import { UIMode } from "../../templates/base";
import { AnalyticsContext } from "../../services/analytics";
import { EBeverageType } from "../../store/types";
import useContent from "../../hooks/useContent";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  footerWrapper: {
    position: "relative"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spaceUnit.xl} 0`,
    borderTop: `1px solid ${theme.colors.grey.grey25}`,
    backgroundColor: theme.colors.white,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.lg} 0`
      }
    }
  },
  lastPage: {
    justifyContent: "center"
  },
  footerLastPage: {
    backgroundColor: theme.colors.black,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spaceUnit.xxl,
    position: "absolute",
    boxSizing: "border-box",
    width: "100vw",
    left: "calc(-50vw + 50%)",
    "@media": {
      "screen and (max-width: 700px)": {
        padding: theme.spaceUnit.lg
      }
    }
  },
  sticky: {
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.colors.white
  },
  backButton: {
    marginRight: theme.spaceUnit.xs
  }
});

interface IProps {
  mode: UIMode;
  stickyFooter?: boolean;
}

const Footer: React.FC<IProps> = ({ mode, stickyFooter }) => {
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { general: contentGeneral } = useContent();
  const [styles, cx] = useStyles(stylesheet);
  const activePage = useSelector(getActivePage);
  const marketType = useSelector(getCurrentCountryMarketType);
  const co2Costs = useSelector(getCurrentCountryCo2Costs);
  const calculatorPages = useMemo(() => getCalculatorPages(marketType, co2Costs), [marketType]);
  const pages = useMemo(
    () => calculatorPages.today.concat(calculatorPages.tomorrow),
    [calculatorPages]
  );
  const todayTaps = useSelector(getTapsInfo);
  const todayDifferentBrands = useSelector(getDifferentBrands).filter(
    b => b.type !== EBeverageType.WATER
  );
  const tomorrowDifferentBrands = useSelector(tomorrowGetDifferentBrands).filter(
    b => b.type !== EBeverageType.WATER
  );
  const todaySellInPrice = useSelector(getSellInPrices);
  const tomorrowSellInPrice = useSelector(tomorrowGetSellInPrices);
  const todayTotalVolume = useSelector(getTotalVolumeSold) || 0;
  const tomorrowTaps = useSelector(tomorrowGetTapsInfo);
  const tomorrowTotalVolume = useSelector(tomorrowGetTotalVolumeSold) || 0;

  const goBack = useCallback(() => {
    if (activePage === 0) {
      analytics.logEvent("LEAVE_CALCULATOR");
      history.push(Routes.HOME);
      dispatch(resetCalculator());
    } else {
      history.push(pages[activePage - 1].path);
      dispatch(setActivePage(activePage - 1));
    }
    // @ts-ignore
    document.querySelector(".root-container").scrollTop = 0;
  }, [pages, activePage, history, dispatch, analytics]);

  const isShare =
    history.location.pathname === Routes.TOMORROW_SHARE ||
    history.location.pathname === Routes.TOMORROW_PDF;
  const lastPage = useMemo(() => {
    return activePage === pages.length - 1 || isShare;
  }, [activePage, pages, isShare]);

  const goNext = useCallback(() => {
    if (lastPage) {
      analytics.logEvent("FINISH_CALCULATOR");
      history.push(Routes.HOME);
      dispatch(resetCalculator());
    } else {
      if (activePage + 1 >= calculatorPages.today.length) {
        analytics.logEvent("FINISH_TODAY");
      }
      history.push(pages[activePage + 1].path);
      dispatch(setActivePage(activePage + 1));
    }
    // @ts-ignore
    document.querySelector(".root-container").scrollTop = 0;
  }, [pages, activePage, history, dispatch, analytics, calculatorPages.today.length, lastPage]);

  useEffect(() => {
    const active = pages.findIndex(p => p.path === history.location.pathname);

    if (!isShare) {
      if (activePage > -1 && activePage !== active) {
        history.push(pages[activePage].path);
      } else if (activePage === -1) {
        history.push("/");
      }
    }
  }, [history, pages, dispatch, activePage, isShare]);

  const allowNextPage = useCallback(() => {
    let allow = true;
    if (isShare) {
      return allow;
    }
    switch (pages[activePage]?.path) {
      case Routes.TODAY_TAPS:
        allow = todayTaps.length > 0;
        break;
      case Routes.TODAY_BRANDS:
        allow = todayTaps.every(t => !!t.beverage);
        break;
      case Routes.TODAY_VOLUME:
        allow = todayTotalVolume > 0;
        break;
      case Routes.TODAY_VOLUME_DISTRIBUTION:
        break;
      case Routes.TODAY_PURCHASING:
        allow =
          Object.keys(todaySellInPrice).length === todayDifferentBrands.length &&
          Object.keys(todaySellInPrice).every(k => todaySellInPrice[k]?.price >= 0);
        break;
      case Routes.TODAY_PRICING:
        allow = todayTaps
          .filter(t => t.beverage?.type !== EBeverageType.WATER)
          .every(t => t.config.cost !== undefined);
        break;
      case Routes.TODAY_CO2_COST:
        break;
      case Routes.TODAY_REPORT:
        break;
      case Routes.TOMORROW_TAPS:
        allow = tomorrowTaps.length > 0;
        break;
      case Routes.TOMORROW_BRANDS:
        allow = tomorrowTaps.every(t => !!t.beverage);
        break;
      case Routes.TOMORROW_VOLUME:
        allow = tomorrowTotalVolume > 0;
        break;
      case Routes.TOMORROW_VOLUME_DISTRIBUTION:
        break;
      case Routes.TOMORROW_PURCHASING:
        allow =
          Object.keys(tomorrowSellInPrice).length === tomorrowDifferentBrands.length &&
          Object.keys(tomorrowSellInPrice).every(k => tomorrowSellInPrice[k]?.price >= 0);
        break;
      case Routes.TOMORROW_PRICING:
        allow = tomorrowTaps
          .filter(t => t.beverage?.type !== EBeverageType.WATER)
          .every(t => t.config.cost !== undefined);
        break;
      case Routes.TOMORROW_REPORT:
        break;
      default:
        break;
    }
    return allow;
  }, [
    todayTaps,
    activePage,
    todayTotalVolume,
    tomorrowTaps,
    tomorrowTotalVolume,
    todaySellInPrice,
    todayDifferentBrands,
    tomorrowDifferentBrands,
    tomorrowSellInPrice,
    pages,
    isShare
  ]);

  return (
    <div className={cx(styles.footerWrapper, stickyFooter && styles.sticky)}>
      <footer className={cx(styles.footer, lastPage && styles.lastPage)}>
        {!lastPage && (
          <>
            {isMobile && <Icon name="dm-logo" fill="black" size={32} />}
            {!isMobile && <Logo height={32} />}
            <span style={{ flex: 1 }} />
          </>
        )}
        {mode === "DEFAULT" && (
          <SecondaryButton
            type="button"
            onClick={goBack}
            className={cx(styles.backButton, "no-print")}
          >
            {contentGeneral.general_back}
          </SecondaryButton>
        )}
        {mode !== "PRINT" && (
          <PrimaryButton
            className="no-print"
            type="button"
            onClick={goNext}
            disabled={!allowNextPage()}
          >
            {lastPage ? contentGeneral.general_finish : contentGeneral.general_next}
          </PrimaryButton>
        )}
      </footer>
      {lastPage && (
        <div className={cx(styles.footerLastPage)}>
          <Logo fill="white" width={isMobile ? 130 : 210} />
        </div>
      )}
    </div>
  );
};

export default Footer;
