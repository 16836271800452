import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Body, PageTitle, Subtitle } from "@ddm-design-system/typography";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "@ddm-design-system/modal";
import { Icon } from "@ddm-design-system/icon";
import { CustomDDMThemeStyleSheetFactory, DDMTheme, useStyles } from "@ddm-design-system/base";
import { SecondaryButton, TertiaryButton } from "@ddm-design-system/button";
import { Link } from "react-router-dom";
import { ITodayState, ITodayStateSave } from "../../store/today/types";
import { ITomorrowState, ITomorrowStateSave } from "../../store/tomorrow/types";
import { getCurrentCountry } from "../../store/main/selectors";
import { calculateReport, copyToClipboard } from "../../helpers";
import Base, { UIMode } from "../../templates/base";
import useIsMobile from "../../hooks/useIsMobile";
import { ICountryConfiguration } from "../../store/types";
import { Routes } from "../../routes";
import { AnalyticsContext } from "../../services/analytics";
import CostSection from "../../components/report/CostSection";
import DiversitySection from "../../components/report/DiversitySection";
import PotentialSection from "../../components/report/PotentialSection";
import RevenueSectionTomorrow from "../../components/report/RevenueSectionTomorrow";
import LegalSection from "../../components/report/LegalSection";
import useContent from "../../hooks/useContent";

const stylesheet: CustomDDMThemeStyleSheetFactory = (theme: DDMTheme) => ({
  reportContainer: {
    display: "flex",
    flexDirection: "column",
    color: theme.colors.black,
    width: "100%"
  },
  reportTitle: {
    textAlign: "center"
  },
  reportSection: {
    padding: `${theme.spaceUnit.xxl} calc(${theme.spaceUnit.md} * 4) ${theme.spaceUnit.xl} `,
    borderBottom: `1px solid ${theme.colors.grey.grey50}`,
    "@media": {
      "screen and (max-width: 700px)": {
        padding: `${theme.spaceUnit.xl} 0`
      },
      "screen and (max-width: 900px)": {
        padding: `${theme.spaceUnit.xl} 0`
      }
    }
  },
  buttonSection: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spaceUnit.md,
    "@media": {
      "screen and (max-width: 700px)": {
        flexDirection: "column",
        paddingBottom: 0
      }
    }
  },
  buttonReport: {
    margin: `${theme.spaceUnit.xs} ${theme.spaceUnit.lg}`
  },
  separator: {
    height: 1,
    width: "100%",
    backgroundColor: theme.colors.grey.grey50,
    margin: `${theme.spaceUnit.xl} 0`
  },
  shareModal: {
    maxWidth: 400,
    "@media": {
      "screen and (max-width: 700px)": {
        maxWidth: `calc(100% - ${theme.spaceUnit.xl})`,
        boxSizing: "border-box"
      }
    }
  },
  shareUrl: {
    overflow: "auto",
    display: "flex",
    whiteSpace: "nowrap",
    borderRadius: 10,
    backgroundColor: theme.colors.grey.grey25,
    border: `1px solid ${theme.colors.grey.grey50}`,
    color: theme.colors.grey.grey100,
    padding: theme.spaceUnit.xs
  }
});

interface IProps {
  todayInfo?: ITodayState;
  tomorrowInfo?: ITomorrowState;
  countryConfig: ICountryConfiguration;
  mode?: UIMode;
  shareReport?: { todayReport: any; tomorrowReport: any };
}

export const Report: React.FC<IProps> = ({
  todayInfo,
  tomorrowInfo,
  countryConfig,
  mode = "DEFAULT",
  shareReport
}) => {
  const analytics = useContext(AnalyticsContext);
  const currentCountry = useSelector(getCurrentCountry);
  const [styles, cx] = useStyles(stylesheet);
  const { report: content, general: contentGeneral } = useContent();
  const [shareModalVisible, setShareModalVisible] = useState(false);

  // eslint-disable-next-line no-underscore-dangle
  const _isMobile = useIsMobile();
  const isMobile = useMemo(() => _isMobile && mode !== "PRINT", [_isMobile, mode]);

  const todayReport = useMemo(
    () =>
      shareReport?.todayReport || (todayInfo && calculateReport("TODAY", todayInfo, countryConfig)),
    [shareReport, todayInfo, countryConfig]
  );

  const tomorrowReport = useMemo(
    () =>
      shareReport?.tomorrowReport ||
      (tomorrowInfo && calculateReport("TOMORROW", tomorrowInfo, countryConfig)),
    [shareReport, tomorrowInfo, countryConfig]
  );

  const shareUrl = useMemo(() => {
    const saveToday: ITodayStateSave = {
      todayReport
    };
    const saveTomorrow: ITomorrowStateSave = {
      tomorrowReport
    };
    const config: any = {};
    if (currentCountry) {
      config.country = currentCountry?.id;
    }
    if (countryConfig.shareReportDays > 0) {
      config.limitDate = +new Date() + 1000 * 60 * 60 * 24 * countryConfig.shareReportDays;
    }
    const shareParams = new URLSearchParams({
      today: btoa(JSON.stringify(saveToday)),
      tomorrow: btoa(JSON.stringify(saveTomorrow)),
      config: btoa(JSON.stringify(config))
    });
    return `${Routes.TOMORROW_SHARE}?${shareParams.toString()}`;
  }, [todayReport, tomorrowReport, currentCountry, countryConfig]);

  const copyUrl = useCallback(() => {
    copyToClipboard(window.location.origin + shareUrl);
    setShareModalVisible(false);
    analytics.logEvent("SHARE_REPORT");
  }, [shareUrl, analytics]);

  const Title = isMobile ? Subtitle : PageTitle;

  return (
    <Base mode={mode}>
      <div className={cx(styles.reportContainer)}>
        <Title className={cx(styles.reportTitle)}>{content.report_tomorrow}</Title>

        <PotentialSection todayReport={todayReport} tomorrowReport={tomorrowReport} spoiler />

        <RevenueSectionTomorrow todayReport={todayReport} tomorrowReport={tomorrowReport} />

        <DiversitySection todayReport={todayReport} tomorrowReport={tomorrowReport} />

        <CostSection todayReport={todayReport} tomorrowReport={tomorrowReport} />

        <PotentialSection todayReport={todayReport} tomorrowReport={tomorrowReport} />

        <LegalSection mode={mode} />

        {mode !== "PRINT" && mode !== "SHARE" && (
          <section className={cx(styles.reportSection, "no-print")}>
            <div className={cx(styles.buttonSection)}>
              {countryConfig.downloadReport && (
                <Link target="_blank" to={Routes.TOMORROW_PDF} type="application/pdf">
                  <TertiaryButton icon="download" className={cx(styles.buttonReport)}>
                    {content.report_save_pdf}
                  </TertiaryButton>
                </Link>
              )}
              {countryConfig.shareReport && (
                <>
                  {/* {isMobile && <div className={cx(styles.separator)} />} */}
                  <TertiaryButton
                    icon="share"
                    className={cx(styles.buttonReport)}
                    onClick={() => setShareModalVisible(true)}
                  >
                    {content.report_share_analysis}
                  </TertiaryButton>
                  <Modal
                    className={cx(styles.shareModal)}
                    isOpen={shareModalVisible}
                    onRequestClose={() => setShareModalVisible(false)}
                    shouldCloseOnOverlayClick
                  >
                    <ModalHeader
                      icon={<Icon name="share" />}
                      title={content.report_share_analysis}
                    />
                    <ModalBody>
                      <Body className={cx(styles.shareUrl)}>
                        {window.location.origin}
                        {shareUrl}
                      </Body>
                    </ModalBody>
                    <ModalFooter>
                      <SecondaryButton onClick={copyUrl}>
                        {contentGeneral.general_copy}
                      </SecondaryButton>
                    </ModalFooter>
                  </Modal>
                </>
              )}
            </div>
          </section>
        )}
      </div>
    </Base>
  );
};

export default Report;
